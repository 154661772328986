import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Typography from "@mui/material/Typography";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import xIcon from "../../assets/images/ink_me/icons/icon_x_white.svg";

import "./ErrorModal.css";

function BlockUserModal({ onSubmit, buttonText, title, onModalClose }) {
  const { t } = useTranslation();
  const [reason, setReason] = useState("");
  const [errors, setErrors] = useState("");
  const [sendEmail, setSendEmail] = useState(false);
  const label = { inputProps: { "aria-label": "Send Email" } };

  const handleChange = (e) => {
    if (e.target.name === "reason") {
      setReason(e.target.value);
    }
  };

  return (
    <div className="error_modal_background_container">
      <div className="error_modal_container">
        <div className="modal_title">
          <h3>{t(title)}</h3>
          <button type="button" onClick={onModalClose} onKeyDown={onModalClose}>
            <img src={xIcon} alt="x icon" />
          </button>
        </div>
        <div className="modal_content">
          <MDBox p={2} width="350px">
            <MDInput
              type="text"
              name="reason"
              label={t("ARTISTS.REASON_FOR_BLOCK")}
              value={reason}
              onChange={(e) => handleChange(e)}
              error={!errors ? null : errors.reason}
              multiline
              rows={5}
              fullWidth
            />
            {!errors ? null : (
              <p className="error_message">{t(errors.reason)}</p>
            )}
          </MDBox>
          <Stack alignItems="center">
            <Typography variant="h6">{t("AUTH.SEND_EMAIL")}</Typography>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="h6">{t("AUTH.NO")}</Typography>

              <Switch
                {...label}
                label="Top"
                // defaultChecked={loansAreFrozen}
                checked={sendEmail}
                color="error"
                onChange={(e) => {
                  setSendEmail(e.target.checked);
                }}
              />

              <Typography variant="h6">{t("AUTH.YES")}</Typography>
            </Stack>
          </Stack>
          <MDBox>
            <MDButton
              color="error"
              value={reason}
              onClick={() => onSubmit(reason, sendEmail)}
            >
              {t(buttonText)}
            </MDButton>
          </MDBox>
        </div>
      </div>
    </div>
  );
}

export default BlockUserModal;
