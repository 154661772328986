/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import ModalContainer from "components/ModalContainer/ModalContainer";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import BlockUserModal from "components/blockUserModal";

import MDTypography from "components/MDTypography";

import { changeCustomerBlockUnblock } from "../../../../../store/customerSlice";
import { getDate, getDateWithTime } from "../../../../../helpers/helpers";
import { useNavigate } from "react-router-dom";
import { finishCollections } from "api/customers";

function CustomerDetails() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const customerDetails = useSelector(
    (state) => state.customer.customerDetails
  );
  const role = useSelector((state) => state.auth.role);
  const [blockCustomerState, setBlockCustomerState] = useState(false);
  const [reinstateUserState, setReinstateUserState] = useState(false);

  const renderStatus = () => {
    if (customerDetails.status === "Active") {
      return t("CUSTOMERS.ACTIVE");
    } else if (customerDetails.status === "Email_Not_Verified") {
      return t("CUSTOMERS.EMAIL_NOT_VERIFIED");
    } else if (customerDetails.status === "New_Loans_Blocked") {
      return t("CUSTOMERS.NEW_LOANS_BLOCKED");
    } else if (customerDetails.status === "AdminBlocked") {
      return t("CUSTOMERS.ADMIN_BLOCKED");
    } else if (customerDetails.status === "Collections") {
      return t("CUSTOMERS.COLLECTIONS");
    } else return null;
  };

  const handleBlockCustomer = () => {
    setBlockCustomerState(true);
  };

  const handleCustomerCollections = () => {
    setReinstateUserState(true);
  };

  const handleSubmitRemoveColletions = async (e) => {
    e.preventDefault();
    try {
      const res = await finishCollections(customerDetails.id);
      toast.success(t("TOAST.CUSTOMER_REINSTATE_SUCCESSFUL"));
      setReinstateUserState(false);
    } catch (e) {
      console.log(e);
      toast.error(e.response);
    }
  };

  const handleSubmitBlockCustomer = (reason, sendEmail) => {
    const data = {
      id: customerDetails.id,
      status: "Block",
      declineReason: reason,
      sendUserEmail: sendEmail,
    };
    dispatch(changeCustomerBlockUnblock(data));
    setBlockCustomerState(false);
  };

  const handleUnBlockCustomer = () => {
    const data = {
      id: customerDetails.id,
      status: "Unblock",
      declineReason: "",
    };
    dispatch(changeCustomerBlockUnblock(data));
  };

  const handleViewLoans = () => {
    const encodedEmail = encodeURIComponent(customerDetails.email);
    navigate(`/application-management/applications/?email=${encodedEmail}`);
  };

  const renderBlockReasons = () => {
    if (!customerDetails || customerDetails.blockForms.length === 0) {
      return;
    } else {
      return customerDetails.blockForms.map((reason) => {
        return (
          <MDBox key={reason.created}>
            <MDTypography variant="h6" pl={1}>
              {getDate(reason.created)}
            </MDTypography>
            <MDTypography variant="body2" pl={1}>
              {reason.blockReason}
            </MDTypography>
          </MDBox>
        );
      });
    }
  };

  return (
    <>
      {role === "SuperAdmin" && customerDetails.status === "Collections" ? (
        <MDBox display="flex" justifyContent="flex-end" px={3}>
          <MDBox p={2}>
            <MDButton
              color="primary"
              variant="outlined"
              onClick={handleCustomerCollections}
            >
              {t("CUSTOMERS.REMOVE_COLLECTIONS")}
            </MDButton>
          </MDBox>
        </MDBox>
      ) : null}
      {role === "SuperAdmin" &&
      customerDetails.status !== "Email_Not_Verified" &&
      customerDetails.status !== "Collections" ? (
        <MDBox display="flex" justifyContent="flex-end" px={3}>
          <MDBox px={2}>
            <MDButton color="error" onClick={handleBlockCustomer}>
              {t("CUSTOMERS.BLOCK_CUSTOMER")}
            </MDButton>
          </MDBox>
          <MDButton color="primary" onClick={handleUnBlockCustomer}>
            {t("CUSTOMERS.UNBLOCK_CUSTOMER")}
          </MDButton>
        </MDBox>
      ) : null}
      <MDBox
        display="flex"
        flexDirection="column"
        alignItems="flex-end"
        px={3}
        pt={3}
      >
        <MDBox display="flex" flexDirection="column">
          <MDTypography variant="h6" color="primary" pb={2}>
            {t("TABLE.STATUS")}
            {": "}
            {renderStatus()}
          </MDTypography>
          <MDBox>
            <MDTypography variant="h6">
              {t("ARTISTS.REASON_FOR_BLOCK")}
              {": "}
            </MDTypography>
            <MDBox>
              <MDTypography variant="body2" pl={1}>
                {renderBlockReasons()}
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </MDBox>
      <MDBox
        pt={2}
        px={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <MDBox p={3} pb={1}>
          <MDTypography variant="h3" textTransform="uppercase">
            {`${customerDetails.firstName} ${customerDetails.lastName}`}
          </MDTypography>
          <MDTypography variant="h6">
            {t("TABLE.EMAIL")}
            {": "}
            {customerDetails.email}
          </MDTypography>
          <MDTypography variant="h6">
            {t("TABLE.PHONE")}
            {": "}
            {customerDetails.phone}
          </MDTypography>
          <MDBox display="flex" alignItmes="center">
            <MDTypography variant="h6">
              {t("ARTISTS.HOW_HEARD")}
              {": "}
            </MDTypography>
            <MDTypography variant="body2" pl={1}>
              {customerDetails.howHeard}
            </MDTypography>
          </MDBox>
          <MDBox display="flex" alignItmes="center">
            <MDTypography variant="h6">
              {t("TABLE.TOS_AND_PP")}
              {": "}
            </MDTypography>
            <MDTypography variant="body2" pl={1}>
              {getDateWithTime(customerDetails.tosSignDate)}
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
      <Divider />
      <MDBox pt={1} pb={3} px={5}>
        <MDBox p={3} pb={3} px={10}>
          <MDTypography variant="h6" textTransform="uppercase">
            {t("CUSTOMERS.LOAN_SUMMARY")}
          </MDTypography>
        </MDBox>
        <MDBox display="flex" justifyContent="space-evenly">
          <Grid item xs={12} md={6} xl={3}>
            <DefaultInfoCard
              icon="account_balance_wallet"
              title={t("CUSTOMERS.OWE_AMOUNT")}
              value={`$ ${customerDetails.oweAmount.toFixed(2)}`}
            />
          </Grid>
          <Grid item xs={12} md={6} xl={3}>
            <DefaultInfoCard
              icon="payments"
              title={t("CUSTOMERS.PAID_OFF")}
              value={`$ ${customerDetails.paidOff.toFixed(2)}`}
            />
          </Grid>
          <Grid item xs={12} md={6} xl={3}>
            <DefaultInfoCard
              icon="assignment_late"
              title={t("CUSTOMERS.MISSED_PAYMENTS")}
              value={customerDetails.missedPayments}
            />
          </Grid>
        </MDBox>
        <MDBox p={3} pb={1} display="flex" justifyContent="flex-end">
          <MDButton color="primary" onClick={handleViewLoans}>
            {" "}
            {t("CUSTOMERS.VIEW_LOANS")}
          </MDButton>
        </MDBox>
      </MDBox>
      {blockCustomerState ? (
        <BlockUserModal
          buttonText={"CUSTOMERS.BLOCK_CUSTOMER"}
          title={"CUSTOMERS.BLOCK_CUSTOMER"}
          onModalClose={() => setBlockCustomerState(false)}
          onSubmit={handleSubmitBlockCustomer}
        />
      ) : null}
      {reinstateUserState ? (
        <ModalContainer
          title={t("CUSTOMERS.REMOVE_COLLECTIONS")}
          onButtonClose={() => setReinstateUserState(false)}
        >
          <MDBox width="350px">
            <MDTypography variant="body2">
              {t("CUSTOMERS.REMOVE_COLLECTIONS_MESSAGE")}
            </MDTypography>
          </MDBox>
          <MDBox py={2}>
            <MDButton color="primary" onClick={handleSubmitRemoveColletions}>
              {t("CUSTOMERS.REMOVE_COLLECTIONS")}
            </MDButton>
          </MDBox>
        </ModalContainer>
      ) : null}
    </>
  );
}

export default CustomerDetails;
