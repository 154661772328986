import axios from "./api";

export function getFeedbackTable(data) {
  return axios({
    url: `/Feedback/getpage`,
    method: "post",
    data,
  });
}

export function replyToFeedback(data) {
  return axios({
    url: `/Feedback/reply`,
    method: "post",
    data,
  });
}

export function markAsAnswered(id) {
  return axios({
    url: `/Feedback/markread/${id}`,
    method: "put",
  });
}
