import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import { Icon } from "@mui/material";
import Radio from "@mui/material/Radio";
import MDButton from "components/MDButton";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import validate from "../CreateAdmin/createAdminValidation";
import MDTypography from "components/MDTypography";

import {
  toggleCreateNewAdminState,
  toggleUpdateAdminState,
} from "store/staffSlice";
import { editAdminDetails, getStaffData} from "store/staffSlice";

const UpdateAdmin = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const staffDetails = useSelector((state) => state.staff.staffDetails);
  const [role, setRole] = useState("admin");

  useEffect(() => {
    if (!staffDetails) {
    } else {
      //setRole(staffDetails.isSuperAdmin ? "superAdmin" : "admin");
      setFirstName(staffDetails.firstName);
      setLastName(staffDetails.lastName);
      setPhone(staffDetails.phone);
    }
  }, [staffDetails]);

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      const data = {
        id: staffDetails.id,
        firstName,
        lastName,
        phone,
        isSuperAdmin: role === "admin" ? false : true,
        isBlocked: staffDetails.isBlocked,
      };
      dispatch(editAdminDetails(data));
      const staffData = {
        pageIndex: 1,
        pageSize: 10,
      };
      dispatch(getStaffData(staffData));
      setIsSubmitting(false);
      setFirstName("");
      setLastName("");
      setPhone(0);
    }
  }, [
    errors,
    isSubmitting,
    firstName,
    lastName,
    phone,
    role,
    staffDetails.id,
    staffDetails.isBlocked,
  ]);

  const handleRoleChange = (e) => {
    setRole(e.target.value);
  };

  const handleChange = (e) => {
    setIsSubmitting(false);
    if (e.target.name === "firstName") {
      setFirstName(e.target.value);
    }
    if (e.target.name === "lastName") {
      setLastName(e.target.value);
    }
    if (e.target.name === "phone") {
      setPhone(e.target.value);
    }
  };

  const handleSubmit = (e) => {
    if (e) e.preventDefault();
    setIsSubmitting(true);
    setErrors(validate(firstName, lastName, staffDetails.email, phone));
  };

  return (
    <MDBox display="flex" flexDirection="column" alignItems="center" p={2}>
      <MDBox width={"50%"}>
        {/* <MDBox py={2} px={4}>
          <FormControl>
            <FormLabel
              sx={{
                fontFamily: "Roboto, sans-serif",
                fontSize: 14,
                fontWeight: 400,
                letterSpacing: 0.13132,
                lineHeight: "20.12px",
                color: "text",
              }}
              id="role-radio-buttons-group"
            >
              {t("STAFF.ADMIN_ROLE")}
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="role-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={role}
              onChange={handleRoleChange}
            >
              <FormControlLabel
                value="admin"
                control={<Radio color="primary" />}
                label={t("STAFF.ADMIN")}
              />
              <FormControlLabel
                value="superAdmin"
                control={<Radio color="primary" />}
                label={t("STAFF.SUPERADMIN")}
              />
            </RadioGroup>
          </FormControl>
        </MDBox> */}
        <MDBox p={2}>
          <MDInput
            type="text"
            name="firstName"
            label={t("TABLE.FIRST_NAME")}
            value={firstName}
            onChange={(e) => handleChange(e)}
            error={!errors ? null : errors.firstName}
            fullWidth
          />
          {!errors ? null : (
            <p className="error_message">{t(errors.firstName)}</p>
          )}
        </MDBox>
        <MDBox p={2}>
          <MDInput
            type="text"
            name="lastName"
            label={t("TABLE.LAST_NAME")}
            value={lastName}
            onChange={(e) => handleChange(e)}
            error={!errors ? null : errors.lastName}
            fullWidth
          />
          {!errors ? null : (
            <p className="error_message">{t(errors.lastName)}</p>
          )}
        </MDBox>
        <MDBox p={2}>
          <MDInput
            type="tel"
            name="phone"
            label={t("TABLE.PHONE")}
            value={phone}
            onChange={(e) => handleChange(e)}
            error={!errors ? null : errors.phone}
            fullWidth
          />
          {!errors ? null : <p className="error_message">{t(errors.phone)}</p>}
        </MDBox>
        <MDBox display="flex" justifyContent="center" p={2}>
          <MDButton color="primary" onClick={handleSubmit}>
            {t("STAFF.UPDATE_INFO")}
          </MDButton>
        </MDBox>
      </MDBox>
    </MDBox>
  );
};

export default UpdateAdmin;
