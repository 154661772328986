export default function validate(
  firstName,
  lastName,
  profileEmail,
  paymentEmail,
  phone,
  address,
  city,
  province,
  postalCode,
  artistName,
  website,
  description
) {
  const errors = {};
  const regexEmail =
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

  const regexPhone = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
  const regexPostalCode =
    /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i;
  const regexName = /^[0-9a-zA-Z]+$/;

  if (!firstName) {
    errors.firstName = "ERRORS.NAME_REQUIRED";
  }
  if (!lastName) {
    errors.lastName = "ERRORS.NAME_REQUIRED";
  }
  if (!profileEmail) {
    errors.profileEmail = "ERRORS.EMAIL_REQUIRED";
  } else if (!regexEmail.test(profileEmail)) {
    errors.profileEmail = "ERRORS.VALID_EMAIL";
  }
  if (!paymentEmail) {
    errors.paymentEmail = "ERRORS.EMAIL_REQUIRED";
  } else if (!regexEmail.test(paymentEmail)) {
    errors.paymentEmail = "ERRORS.VALID_EMAIL";
  }
  if (!phone) {
    errors.phone = "ERRORS.PHONE_REQUIRED";
  } else if (!regexPhone.test(phone)) {
    errors.phone = "ERRORS.VALID_PHONE";
  }
  if (!address) {
    errors.address = "ERRORS.ADDRESS_REQUIRED";
  }
  if (!city) {
    errors.city = "ERRORS.CITY_REQUIRED";
  }
  if (!province) {
    errors.province = "ERRORS.PROVINCE_REQUIRED";
  }
  if (!postalCode) {
    errors.postalCode = "ERRORS.POSTALCODE_REQUIRED";
  } else if (!regexPostalCode.test(postalCode)) {
    errors.postalCode = "ERRORS.VALID_POSTALCODE";
  }
  if (!artistName) {
    errors.artistName = "ERRORS.ARTIST_NAME_REQUIRED";
  }
  if (website) {
    if (
      !(website.indexOf("http://") === 0 || website.indexOf("https://") === 0)
    ) {
      errors.website = "ERRORS.VALID_WEBSITE";
    }
  }
  if (description.length > 500) {
    errors.description = "ERRORS.DESCRIPTION_TOO_LONG";
  }
  return errors;
}
