import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import ModalContainer from "components/ModalContainer/ModalContainer";
import MDTypography from "components/MDTypography";
import { getSettingsData } from "store/settingSlice";
import { useDispatch } from "react-redux";
import { updateAgeSimilarity } from "api/settings";
import { useTranslation } from "react-i18next";

const EditIDSimilarity = ({ idSimilarity, onButtonClose }) => {
  const {t}= useTranslation();
  const dispatch = useDispatch();
  const [ageSimilarityThreshold, setAgeSimilarityThreshold] =
    useState(idSimilarity);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const submitValues = async () => {
    try {
      const res = await updateAgeSimilarity(ageSimilarityThreshold);
      toast.success(t("TOAST.THRESHOLD_UPDATED_SUCCESSFULLY"));
      dispatch(getSettingsData());
    } catch (e) {
      if (e.response.data.message === "AGE_SIMILARITY_THRESHOLD_OUT_OF_RANGE") {
        toast.error(t("ERRORS.AGE_SIMILARITY_THRESHOLD_OUT_OF_RANGE"));
      } else {
        console.log(e.response);
        toast.error(e.response);
      }
    }
  };

  useEffect(() => {
    if (isSubmitting && Object.keys(errors).length === 0) {
      submitValues();
      setIsSubmitting(false);
      onButtonClose();
    }
  }, [errors, isSubmitting]);

  const handleChange = (e) => {
    setIsSubmitting(false);
    if (e.target.name === "ageSimilarityThreshold") {
      setAgeSimilarityThreshold(e.target.value);
    }
  };

  const handleSubmit = () => {
    let errorsCode = {};
    if (!ageSimilarityThreshold) {
      errorsCode.ageSimilarityThreshold = "ERRORS.THRESHOLD_REQUIRED";
    } else if (ageSimilarityThreshold > 100) {
      errorsCode.ageSimilarityThreshold = "ERRORS.THRESHOLD_GREATER_THAN_100";
    }
    setErrors(errorsCode);
    setIsSubmitting(true);
  };

  return (
    <ModalContainer
      title={t("SETTINGS.EDIT_AGE_SIMILARITY")}
      onButtonClose={onButtonClose}
    >
      <MDBox width="500px">
        <MDBox p={2}>
          <MDTypography variant="body2">
            {t("SETTINGS.EDIT_AGE_SIMILARITY_MESSAGE")}
          </MDTypography>
        </MDBox>
        <MDBox p={2}>
          <MDInput
            type="text"
            name="ageSimilarityThreshold"
            label={t("SETTINGS.AGE_SIMILARITY_THRESHOLD")}
            value={ageSimilarityThreshold}
            onChange={(e) => handleChange(e)}
            error={!errors ? null : errors.ageSimilarityThreshold}
            fullWidth
          />
          {!errors ? null : (
            <p className="error_message">{t(errors.ageSimilarityThreshold)}</p>
          )}
        </MDBox>
        <MDBox display="flex" justifyContent="center">
          <MDButton color="primary" onClick={handleSubmit}>
            {t("SETTINGS.SUBMIT")}
          </MDButton>
        </MDBox>
      </MDBox>
    </ModalContainer>
  );
};

export default EditIDSimilarity;
