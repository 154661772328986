import React from "react";
import xIcon from "../../assets/images/ink_me/icons/icon_x_white.svg";
import "./ModalContainer.css";

const ModalContainer = ({ title, onButtonClose, children }) => {
  return (
    <div className="modal_background_container">
      <div className="modal_container">
        <div className="modal_title">
          <h3>{title}</h3>
          <img src={xIcon} alt="x icon" onClick={onButtonClose} />
        </div>
        <div className="modal_content">{children}</div>
      </div>
    </div>
  );
};

export default ModalContainer;
