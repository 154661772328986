import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getCustomerTable,
  getCustomerDetails,
  blockAndUnblockCustomer,
} from "../api/customers";

const initialState = {
  loading: false,
  customers: {},
  totalCount: 0,
  pageIndex: 0,
  errorMessage: "",
  customerDetailState: false,
  customerDetails: {},
  successMessage: "",
  successModalState: false,
  failModalState: false,
};

export const getCustomerData = createAsyncThunk(
  "customer/getCustomerData",
  // eslint-disable-next-line consistent-return
  async (data, thunkAPI) => {
    try {
      const res = await getCustomerTable(data);
      return res;
    } catch (error) {
      if (error.response.status === 400) {
        return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
      }
      if (error.response.status === 500) {
        return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
      } else {
        return thunkAPI.rejectWithValue(error.response);
      }
    }
  }
);

export const getCustomerDetailsData = createAsyncThunk(
  "customer/getCustomerDetailsData",
  // eslint-disable-next-line consistent-return
  async (id, thunkAPI) => {
    try {
      const res = await getCustomerDetails(id);
      return res;
    } catch (error) {
      if (error.response.status === 400) {
        return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
      }
      if (error.response.status === 500) {
        return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
      } else {
        return thunkAPI.rejectWithValue(error.response);
      }
    }
  }
);

export const changeCustomerBlockUnblock = createAsyncThunk(
  "customer/changeCustomerBlockUnblock",
  // eslint-disable-next-line consistent-return
  async (data, thunkAPI) => {
    try {
      const res = await blockAndUnblockCustomer(data);
      return res;
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
        }
        if (error.response.status === 500) {
          return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
        } else {
          return thunkAPI.rejectWithValue(error.response);
        }
      }
    }
  }
);

export const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    setPageIndex: (state, action) => {
      state.pageIndex = action.payload;
    },
    setCustomerDetailState: (state, action) => {
      state.customerDetailState = action.payload;
    },
    toggleSuccessModalState: (state) => {
      state.successModalState = !state.successModalState;
    },
    toggleFailModalState: (state) => {
      state.failModalState = !state.failModalState;
    },
    resetErrorMessage: (state) => {
      state.errorMessage = "";
    },
    resetSuccessMessage: (state) => {
      state.successMessage = "";
    },
  },
  extraReducers: {
    [getCustomerData.pending]: (state) => {
      state.errorMessage = "";
      state.loading = true;
    },
    [getCustomerData.rejected]: (state, action) => {
      state.loading = false;
      state.errorMessage = action.payload;
    },
    [getCustomerData.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.customers = payload;
      state.totalCount = payload.totalCount;
    },
    [getCustomerDetailsData.pending]: (state) => {
      state.errorMessage = "";
      state.loading = true;
    },
    [getCustomerDetailsData.rejected]: (state, action) => {
      state.loading = false;
      state.errorMessage = action.payload;
    },
    [getCustomerDetailsData.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.customerDetails = payload;
      state.customerDetailState = true;
    },
    [changeCustomerBlockUnblock.pending]: (state) => {
      state.errorMessage = "";
      state.loading = true;
    },
    [changeCustomerBlockUnblock.rejected]: (state, action) => {
      state.loading = false;
      state.errorMessage = action.payload;
      state.failModalState = true;
    },
    [changeCustomerBlockUnblock.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.customerDetails.status = payload.status;
      state.successModalState = true;
      state.successMessage = "CUSTOMERS.SUCCESS_BLOCK_CUSTOMER";
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setPageIndex,
  setCustomerDetailState,
  toggleFailModalState,
  toggleSuccessModalState,
  resetErrorMessage,
  resetSuccessMessage,
} = customerSlice.actions;

export default customerSlice.reducer;
