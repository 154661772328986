import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";

import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import Icon from "@mui/material/Icon";
import { Card } from "@mui/material";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { htmlDecode } from "helpers/helpers";
import { getContent } from "api/settings";
import { useTranslation } from "react-i18next";

import { updateContent } from "api/settings";

function PrivacyPolicy({ onClose }) {
  const { t } = useTranslation();
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [privacyPolicyData, setPrivacyPolicyData] = useState();

  useEffect(() => {
    const getPrivacyPolicy = async () => {
      const res = await getContent("Privacy_Policy", "en");
      setPrivacyPolicyData(res);
    };
    getPrivacyPolicy();
  }, []);

  useEffect(() => {
    if (privacyPolicyData) {
      const htmlContent = htmlDecode(privacyPolicyData.htmlContent);
      const contentBlock = htmlToDraft(htmlContent);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        const editorState = EditorState.createWithContent(contentState);
        setEditorState(editorState);
      }
    }
  }, [privacyPolicyData]);

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  const handleUpdate = async () => {
    let userLang = navigator.language || navigator.userLanguage;
    if (userLang.includes("en")) {
      userLang = "en";
    }
    const data = {
      name: "Privacy_Policy",
      htmlContent: draftToHtml(
        convertToRaw(editorState.getCurrentContent())
      ).toString(),
      language: userLang,
    };
    try {
      const res = await updateContent(data);
      toast.success(t("TOAST.PRIVACY_POLICY_UPDATED_SUCCESSFULLY"));
      onClose();
    } catch (e) {
      if (e.response.data.message === "CONTENT_IS_IDENTICAL") {
        toast.error(t("ERRORS.CONTENT_IS_IDENTICAL"));
      } else {
        console.log(e.response);
        toast.error(e.response);
      }
    }
  };

  return (
    <>
      <Card>
        <MDBox p={2} pb={5}>
          <MDBox display="flex" alignItems="center">
            <MDBox mr={2} onClick={() => onClose()}>
              <Icon>arrow_back</Icon>
            </MDBox>
            <MDTypography variant="h5" fontWeight="medium">
              {t("CONTENT.PRIVACY_POLICY")}
            </MDTypography>
          </MDBox>
          <MDBox p={2}>
            <form>
              <Editor
                className="editor"
                toolbar={{
                  options: [
                    "inline",
                    "blockType",
                    "fontSize",
                    "fontFamily",
                    "list",
                    "textAlign",
                    "colorPicker",
                    "link",
                    "history",
                  ],
                  inline: { inDropdown: true },
                  list: { inDropdown: true },
                  textAlign: { inDropdown: true },
                  link: { inDropdown: true },
                  history: { inDropdown: true },
                }}
                editorState={editorState}
                toolbarClassName="editor-toolbar"
                wrapperClassName="editor-wrapper"
                editorClassName="editor-content"
                onEditorStateChange={onEditorStateChange}
              />
              <MDBox display="flex" justifyContent="center" pt={3}>
                <MDButton
                  onClick={handleUpdate}
                  color="primary"
                  size="medium"
                  className="btn-round"
                >
                  {t("CONTENT.SAVE")}
                </MDButton>
              </MDBox>
            </form>
          </MDBox>
        </MDBox>
      </Card>
    </>
  );
}

export default PrivacyPolicy;
