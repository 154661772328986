// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function StatisticsCard({ title, count }) {
  return (
    <Card>
      <MDBox p={2}>
        <Grid item xs={12}>
          <MDBox mb={0.5} lineHeight={1}>
            <MDTypography
              variant="button"
              fontWeight="medium"
              color="text"
              textTransform="capitalize"
            >
              {title}
            </MDTypography>
          </MDBox>
          <MDBox lineHeight={1}>
            <MDTypography variant="h5" fontWeight="bold">
              {count}
            </MDTypography>
          </MDBox>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default StatisticsCard;
