/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
// @mui material components
import Card from "@mui/material/Card";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import CreateAdmin from "./CreateAdmin";
import AdminDetails from "./AdminDetails";
import SuccessModal from "components/SuccessModal/SuccessModal";
import FailModal from "components/FailModal/FailModal";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "components/DataTable";
import ViewMoreCell from "./ViewMoreCell";

import {
  getStaffData,
  toggleCreateNewAdminState,
  toggleSuccessModalState,
  toggleFailModalState,
  resetErrorMessage,
  resetSuccessMessage,
  toggleAdminDetailsState,
} from "../../../../store/staffSlice";

function Staff() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const staffData = useSelector((state) => state.staff.staff);
  const totalCount = useSelector((state) => state.staff.totalCount);
  const createNewAdminState = useSelector(
    (state) => state.staff.createNewAdminState
  );
  const adminDetailsState = useSelector(
    (state) => state.staff.adminDetailsState
  );
  const [filterOption, setFilterOptions] = useState("");
  const [menu, setMenu] = useState(null);
  const successModalState = useSelector(
    (state) => state.staff.successModalState
  );
  const failModalState = useSelector((state) => state.staff.failModalState);
  const errorMessage = useSelector((state) => state.staff.errorMessage);
  const successMessage = useSelector((state) => state.staff.successMessage);
  const [pageIndex, setPageIndex] = useState(1);
  const pageSize = 10;
  const loading = useSelector((state) => state.customer.loading);
  const [searchTerms, setSearchTerms] = useState("");
  const [staff, setStaff] = useState({});

  useEffect(() => {
    if (staffData?.totalCount > 0) {
      setStaff({
        columns: [
          { Header: t("TABLE.FIRST_NAME"), accessor: "firstName" },
          { Header: t("TABLE.LAST_NAME"), accessor: "lastName" },
          { Header: t("TABLE.EMAIL"), accessor: "email" },
          { Header: t("TABLE.PHONE"), accessor: "phone" },
          {
            Header: t("TABLE.ROLE"),
            accessor: "isSuperAdmin",
          },
          {
            Header: t("TABLE.VIEW_MORE"),
            accessor: "viewMore",
          },
        ],
        rows: staffData.admins.map((staff) => {
          return {
            firstName: staff.firstName,
            lastName: staff.lastName,
            email: staff.email,
            phone: staff.phone,
            isSuperAdmin: staff.isSuperAdmin
              ? t("STAFF.SUPERADMIN")
              : t("STAFF.ADMIN"),
            viewMore: <ViewMoreCell id={staff.id} />,
          };
        }),
      });
    } else {
      setStaff({
        columns: [
          { Header: t("TABLE.FIRST_NAME"), accessor: "firstName" },
          { Header: t("TABLE.LAST_NAME"), accessor: "lastName" },
          { Header: t("TABLE.EMAIL"), accessor: "email" },
          { Header: t("TABLE.PHONE"), accessor: "phone" },
          {
            Header: t("TABLE.ROLE"),
            accessor: "isSuperAdmin",
          },
          {
            Header: t("TABLE.VIEW_MORE"),
            accessor: "viewMore",
          },
        ],
        rows: [],
      });
    }
  }, [staffData]);

  useEffect(() => {
    if (searchTerms.length > 0) {
      const timeoutId = setTimeout(() => {
        const data = {
          pageIndex,
          pageSize,
          searchTerms,
          forceSearchTerms: filterOption,
        };
        dispatch(getStaffData(data));
      }, 1000);
      return () => {
        clearTimeout(timeoutId);
      };
    } else {
      const data = {
        pageIndex,
        pageSize,
        searchTerms,
        forceSearchTerms: filterOption,
      };
      dispatch(getStaffData(data));
    }

    return () => {
      if (adminDetailsState) {
        dispatch(toggleAdminDetailsState());
      }
    };
  }, [filterOption, pageIndex, searchTerms]);

  useEffect(() => {
    let timer1;
    if (successModalState) {
      timer1 = setTimeout(() => {
        dispatch(toggleSuccessModalState());
        dispatch(resetSuccessMessage());
      }, 1500);
    }

    return () => {
      clearTimeout(timer1);
    };
  }, [successModalState]);

  useEffect(() => {
    let timer1;
    if (failModalState) {
      timer1 = setTimeout(() => {
        dispatch(toggleFailModalState());
        dispatch(resetErrorMessage());
      }, 1500);
    }

    return () => {
      clearTimeout(timer1);
    };
  }, [failModalState]);

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = (state) => {
    if (
      state === "Active" ||
      state === "Blocked" ||
      state === "Admins" ||
      state === "SuperAdmins"
    ) {
      setFilterOptions(state);
    } else if (state === "None") {
      setFilterOptions("");
    }
    setMenu(null);
  };

  const renderMenu = (
    <Menu
      anchorEl={menu}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu)}
      onClose={closeMenu}
      keepMounted
    >
      <MenuItem onClick={() => closeMenu("Admins")}>
        {t("STAFF.FILTER_ADMIN")}
      </MenuItem>
      <MenuItem onClick={() => closeMenu("SuperAdmins")}>
        {t("STAFF.FILTER_SUPERADMIN")}
      </MenuItem>
      <MenuItem onClick={() => closeMenu("Active")}>
        {t("STAFF.FILTER_ACTIVE")}
      </MenuItem>
      <MenuItem onClick={() => closeMenu("Blocked")}>
        {t("STAFF.FILTER_BLOCKED")}
      </MenuItem>
      <MenuItem onClick={() => closeMenu("None")}>
        {t("ARTISTS.FILTER_NONE")}
      </MenuItem>
    </Menu>
  );

  const renderContent = () => {
    if (createNewAdminState) {
      return <CreateAdmin />;
    } else if (adminDetailsState) {
      return <AdminDetails />;
    } else {
      return (
        <>
          <MDBox display="flex" justifyContent="flex-end" m={2}>
            <MDButton
              color="primary"
              onClick={() => dispatch(toggleCreateNewAdminState())}
            >
              {t("STAFF.CREATE_NEW_ADMIN")}
            </MDButton>
          </MDBox>
          <MDBox px={3} py={2} lineHeight={1}>
            <MDTypography variant="h5" fontWeight="medium">
              {t("USERS.STAFF")}
            </MDTypography>
          </MDBox>
          <MDBox display="flex" justifyContent="flex-end" px={3}>
            <MDButton
              variant={menu ? "contained" : "outlined"}
              color="dark"
              onClick={openMenu}
            >
              {t("TABLE.FILTERS")}&nbsp;
              <Icon>keyboard_arrow_down</Icon>
            </MDButton>
            {renderMenu}
          </MDBox>
          {!staff || Object.keys(staff).length === 0 ? null : (
            <DataTable
              table={staff}
              totalCount={totalCount}
              filterOption={filterOption}
              canSearch
              setPageIndex={setPageIndex}
              parentPageIndex={pageIndex}
              parentPageSize={pageSize}
              loading={loading}
              setSearchTerms={setSearchTerms}
              searchTerms={searchTerms}
            />
          )}
        </>
      );
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Card>{renderContent()}</Card>
      </MDBox>
      {failModalState ? (
        <FailModal title={t("ERRORS.ERROR")} message={t(errorMessage)} />
      ) : null}
      {successModalState ? (
        <SuccessModal title={t("AUTH.SUCCESS")} message={t(successMessage)} />
      ) : null}
    </DashboardLayout>
  );
}

export default Staff;
