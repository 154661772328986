export default function validate(firstName, lastName, email, phone) {
  const errors = {};
  const regexEmail =
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

  const regexPhone = /@^\d+$/;
  
  if (!firstName) {
    errors.firstName = "ERRORS.NAME_REQUIRED";
  }
  if (!lastName) {
    errors.lastName = "ERRORS.NAME_REQUIRED";
  }
  if (!email) {
    errors.email = "ERRORS.EMAIL_REQUIRED";
  } else if (!regexEmail.test(email)) {
    errors.email = "ERRORS.VALID_EMAIL";
  }
  if (!phone) {
    errors.phone = "ERRORS.PHONE_REQUIRED";
  } else if (phone.substring(0, 2) !== "+1") {
    errors.phone = "ERRORS.VALID_PHONE";
  } else if (regexPhone.test(phone.substring(1))) {
    errors.phone = "ERRORS.VALID_PHONE";
  } else if (phone.length !== 12) {
    errors.phone = "ERRORS.VALID_PHONE";
  }
  return errors;
}
