import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  approveOrDeclineArtist,
  blockAndUnblockArtist,
  editArtistDetails,
  getArtist,
  getArtistDetails,
} from "../api/artist";

const initialState = {
  loading: false,
  artists: {},
  totalCount: 0,
  pageIndex: 0,
  errorMessage: "",
  artistDetailState: false,
  artistDetails: {},
  updateProfileState: false,
  generalError: false,
  successMessage: "",
  successModalState: false,
  failModalState: false,
};

export const getArtists = createAsyncThunk(
  "Artist/getArtists",
  // eslint-disable-next-line consistent-return
  async (data, thunkAPI) => {
    try {
      const res = await getArtist(data);
      return res;
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
        }
        if (error.response.status === 500) {
          return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
        } else {
          return thunkAPI.rejectWithValue(error.response);
        }
      }
    }
  }
);

export const getArtistDetailsData = createAsyncThunk(
  "artist/getArtistDetailsData",
  // eslint-disable-next-line consistent-return
  async (id, thunkAPI) => {
    try {
      const res = await getArtistDetails(id);
      return res;
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
        }
        if (error.response.status === 500) {
          return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
        } else {
          return thunkAPI.rejectWithValue(error.response);
        }
      }
    }
  }
);

export const updateArtistProfile = createAsyncThunk(
  "artist/updateArtistProfile",
  // eslint-disable-next-line consistent-return
  async (data, thunkAPI) => {
    try {
      const res = await editArtistDetails(data.id, data);
      return res;
    } catch (error) {
      if (error.response) {
        if (error.response.data.message === "BAD_PAYMENT_EMAIL") {
          return thunkAPI.rejectWithValue("ERRORS.BAD_PAYMENT_EMAIL");
        }
        if (error.response.data.message === "BAD_PROFILE_EMAIL") {
          return thunkAPI.rejectWithValue("ERRORS.BAD_PROFILE_EMAIL");
        }
        if (error.response.data.message === "TOO_MANY_TAGS") {
          return thunkAPI.rejectWithValue("ERRORS.TOO_MANY_TAGS");
        }  if (error.response.data.message === "INVALID_ADDRESS") {
          return thunkAPI.rejectWithValue("ERRORS.INVALID_ADDRESS");
        }
        if (error.response.status === 400) {
          return thunkAPI.rejectWithValue("ERRORS.INCORRECT_LOGIN");
        }
        if (error.response.status === 500) {
          return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
        }
        return thunkAPI.rejectWithValue(error.response);
      }
    }
  }
);

export const updateArtistStatusApproveDecline = createAsyncThunk(
  "artist/updateArtistStatusApproveDecline",
  // eslint-disable-next-line consistent-return
  async (data, thunkAPI) => {
    try {
      const res = await approveOrDeclineArtist(data);
      return res;
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
        }
        if (error.response.status === 500) {
          return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
        } else {
          return thunkAPI.rejectWithValue(error.response);
        }
      }
    }
  }
);

export const changeArtistBlockUnblock = createAsyncThunk(
  "artst/changeArtistBlockUnblock",
  // eslint-disable-next-line consistent-return
  async (data, thunkAPI) => {
    try {
      const res = await blockAndUnblockArtist(data);
      return res;
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
        }
        if (error.response.status === 500) {
          return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
        } else {
          return thunkAPI.rejectWithValue(error.response);
        }
      }
    }
  }
);

export const artistSlice = createSlice({
  name: "artist",
  initialState,
  reducers: {
    setPageIndex: (state, action) => {
      state.pageIndex = action.payload;
    },
    setArtistDetailState: (state, action) => {
      state.artistDetailState = action.payload;
    },
    toggleUpdateProfileState: (state, action) => {
      state.updateProfileState = !state.updateProfileState;
    },
    resetArtistGeneralError: (state) => {
      state.errorMessage = "";
      state.generalError = false;
    },
    toggleSuccessModalState: (state) => {
      state.successModalState = !state.successModalState;
    },
    toggleFailModalState: (state) => {
      state.failModalState = !state.failModalState;
    },
    resetErrorMessage: (state) => {
      state.errorMessage = "";
    },
    resetSuccessMessage: (state) => {
      state.successMessage = "";
    },
  },
  extraReducers: {
    [getArtists.pending]: (state) => {
      state.errorMessage = "";
      state.loading = true;
    },
    [getArtists.rejected]: (state, action) => {
      state.loading = false;
      state.errorMessage = action.payload;
    },
    [getArtists.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.artists = payload;
      state.totalCount = payload.totalCount;
    },
    [getArtistDetailsData.pending]: (state) => {
      state.errorMessage = "";
      state.loading = true;
    },
    [getArtistDetailsData.rejected]: (state, action) => {
      state.loading = false;
      state.errorMessage = action.payload;
    },
    [getArtistDetailsData.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.artistDetails = payload;
      state.artistDetailState = true;
      state.updateProfileState = false;
    },
    [updateArtistProfile.pending]: (state) => {
      state.errorMessage = "";
      state.loading = true;
    },
    [updateArtistProfile.rejected]: (state, action) => {
      state.loading = false;
      if (
        action.payload === "ERRORS.BAD_PAYMENT_EMAIL" ||
        action.payload === "ERRORS.BAD_PROFILE_EMAIL" ||
        action.payload === "ERRORS.TOO_MANY_TAGS"
      ) {
        state.errorMessage = action.payload;
      } else {
        state.artistDetailState = true;
        state.updateProfileState = false;
        state.generalError = true;
      }
      state.errorMessage = action.payload;
      state.failModalState = true;
    },
    [updateArtistProfile.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.artistDetails.firstName = payload.firstName;
      state.artistDetails.lastName = payload.lastName;
      state.artistDetails.profileEmail = payload.profileEmail;
      state.artistDetails.paymentEmail = payload.paymentEmail;
      state.artistDetails.banner = payload.banner;
      state.artistDetails.studioName = payload.studioName;
      state.artistDetails.street = payload.street;
      state.artistDetails.city = payload.city;
      state.artistDetails.province = payload.province;
      state.artistDetails.postalCode = payload.postalCode;
      state.artistDetails.phone = payload.phone;
      state.artistDetails.website = payload.website;
      state.artistDetails.instagram = payload.instagram;
      state.artistDetails.description = payload.description;
      state.artistDetails.tags = payload.tags;
      state.artistDetails.artistProfileImages = payload.artistProfileImages;
      state.artistDetails.artistName = payload.artistName;
      state.artistDetails.status = payload.status;
      state.artistDetailState = true;
      state.updateProfileState = false;
      state.successModalState = true;
      state.successMessage = "ARTISTS.SUCCESS_UPDATE_ARTIST";
    },
    [updateArtistStatusApproveDecline.pending]: (state) => {
      state.errorMessage = "";
      state.loading = true;
    },
    [updateArtistStatusApproveDecline.rejected]: (state, action) => {
      state.loading = false;
      state.errorMessage = action.payload;
      state.generalError = true;
      state.failModalState = true;
    },
    [updateArtistStatusApproveDecline.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.artistDetails.status = payload.status;
      state.artistDetailState = true;
      state.successModalState = true;
      state.successMessage = "ARTISTS.SUCCESS_BLOCK_ARTIST";
    },
    [changeArtistBlockUnblock.pending]: (state) => {
      state.errorMessage = "";
      state.loading = true;
    },
    [changeArtistBlockUnblock.rejected]: (state, action) => {
      state.loading = false;
      state.errorMessage = action.payload;
      state.failModalState = true;
    },
    [changeArtistBlockUnblock.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.artistDetails.status = payload.status;
      state.successModalState = true;
      state.successMessage = "ARTISTS.SUCCESS_BLOCK_ARTIST";
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setPageIndex,
  setArtistDetailState,
  toggleUpdateProfileState,
  resetArtistGeneralError,
  toggleFailModalState,
  toggleSuccessModalState,
  resetErrorMessage,
  resetSuccessMessage,
} = artistSlice.actions;

export default artistSlice.reducer;
