import React from "react";
import { useSelector } from "react-redux";

import "./Loader.css";

function Loader() {
  const loading = useSelector((state) => state.auth.loading);
  const artistLoading = useSelector((state) => state.artist.loading);
  const customerLoading = useSelector((state) => state.customer.loading);
  const staffLoading = useSelector((state) => state.staff.loading);

  return (
    <div
      className={
        loading || artistLoading || customerLoading || staffLoading
          ? "show_loading"
          : "hidden"
      }
    >
      <div className="loading_background_container">
        <div className="loading_container">
          <div className="lds-spinner">
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Loader;
