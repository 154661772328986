import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  PdfViewerComponent,
  Toolbar,
  Magnification,
  Navigation,
  LinkAnnotation,
  BookmarkView,
  ThumbnailView,
  Print,
  Annotation,
  TextSelection,
  TextSearch,
  Inject,
  FormDesigner,
  FormFields,
} from "@syncfusion/ej2-react-pdfviewer";
import ModalContainer from "components/ModalContainer/ModalContainer";

import { getContract } from "api/loan";

import "./PDFViewer.css";

const PDFVIewer = ({ togglePDFViewer }) => {
  const { t } = useTranslation();
  const viewerRef = useRef(null);
  const loanId = useSelector((state) => state.loan.loanDetails.id);

  const viewerCreatedHandler = async () => {
    const data = {
      id: loanId,
    };
    try {
      const res = await getContract(data);
      viewerRef.current.load(`data:application/pdf;base64, ${res}`, null);
      viewerRef.current.fileName = "contract.pdf";
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <ModalContainer
        title={t("LOAN.VIEW_CONTRACT")}
        onButtonClose={() => togglePDFViewer(false)}
      >
        <div className="contract_container">
          <div className="control-section">
            <PdfViewerComponent
              id="container"
              ref={viewerRef}
              serviceUrl={`${process.env.REACT_APP_BACKEND_BASE_URL}/pdfviewer`}
              created={viewerCreatedHandler}
              showNotificationDialog={false}
              style={{ height: "640px" }}
              toolbarSettings={{
                showTooltip: true,
                toolbarItems: [
                  "OpenOption",
                  "PageNavigationTool",
                  "MagnificationTool",
                  "PanTool",
                  "SelectionTool",
                  "SignatureOption",
                  "SearchOption",
                  "DownloadOption",
                ],
                annotationToolbarItems: [
                  "HighlightTool",
                  "UnderlineTool",
                  "StrikethroughTool",
                  "ColorEditTool",
                  "OpacityEditTool",
                  "AnnotationDeleteTool",
                  "StampAnnotationTool",
                  "HandWrittenSignatureTool",
                  "InkAnnotationTool",
                  "ShapeTool",
                  "CalibrateTool",
                  "StrokeColorEditTool",
                  "ThicknessEditTool",
                  "FreeTextAnnotationTool",
                  "FontFamilyAnnotationTool",
                  "FontSizeAnnotationTool",
                  "FontStylesAnnotationTool",
                  "FontAlignAnnotationTool",
                  "FontColorAnnotationTool",
                  "CommentPanelTool",
                ],
                formDesignerToolbarItems: [
                  "TextboxTool",
                  "PasswordTool",
                  "CheckBoxTool",
                  "RadioButtonTool",
                  "DropdownTool",
                  "ListboxTool",
                  "DrawSignatureTool",
                  "DeleteTool",
                ],
              }}
            >
              <Inject
                services={[
                  Toolbar,
                  Magnification,
                  Annotation,
                  Navigation,
                  LinkAnnotation,
                  BookmarkView,
                  ThumbnailView,
                  Print,
                  TextSelection,
                  TextSearch,
                  FormFields,
                  FormDesigner,
                ]}
              />
            </PdfViewerComponent>
          </div>
        </div>
      </ModalContainer>
    </div>
  );
};

export default PDFVIewer;
