import React, { useEffect, useState } from "react";

import { toast } from "react-toastify";

import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import ModalContainer from "components/ModalContainer/ModalContainer";
import MDTypography from "components/MDTypography";
import { getSettingsData } from "store/settingSlice";
import { useDispatch } from "react-redux";
import { updateFailedPaymentDays } from "api/settings";
import { useTranslation } from "react-i18next";

const EditFailedPaymentDays = ({
  failed1,
  failed2,
  failed3,
  onButtonClose,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [failedPayment1, setFailedPayment1] = useState(failed1);
  const [failedPayment2, setFailedPayment2] = useState(failed2);
  const [failedPayment3, setFailedPayment3] = useState(failed3);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const submitValues = async () => {
    const data = {
      failedPaymentDaysUntilTry2: failedPayment1,
      failedPaymentDaysUntilTry3: failedPayment2,
      failedPaymentDaysUntilTry4: failedPayment3,
    };
    try {
      const res = await updateFailedPaymentDays(data);
      toast.success(t("TOAST.FAILED_PAYMENT_DAYS_UPDATED_SUCCESSFULLY"));
      dispatch(getSettingsData());
    } catch (e) {
      console.log(e);
      if (
        e.response.data.message === "DAYS_MUST_INCREASE_IN_VALUE_BETWEEN_TRYS"
      ) {
        toast.error(t("ERRORS.DAYS_MUST_INCREASE_IN_VALUE_BETWEEN_TRYS"));
      } else {
        console.log(e.response);
        toast.error(e.response);
      }
    }
  };

  useEffect(() => {
    if (isSubmitting && Object.keys(errors).length === 0) {
      submitValues();
      setIsSubmitting(false);
      onButtonClose();
    }
  }, [errors, isSubmitting]);

  const handleChange = (e) => {
    setIsSubmitting(false);
    if (e.target.name === "failedPayment1") {
      setFailedPayment1(e.target.value);
    }
    if (e.target.name === "failedPayment2") {
      setFailedPayment2(e.target.value);
    }
    if (e.target.name === "failedPayment3") {
      setFailedPayment3(e.target.value);
    }
  };

  const handleSubmit = () => {
    let errorsCode = {};
    if (!failedPayment1) {
      errorsCode.failedPayment1 = "ERRORS.FAILED_PAYMENT_DAY_REQUIRED";
    } else if (failedPayment1 < 1) {
      errorsCode.failedPayment = "ERRORS.FAILED_PAYMENT_DAY_INVALID_VALUE";
    }
    if (!failedPayment2) {
      errorsCode.failedPayment2 = "ERRORS.FAILED_PAYMENT_DAY_REQUIRED";
    } else if (failedPayment2 <= failedPayment1) {
      errorsCode.failedPayment2 =
        "ERRORS.DAYS_MUST_INCREASE_IN_VALUE_BETWEEN_TRYS";
    }
    if (!failedPayment3) {
      errorsCode.failedPayment3 = "ERRORS.FAILED_PAYMENT_DAY_REQUIRED";
    } else if (failedPayment3 <= failedPayment2) {
      errorsCode.failedPayment3 =
        "ERRORS.DAYS_MUST_INCREASE_IN_VALUE_BETWEEN_TRYS";
    }
    setErrors(errorsCode);
    setIsSubmitting(true);
  };

  return (
    <ModalContainer
      title={t("SETTINGS.EDIT_FAILED_PAYMENT_DAYS")}
      onButtonClose={onButtonClose}
    >
      <MDBox width="500px">
        <MDBox p={2}>
          <MDTypography variant="body2">
            {t("SETTINGS.EDIT_FAILED_PAYMENT_DAYS_MESSAGE")}
          </MDTypography>
        </MDBox>
        <MDBox p={2}>
          <MDInput
            type="number"
            name="failedPayment1"
            label={t("SETTINGS.FAILED_PAYMENT_TRY2")}
            value={failedPayment1}
            onChange={(e) => handleChange(e)}
            error={!errors ? null : errors.failedPayment1}
            fullWidth
          />
          {!errors ? null : (
            <p className="error_message">{t(errors.failedPayment1)}</p>
          )}
        </MDBox>
        <MDBox p={2}>
          <MDInput
            type="number"
            name="failedPayment2"
            label={t("SETTINGS.FAILED_PAYMENT_TRY3")}
            value={failedPayment2}
            onChange={(e) => handleChange(e)}
            error={!errors ? null : errors.failedPayment2}
            fullWidth
          />
          {!errors ? null : (
            <p className="error_message">{t(errors.failedPayment2)}</p>
          )}
        </MDBox>
        <MDBox p={2}>
          <MDInput
            type="number"
            name="failedPayment3"
            label={t("SETTINGS.FAILED_PAYMENT_TRY4")}
            value={failedPayment3}
            onChange={(e) => handleChange(e)}
            error={!errors ? null : errors.failedPayment3}
            fullWidth
          />
          {!errors ? null : (
            <p className="error_message">{t(errors.failedPayment3)}</p>
          )}
        </MDBox>
        <MDBox display="flex" justifyContent="center">
          <MDButton color="primary" onClick={handleSubmit}>
            {t("SETTINGS.SUBMIT")}
          </MDButton>
        </MDBox>
      </MDBox>
    </ModalContainer>
  );
};

export default EditFailedPaymentDays;
