import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import MDBox from "components/MDBox";
import InApplyingProcessDetails from "./InApplyingProcessDetails";
import ActiveLoanDetails from "./ActiveLoanDetails";
import MDButton from "components/MDButton";
import FailModal from "components/FailModal/FailModal";
import SuccessModal from "components/SuccessModal/SuccessModal";

import MDTypography from "components/MDTypography";
import { getLoanDetails, setLoanDetailState } from "store/loanSlice";
import { reactivateLoan } from "api/loan";
import { removeLateFee } from "api/loan";

const LoanDetails = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loanDetails = useSelector((state) => state.loan.loanDetails);
  const loanDetailState = useSelector((state) => state.loan.loanDetailState);
  const [successModalState, setSuccessModalState] = useState(false);
  const [failModalState, setFailModalState] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [error, setError] = useState();

  useEffect(() => {
    return () => {
      if (loanDetailState) {
        dispatch(setLoanDetailState(false));
      }
    };
  }, []);

  useEffect(() => {
    let timer1;
    if (successModalState) {
      timer1 = setTimeout(() => {
        setSuccessModalState(false);
        dispatch(getLoanDetails(loanDetails.id));
      }, 1500);
    }

    return () => {
      clearTimeout(timer1);
    };
  }, [successModalState, loanDetails.id]);

  useEffect(() => {
    let timer1;
    if (failModalState) {
      timer1 = setTimeout(() => {
        setFailModalState(false);
        setError();
      }, 1500);
    }

    return () => {
      clearTimeout(timer1);
    };
  }, [failModalState]);

  const handleReactivateLoan = async (e) => {
    e.preventDefault();
    try {
      const res = await reactivateLoan(loanDetails.id);
      setSuccessModalState(true);
      setSuccessMessage("LOAN.SUCCESS_REACTIVATE");
    } catch (error) {
      console.log(error.response);
      if (error.response.data.message === "NO_UNUSED_AMOUNT") {
        setFailModalState(true);
        setError("ERRORS.NO_UNUSED_AMOUNT");
      } else {
        setFailModalState(true);
        setError("ERRORS.GENERAL_ERROR");
      }
    }
  };

  const handleRemoveLateFee = async (e) => {
    e.preventDefault();
    try {
      const res = await removeLateFee(loanDetails.id);
      setSuccessModalState(true);
      setSuccessMessage("LOAN.REMOVE_LATE_FEE_SUCCESS");
    } catch (error) {
      console.log(error.response);
      setFailModalState(true);
      setError("ERRORS.GENERAL_ERROR");
    }
  };

  const renderDetails = () => {
    if (!loanDetails || !loanDetails.details) {
      return;
    }
    if (
      loanDetails.details.loanStatus === "In Applying Process" ||
      loanDetails.status === "Risk_Score_Denied" ||
      loanDetails.status === "Age_Denied"
    ) {
      return <InApplyingProcessDetails />;
    } else {
      return <ActiveLoanDetails />;
    }
  };

  const renderStatus = () => {
    if (
      !loanDetails ||
      !loanDetails.details ||
      !loanDetails.details.loanStatus
    ) {
      return;
    } else if (loanDetails.details.loanStatus === "In Applying Process") {
      return t("LOAN.IN_APPLYING_PROCESS");
    } else if (loanDetails.details.loanStatus === "Pending Approval") {
      return t("LOAN.PENDING_APPROVAL");
    } else if (loanDetails.details.loanStatus === "Active") {
      return t("LOAN.ACTIVE");
    } else if (loanDetails.details.loanStatus === "Tattoo Complete") {
      return t("LOAN.TATTOO_COMPLETE");
    } else if (loanDetails.details.loanStatus === "Complete And Paid") {
      return t("LOAN.COMPLETE_PAID");
    } else if (loanDetails.details.loanStatus === "Cancelled") {
      return t("LOAN.CANCELLED");
    } else if (loanDetails.details.loanStatus === "Denied") {
      return t("LOAN.DENIED");
    } else if (loanDetails.details.loanStatus === "Admin Hold") {
      return t("LOAN.ADMIN_HOLD");
    } else {
      return t("LOAN.IN_COLLECTIONS");
    }
  };

  return (
    <>
      <MDBox display="flex" justifyContent="flex-end" px={3}>
        <MDTypography variant="h6" color="primary" pb={2}>
          {t("TABLE.STATUS")}
          {": "}
          {renderStatus()}
        </MDTypography>
      </MDBox>
      {!!loanDetails.details &&
      (loanDetails.details.loanStatus === "Complete And Paid" ||
        loanDetails.details.loanStatus === "Tattoo Complete") ? (
        <MDBox display="flex" justifyContent="flex-end" px={3}>
          <MDButton color="primary" onClick={handleReactivateLoan}>
            {t("LOAN.REACTIVATE_LOAN")}
          </MDButton>
        </MDBox>
      ) : null}
      {!!loanDetails.details &&
      loanDetails.details.paymentStatus === "Overdue" ? (
        <MDBox display="flex" justifyContent="flex-end" px={3} pt={2}>
          <MDButton
            color="primary"
            variant="outlined"
            onClick={handleRemoveLateFee}
          >
            {t("LOAN.REMOVE_LATE_FEE")}
          </MDButton>
        </MDBox>
      ) : null}
      <MDBox pt={2} pb={3} px={5} display="flex" flexDirection="column">
        <MDBox>
          <MDTypography variant="h4">
            {t("TABLE.NAME")}
            {`: ${!loanDetails.details ? null : loanDetails.details.userName}`}
          </MDTypography>
        </MDBox>
        <MDBox display="flex" alignItems="center">
          <MDTypography variant="h6">
            {" "}
            {t("TABLE.ARTIST_NAME")}
            {": "}
          </MDTypography>
          <MDTypography variant="body2" pl={2}>
            {!loanDetails.details ? null : loanDetails.details.artistName}
          </MDTypography>
        </MDBox>
        {renderDetails()}
      </MDBox>
      {failModalState ? (
        <FailModal title={t("ERRORS.ERROR")} message={t(error)} />
      ) : null}
      {successModalState ? (
        <SuccessModal title={t("AUTH.SUCCESS")} message={t(successMessage)} />
      ) : null}
    </>
  );
};

export default LoanDetails;
