import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getStaffTable } from "api/staff";
import { blockAndUnblockAdmin } from "api/staff";
import { resetTempPassword } from "api/staff";
import { editAdmin } from "api/staff";
import { createSuperAdmin } from "api/staff";
import { createAdmin } from "api/staff";


const initialState = {
  loading: false,
  staff: {},
  totalCount: 0,
  pageIndex: 0,
  errorMessage: "",
  createNewAdminState: false,
  staffDetails: {},
  adminDetailsState: false,
  updateAdminState: false,
  successModalState: false,
  failModalState: false,
  successMessage: "",
};

export const getStaffData = createAsyncThunk(
  "staff/getStaffData",
  // eslint-disable-next-line consistent-return
  async (data, thunkAPI) => {
    try {
      const res = await getStaffTable(data);
      return res;
    } catch (error) {
      if (error.response.status === 400) {
        return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
      }
      if (error.response.status === 500) {
        return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
      } else {
        return thunkAPI.rejectWithValue(error.response);
      }
    }
  }
);

export const editAdminDetails = createAsyncThunk(
  "staff/editAdminDetails",
  // eslint-disable-next-line consistent-return
  async (data, thunkAPI) => {
    try {
      const res = await editAdmin(data);
      return res;
    } catch (error) {
      if (error.response.data.message === "INCORRECT_PHONE_FORMAT") {
        return thunkAPI.rejectWithValue("ERRORS.VALID_PHONE");
      }
      if (error.response.status === 400) {
        return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
      }
      if (error.response.status === 500) {
        return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
      } else {
        return thunkAPI.rejectWithValue(error.response);
      }
    }
  }
);

export const createNewAdmin = createAsyncThunk(
  "staff/createNewAdmin",
  // eslint-disable-next-line consistent-return
  async (data, thunkAPI) => {
    try {
      const res = await createAdmin(data);
      return res;
    } catch (error) {
      if (error.response.data.message === "USER_ALREADY_EXISTS") {
        return thunkAPI.rejectWithValue("ERRORS.USER_ALREADY_EXISTS");
      }
      if (error.response.status === 400) {
        return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
      }
      if (error.response.status === 500) {
        return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
      } else {
        return thunkAPI.rejectWithValue(error.response);
      }
    }
  }
);

export const createNewSuperAdmin = createAsyncThunk(
  "staff/createNewSuperAdmin",
  // eslint-disable-next-line consistent-return
  async (data, thunkAPI) => {
    try {
      const res = await createSuperAdmin(data);
      return res;
    } catch (error) {
      if (error.response.status === 400) {
        return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
      }
      if (error.response.status === 500) {
        return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
      } else {
        return thunkAPI.rejectWithValue(error.response);
      }
    }
  }
);

export const changeAdminBlockUnblock = createAsyncThunk(
  "staff/changeAdminBlockUnblock",
  // eslint-disable-next-line consistent-return
  async (data, thunkAPI) => {
    try {
      const res = await blockAndUnblockAdmin(data);
      return res;
    } catch (error) {
      if (error.response.data.message === "USER_IS_SUPER_ADMIN") {
        return thunkAPI.rejectWithValue("ERRORS.USER_IS_SUPER_ADMIN");
      }
      if (error.response.status === 400) {
        return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
      }
      if (error.response.status === 500) {
        return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
      } else {
        return thunkAPI.rejectWithValue(error.response);
      }
    }
  }
);

export const adminResetTempPassword = createAsyncThunk(
  "staff/adminResetTempPassword",
  // eslint-disable-next-line consistent-return
  async (data, thunkAPI) => {
    try {
      const res = await resetTempPassword(data);
      return res;
    } catch (error) {
      if (
        error.response.data.message === "USER_NOT_IN_TEMPORARY_PASSWORD_STATUS"
      ) {
        return thunkAPI.rejectWithValue("ERROR.USER_NOT_TEMP_PASSWORD");
      }
      if (error.response.status === 400) {
        return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
      }
      if (error.response.status === 500) {
        return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
      } else {
        return thunkAPI.rejectWithValue(error.response);
      }
    }
  }
);

export const staffSlice = createSlice({
  name: "staff",
  initialState,
  reducers: {
    setPageIndex: (state, action) => {
      state.pageIndex = action.payload;
    },
    toggleCreateNewAdminState: (state) => {
      state.createNewAdminState = !state.createNewAdminState;
    },
    editAdminState: (state, action) => {
      state.staffDetails = state.staff.admins.filter(
        (staff) => staff.id === action.payload
      )[0];
    },
    toggleAdminDetailsState: (state) => {
      state.adminDetailsState = !state.adminDetailsState;
    },
    toggleUpdateAdminState: (state) => {
      state.updateAdminState = !state.updateAdminState;
    },
    toggleSuccessModalState: (state) => {
      state.successModalState = !state.successModalState;
    },
    toggleFailModalState: (state) => {
      state.failModalState = !state.failModalState;
    },
    resetErrorMessage: (state) => {
      state.errorMessage = "";
    },
    resetSuccessMessage: (state) => {
      state.successMessage = "";
    },
  },
  extraReducers: {
    [getStaffData.pending]: (state) => {
      state.errorMessage = "";
      state.loading = true;
    },
    [getStaffData.rejected]: (state, action) => {
      state.loading = false;
      state.errorMessage = action.payload;
    },
    [getStaffData.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.staff = payload;
      state.totalCount = payload.totalCount;
    },
    [createNewAdmin.pending]: (state) => {
      state.errorMessage = "";
      state.loading = true;
    },
    [createNewAdmin.rejected]: (state, action) => {
      state.loading = false;
      state.errorMessage = action.payload;
      state.failModalState = true;
    },
    [createNewAdmin.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.createNewAdminState = false;
      state.adminDetailsState = false;
      state.successModalState = true;
      state.successMessage = "STAFF.SUCCESS_CREATED_ADMIN";
    },
    [createNewSuperAdmin.pending]: (state) => {
      state.errorMessage = "";
      state.loading = true;
    },
    [createNewSuperAdmin.rejected]: (state, action) => {
      state.loading = false;
      state.errorMessage = action.payload;
      state.failModalState = true;
    },
    [createNewSuperAdmin.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.createNewAdminState = false;
      state.adminDetailsState = false;
      state.successModalState = true;
      state.successMessage = "STAFF.SUCCESS_CREATED_ADMIN";
    },
    [editAdminDetails.pending]: (state) => {
      state.errorMessage = "";
      state.loading = true;
    },
    [editAdminDetails.rejected]: (state, action) => {
      state.loading = false;
      state.errorMessage = action.payload;
      state.failModalState = true;
    },
    [editAdminDetails.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.updateAdminState = false;
      state.staffDetails = payload;
      state.adminDetailsState = true;
      state.successModalState = true;
      state.successMessage = "STAFF.SUCCESS_EDIT_ADMIN";
    },
    [changeAdminBlockUnblock.pending]: (state) => {
      state.errorMessage = "";
      state.loading = true;
    },
    [changeAdminBlockUnblock.rejected]: (state, action) => {
      state.loading = false;
      state.errorMessage = action.payload;
      state.failModalState = true;
    },
    [changeAdminBlockUnblock.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.updateAdminState = false;
      state.staffDetails = payload;
      state.adminDetailsState = true;
      state.successModalState = true;
      state.successMessage = "STAFF.SUCCESS_BLOCK_ADMIN";
    },
    [adminResetTempPassword.pending]: (state) => {
      state.errorMessage = "";
      state.loading = true;
    },
    [adminResetTempPassword.rejected]: (state, action) => {
      state.loading = false;
      state.errorMessage = action.payload;
      state.failModalState = true;
    },
    [adminResetTempPassword.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.updateAdminState = false;
      state.adminDetailsState = true;
      state.successModalState = true;
      state.successMessage = "STAFF.SUCCESS_SENT_PASSWORD";
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setPageIndex,
  toggleCreateNewAdminState,
  editAdminState,
  toggleAdminDetailsState,
  toggleUpdateAdminState,
  toggleFailModalState,
  toggleSuccessModalState,
  resetErrorMessage,
  resetSuccessMessage,
} = staffSlice.actions;

export default staffSlice.reducer;
