/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
// react-router-dom components
// import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
// import Switch from "@mui/material/Switch";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import ErrorModal from "components/ErrorModal/ErrorModal";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/ink_me/background_eye.png";

import { loginAdmin, updateEmail, toggleForgotPassword } from "store/authSlice";

import validate from "../../../../helpers/validation";
import "./signIn.css";
import MfaCodeForm from "./Components/mfaCodeForm";
import ForgotPasswordCodeForm from "./Components/forgotPasswordCodeForm";
import UpdatePasswordForm from "./Components/updatePasswordForm";
import ForgotPasswordForm from "./Components/forgotPasswordForm";

function Basic() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({});
  const mfaCodePageState = useSelector((state) => state.auth.mfaCodePageState);
  const updatePasswordPageState = useSelector(
    (state) => state.auth.updatePasswordPageState
  );
  const forgotPasswordState = useSelector(
    (state) => state.auth.forgotPasswordState
  );
  const forgotPasswordCodeState = useSelector(
    (state) => state.auth.forgotPasswordCodeState
  );
  const loginErrorState = useSelector((state) => state.auth.loginError);
  const errorMessage = useSelector((state) => state.auth.errorMessage);

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      const data = {
        email,
        password,
      };
      dispatch(loginAdmin(data));
      dispatch(updateEmail(email));
      sessionStorage.setItem("email", email);
      setIsSubmitting(false);
      setPassword("");
      setEmail("");
    }
  }, [errors, isSubmitting, email, password]);

  const handleChange = (e) => {
    setIsSubmitting(false);
    if (e.target.name === "email") {
      setEmail(e.target.value);
    }
    if (e.target.name === "password") {
      setPassword(e.target.value);
    }
  };

  const handleSubmit = (e) => {
    if (e) e.preventDefault();
    setIsSubmitting(true);
    setErrors(validate(email, password));
  };

  const renderForms = () => {
    if (mfaCodePageState) {
      return <MfaCodeForm />;
    }
    if (forgotPasswordCodeState) {
      return <ForgotPasswordCodeForm />;
    }
    if (updatePasswordPageState) {
      return <UpdatePasswordForm />;
    }
    if (forgotPasswordState) {
      return <ForgotPasswordForm />;
    }
    return (
      <>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            {t("AUTH.SIGN_IN")}
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <form onSubmit={handleSubmit}>
            <MDBox mb={2}>
              <MDInput
                type="email"
                name="email"
                label={t("AUTH.EMAIL")}
                value={email}
                onChange={(e) => handleChange(e)}
                error={!errors ? null : errors.email}
                fullWidth
              />
              {!errors.email ? null : (
                <p className="error_message">{t(errors.email)}</p>
              )}
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                name="password"
                label={t("AUTH.PASSWORD")}
                value={password}
                onChange={(e) => handleChange(e)}
                error={!errors ? null : errors.password}
                fullWidth
              />
              {!errors.password ? null : (
                <p className="error_message">{t(errors.password)}</p>
              )}
              {!errorMessage ? null : (
                <p className="error_message">{t(errorMessage)}</p>
              )}
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                onClick={handleSubmit}
                fullWidth
              >
                {t("AUTH.SIGN_IN")}
              </MDButton>
              <MDBox display="flex" justifyContent="flex-end" pt={2}>
                <MDButton
                  onClick={() => dispatch(toggleForgotPassword())}
                  variant="text"
                  color="dark"
                  size="large"
                >
                  {t("AUTH.FORGOT_PASSWORD")}
                </MDButton>
              </MDBox>
            </MDBox>
          </form>
        </MDBox>
      </>
    );
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>{renderForms()}</Card>
      {loginErrorState ? <ErrorModal /> : null}
    </BasicLayout>
  );
}

export default Basic;
