import axios from "./api";

export function getArtist(data) {
  return axios({
    url: `/UserAdmin/artist/getpage`,
    method: "post",
    data,
  });
}

export function getArtistDetails(id) {
  return axios({
    url: `/UserAdmin/artist/details/${id}`,
    method: "get",
  });
}

export function editArtistDetails(id, data) {
  return axios({
    url: `/UserAdmin/artist/edit/${id}`,
    method: "put",
    data,
  });
}

export function approveOrDeclineArtist(data) {
  return axios({
    url: `/UserAdmin/artist/approval`,
    method: "put",
    data,
  });
}

export function blockAndUnblockArtist(data) {
  return axios({
    url: `/UserAdmin/artist/blocking`,
    method: "put",
    data,
  });
}
