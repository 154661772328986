import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

import {
  forgotPasswordCodeSubmit,
  toggleForgotPasswordCodeState,
  forgotPasswordSubmit,
} from "store/authSlice";

import passwordValidate from "helpers/passwordValidation";
import { resetErrorMessage } from "store/authSlice";

const ForgotPasswordCodeForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [smsCode, setSMSCode] = useState("");
  const [isForgotPasswordCodeSubmitting, setIsForgotPasswordCodeSubmitting] =
    useState(false);
  const savedEmail = useSelector((state) => state.auth.email);
  const errorMessage = useSelector((state) => state.auth.errorMessage);
  const [resendCodeMessageState, setResendCodeMessageState] = useState(false);

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isForgotPasswordCodeSubmitting) {
      const data = {
        email: savedEmail,
        code: smsCode,
        password: password,
        passwordConfirmation: confirmPassword,
      };
      dispatch(forgotPasswordCodeSubmit(data));
      setIsForgotPasswordCodeSubmitting(false);
      setSMSCode("");
    }
  }, [
    errors,
    isForgotPasswordCodeSubmitting,
    smsCode,
    savedEmail,
    password,
    confirmPassword,
  ]);

  const handleChange = (e) => {
    if (errorMessage) {
      dispatch(resetErrorMessage());
    }
    setIsForgotPasswordCodeSubmitting(false);
    if (e.target.name === "password") {
      setPassword(e.target.value);
    }
    if (e.target.name === "smsCode") {
      setSMSCode(e.target.value.trim());
    }
    if (e.target.name === "confirmPassword") {
      setConfirmPassword(e.target.value);
    }
  };

  const handleForgotPasswordCodeSubmit = (e) => {
    if (e) e.preventDefault();
    setIsForgotPasswordCodeSubmitting(true);
    setErrors({});
    let errorsCode = {};
    errorsCode = passwordValidate(password, confirmPassword);
    if (!smsCode) {
      errorsCode.smsCode = "ERRORS.CODE_REQUIRED";
    }
    setErrors(errorsCode);
  };

  const handleResendCodeSubmit = (e) => {
    e.preventDefault();
    const data = {
      email: savedEmail,
    };
    dispatch(forgotPasswordSubmit(data));
    setResendCodeMessageState(true);
  };

  return (
    <>
      <MDBox
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
        mx={2}
        mt={-3}
        p={2}
        mb={1}
        textAlign="center"
      >
        <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
          {t("AUTH.FORGOT_PASSWORD_CODE")}
        </MDTypography>
      </MDBox>
      <MDBox pt={4} pb={3} px={3}>
        <MDBox mb={2}>
          <MDTypography
            variant="body2"
            fontWeight="regular"
            color="text"
            mb={3}
          >
            {t("AUTH.FORGOT_PASSWORD_CODE_MESSAGE")}
          </MDTypography>
          <form onSubmit={handleForgotPasswordCodeSubmit}>
            <MDBox mb={2}>
              <MDInput
                type="number"
                name="smsCode"
                label={t("AUTH.CODE")}
                value={smsCode}
                onChange={(e) => handleChange(e)}
                error={!errors ? null : errors.smsCode}
                fullWidth
              />
              {!errors.smsCode ? null : (
                <p className="error_message">{t(errors.smsCode)}</p>
              )}
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                name="password"
                label={t("AUTH.PASSWORD")}
                value={password}
                onChange={(e) => handleChange(e)}
                error={!errors ? null : errors.newPassword}
                fullWidth
              />
              {!errors.newPassword ? null : (
                <p className="error_message">{t(errors.newPassword)}</p>
              )}
              {!errors.email ? null : (
                <p className="error_message">{t(errors.email)}</p>
              )}
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                name="confirmPassword"
                label={t("AUTH.CONFIRM_PASSWORD")}
                value={confirmPassword}
                onChange={(e) => handleChange(e)}
                error={!errors ? null : errors.confirmPassword}
                fullWidth
              />
              {!errors.confirmPassword ? null : (
                <p className="error_message">{t(errors.confirmPassword)}</p>
              )}
              {!errorMessage ? null : (
                <p className="error_message">{t(errorMessage)}</p>
              )}
              {resendCodeMessageState ? (
                <MDBox pt={2}>
                  <p className="error_message">
                    {t("ERRORS.RESEND_CODE_MESSAGE")}
                  </p>
                </MDBox>
              ) : null}
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                onClick={handleForgotPasswordCodeSubmit}
                fullWidth
              >
                {t("AUTH.FORGOT_PASSWORD_CODE")}
              </MDButton>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                variant="outlined"
                color="primary"
                onClick={handleResendCodeSubmit}
                fullWidth
              >
                {t("AUTH.RESEND_CODE")}
              </MDButton>
            </MDBox>
            <MDBox display="flex" justifyContent="flex-end" pt={2}>
              <MDButton
                onClick={() => {
                  dispatch(toggleForgotPasswordCodeState());
                  dispatch(resetErrorMessage());
                }}
                variant="text"
                color="dark"
                size="large"
              >
                {t("AUTH.SIGN_IN")}
              </MDButton>
            </MDBox>
          </form>
        </MDBox>
      </MDBox>
    </>
  );
};

export default ForgotPasswordCodeForm;
