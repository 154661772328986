/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import ErrorModal from "components/ErrorModal/ErrorModal";
import LoanDetail from "./loanDetails";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "components/DataTable";

import { getDateWithTime } from "helpers/helpers";
// Data
import {
  getLoanData,
  setLoanDetailState,
  resetLoanState,
} from "../../../store/loanSlice";
import ViewMoreCell from "./ViewMoreCell";

function LoanManagement() {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const loanData = useSelector((state) => state.loan.loans);
  const totalCount = useSelector((state) => state.loan.totalCount);
  const [menu, setMenu] = useState(null);
  const [filterOption, setFilterOptions] = useState("");
  const loanDetailState = useSelector((state) => state.loan.loanDetailState);
  const [emailFilter, setEmailFilter] = useState(false);
  const [pageIndex, setPageIndex] = useState(1);
  const pageSize = 10;
  const loading = useSelector((state) => state.loan.loading);
  const [searchTerms, setSearchTerms] = useState("");
  const [loans, setLoans] = useState({});

  const getLoanStatus = (status) => {
    let text = "";
    switch (status) {
      case "In Applying Process":
        text = t("LOAN.IN_APPLYING_PROCESS");
        break;
      case "Pending Approval":
        text = t("LOAN.PENDING_APPROVAL");
        break;
      case "Active":
        text = t("LOAN.ACTIVE");
        break;
      case "Tattoo Complete":
        text = t("LOAN.TATTOO_COMPLETE");
        break;
      case "Complete And Paid":
        text = t("LOAN.COMPLETE_PAID");
        break;
      case "Cancelled":
        text = t("LOAN.CANCELLED");
        break;
      case "Denied":
        text = t("LOAN.DENIED");
        break;
      case "Admin Hold":
        text = t("LOAN.ADMIN_HOLD");
        break;
      default:
        text = t("LOAN.IN_COLLECTIONS");
    }
    return text;
  };
  const getPaymentStatus = (status) => {
    let text = "";
    switch (status) {
      case "Not Redeemed Yet":
        text = t("LOAN.NOT_REDEEMED_YET");
        break;
      case "In Progress":
        text = t("LOAN.IN_PROGRESS");
        break;
      case "Is Paid Off":
        text = t("LOAN.IS_PAID_OFF");
        break;
      case "Failed":
        text = t("LOAN.FAILED");
        break;
      case "Overdue":
        text = t("LOAN.OVERDUE");
        break;
      case "Admin Hold":
        text = t("LOAN.ADMIN_HOLD");
        break;
      default:
        text = t("LOAN.IN_COLLECTIONS");
    }
    return text;
  };

  useEffect(() => {
    if (loanData?.totalCount > 0) {
      setLoans({
        columns: [
          { Header: t("TABLE.NAME"), accessor: "userName" },
          {
            Header: t("TABLE.DATE"),
            accessor: "created",
          },
          { Header: t("TABLE.ARTIST_NAME"), accessor: "artistName" },
          {
            Header: t("TABLE.LOAN_STATUS"),
            accessor: "loanStatus",
          },
          {
            Header: t("TABLE.APPROVED_AMOUNT"),
            accessor: "approvedAmount",
            width: "10%",
          },
          {
            Header: t("TABLE.AMOUNT_PAID_OFF"),
            accessor: "paidOffAmount",
            width: "10%",
          },
          {
            Header: t("TABLE.REDEEMED_AMOUNT"),
            accessor: "redeemedAmount",
            width: "10%",
          },
          {
            Header: t("TABLE.PAYMENT_STATUS"),
            accessor: "paymentStatus",
          },
          {
            Header: t("TABLE.VIEW_MORE"),
            accessor: "viewMore",
          },
        ],
        rows: loanData.loans.map((loan) => {
          return {
            userName: loan.userName,
            created: getDateWithTime(loan.created),
            artistName: loan.artistName,
            loanStatus: getLoanStatus(loan.loanStatus),
            approvedAmount: `$ ${loan.approvedAmount.toFixed(2)}`,
            paidOffAmount: `$ ${loan.paidOffAmount.toFixed(2)}`,
            redeemedAmount: `$ ${loan.redeemedAmount.toFixed(2)}`,
            paymentStatus: getPaymentStatus(loan.paymentStatus),
            viewMore: <ViewMoreCell id={loan.id} />,
          };
        }),
      });
    } else {
      setLoans({
        columns: [
          { Header: t("TABLE.NAME"), accessor: "userName" },
          {
            Header: t("TABLE.DATE"),
            accessor: "created",
          },
          { Header: t("TABLE.ARTIST_NAME"), accessor: "artistName" },
          {
            Header: t("TABLE.LOAN_STATUS"),
            accessor: "loanStatus",
          },
          {
            Header: t("TABLE.APPROVED_AMOUNT"),
            accessor: "approvedAmount",
            width: "10%",
          },
          {
            Header: t("TABLE.AMOUNT_PAID_OFF"),
            accessor: "paidOffAmount",
            width: "10%",
          },
          {
            Header: t("TABLE.REDEEMED_AMOUNT"),
            accessor: "redeemedAmount",
            width: "10%",
          },
          {
            Header: t("TABLE.PAYMENT_STATUS"),
            accessor: "paymentStatus",
          },
          {
            Header: t("TABLE.VIEW_MORE"),
            accessor: "viewMore",
          },
        ],
        rows: [],
      });
    }
  }, [loanData]);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const email = queryParams.get("email");
    if (
      location.search === "" &&
      filterOption !== "InApplyingProcess" &&
      filterOption !== "PendingApproval" &&
      filterOption !== "TattooComplete" &&
      filterOption !== "CompletedAndPaid" &&
      filterOption !== "Cancelled" &&
      filterOption !== "Collections" &&
      filterOption !== "NotReedeemedYet" &&
      filterOption !== "InProgress" &&
      filterOption !== "IsPaidOff" &&
      filterOption !== "Failed" &&
      filterOption !== "Overdue" &&
      filterOption !== "AdminHold" &&
      filterOption !== "Denied" &&
      filterOption !== "Active"
    ) {
      setEmailFilter(false);
      setFilterOptions("");
    }
    if (email) {
      const data = {
        pageIndex,
        pageSize,
        forceSearchTerms: email,
        searchTerms,
      };
      dispatch(getLoanData(data));
      setFilterOptions(email);
      setEmailFilter(true);
    } else if (searchTerms.length > 0) {
      const timeoutId = setTimeout(() => {
        const data = {
          pageIndex,
          pageSize,
          forceSearchTerms: filterOption,
          searchTerms,
        };
        dispatch(getLoanData(data));
      }, 1000);
      return () => {
        clearTimeout(timeoutId);
      };
    } else {
      const data = {
        pageIndex,
        pageSize,
        forceSearchTerms: filterOption,
        searchTerms,
      };
      dispatch(getLoanData(data));
      dispatch(resetLoanState());
    }
  }, [filterOption, location.search, pageIndex, searchTerms]);

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = (state) => {
    if (
      state === "InApplyingProcess" ||
      state === "PendingApproval" ||
      state === "TattooComplete" ||
      state === "CompletedAndPaid" ||
      state === "Cancelled" ||
      state === "Collections" ||
      state === "NotRedeemedYet" ||
      state === "InProgress" ||
      state === "IsPaidOff" ||
      state === "Failed" ||
      state === "Overdue" ||
      state === "AdminHold" ||
      state === "Denied" ||
      state === "Active"
    ) {
      setFilterOptions(state);
    } else if (state === "None") {
      setFilterOptions("");
    }
    setMenu(null);
  };

  const renderMenu = (
    <Menu
      anchorEl={menu}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu)}
      onClose={closeMenu}
      keepMounted
    >
      <MenuItem onClick={() => closeMenu("InApplyingProcess")}>
        {t("LOAN.FILTER_IN_APPLYING_PROCESS")}
      </MenuItem>
      <MenuItem onClick={() => closeMenu("PendingApproval")}>
        {t("LOAN.FILTER_PENDING_APPROVAL")}
      </MenuItem>
      <MenuItem onClick={() => closeMenu("Active")}>
        {t("LOAN.FILTER_ACTIVE")}
      </MenuItem>
      <MenuItem onClick={() => closeMenu("TattooComplete")}>
        {t("LOAN.FILTER_TATTOO_COMPLETE")}
      </MenuItem>
      <MenuItem onClick={() => closeMenu("CompletedAndPaid")}>
        {t("LOAN.FILTER_COMPLETE_PAID")}
      </MenuItem>
      <MenuItem onClick={() => closeMenu("Cancelled")}>
        {t("LOAN.FILTER_CANCELLED")}
      </MenuItem>
      <MenuItem onClick={() => closeMenu("NotRedeemedYet")}>
        {t("LOAN.FILTER_NOT_REDEEMED")}
      </MenuItem>
      <MenuItem onClick={() => closeMenu("InProgress")}>
        {t("LOAN.FILTER_IN_PROGRESS")}
      </MenuItem>
      <MenuItem onClick={() => closeMenu("IsPaidOff")}>
        {t("LOAN.FILTER_IS_PAID_OFF")}
      </MenuItem>
      <MenuItem onClick={() => closeMenu("Failed")}>
        {t("LOAN.FILTER_FAILED")}
      </MenuItem>
      <MenuItem onClick={() => closeMenu("Overdue")}>
        {t("LOAN.FILTER_OVERDUE")}
      </MenuItem>
      <MenuItem onClick={() => closeMenu("AdminHold")}>
        {t("LOAN.FILTER_ADMIN_HOLD")}
      </MenuItem>
      <MenuItem onClick={() => closeMenu("Collections")}>
        {t("LOAN.FILTER_COLLECTIONS")}
      </MenuItem>
      <MenuItem onClick={() => closeMenu("Denied")}>
        {t("LOAN.FILTER_DENIED")}
      </MenuItem>
      <MenuItem onClick={() => closeMenu("None")}>
        {t("ARTISTS.FILTER_NONE")}
      </MenuItem>
    </Menu>
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ErrorModal />
      <MDBox pt={6} pb={3}>
        <Card>
          {loanDetailState ? (
            <>
              <MDBox
                p={3}
                pb={1}
                lineHeight={1}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <MDBox display="flex" alignItems="center">
                  <MDBox
                    mr={2}
                    onClick={() => dispatch(setLoanDetailState(false))}
                  >
                    <Icon>arrow_back</Icon>
                  </MDBox>
                  <MDTypography variant="h5" fontWeight="medium">
                    {t("LOAN.LOAN_TABLE")}
                  </MDTypography>
                </MDBox>
              </MDBox>
              <LoanDetail />
            </>
          ) : (
            <MDBox
              p={3}
              pb={1}
              lineHeight={1}
              display="flex"
              justifyContent="space-between"
            >
              <MDTypography variant="h5" fontWeight="medium">
                {t("TABLE.LOAN_MANAGEMENT")}
              </MDTypography>
              <MDBox display="flex">
                {emailFilter ? null : (
                  <MDButton
                    variant={menu ? "contained" : "outlined"}
                    color="dark"
                    onClick={openMenu}
                  >
                    {t("TABLE.FILTERS")}&nbsp;
                    <Icon>keyboard_arrow_down</Icon>
                  </MDButton>
                )}
                {renderMenu}
              </MDBox>
            </MDBox>
          )}
          {!loans ||
          Object.keys(loans).length === 0 ||
          loanDetailState ? null : (
            <DataTable
              table={loans}
              totalCount={totalCount}
              filterOption={filterOption}
              canSearch
              setPageIndex={setPageIndex}
              parentPageIndex={pageIndex}
              parentPageSize={pageSize}
              loading={loading}
              setSearchTerms={setSearchTerms}
              searchTerms={searchTerms}
            />
          )}
          {/* {failModalState ? (
            <FailModal title={t("ERRORS.ERROR")} message={t(errorMessage)} />
          ) : null}
          {successModalState ? (
            <SuccessModal
              title={t("AUTH.SUCCESS")}
              message={t(successMessage)}
            />
          ) : null} */}
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default LoanManagement;
