/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 PRO React layouts
import ProfileOverview from "layouts/pages/profile/profile-overview";
import Artists from "layouts/pages/user-management/artists";
import Customers from "layouts/pages/user-management/customers";
import Staff from "layouts/pages/user-management/staff";
import FloatManagement from "layouts/pages/float-management";
import Payout from "layouts/pages/payout";
import Dashboard from "layouts/pages/dashboard/dashboard";
import Logout from "layouts/authentication/logout";
import ResetPassword from "./layouts/authentication/resetPassword";
import Feedback from "layouts/pages/feedback";
import LoanApprovals from "layouts/pages/loanApprovals";
import Settings from "layouts/pages/settings";
import Content from "layouts/pages/content";
// @mui icons
import Icon from "@mui/material/Icon";
import LoanManagement from "layouts/pages/loan-management";

const routes = [
  {
    type: "collapse",
    name: "NAVBAR.PROFILE",
    userPermission: "Admin",
    key: "profile",
    icon: <Icon fontSize="medium">account_circle</Icon>,
    collapse: [
      {
        name: "NAVBAR.PROFILE",
        key: "profile-overview",
        userPermission: "Admin",
        route: "/profile/profile-overview",
        component: <ProfileOverview />,
      },
      {
        name: "NAVBAR.LOGOUT",
        key: "logout",
        userPermission: "Admin",
        route: "/logout",
        component: <Logout />,
      },
      {
        name: "NAVBAR.CHANGE_PASSWORD",
        key: "resetPassword",
        userPermission: "Admin",
        route: "/profile/resetPassword",
        component: <ResetPassword />,
      },
    ],
  },
  { type: "divider", key: "divider-0", userPermission: "Admin" },
  {
    type: "collapse",
    userPermission: "Admin",
    name: "NAVBAR.DASHBOARD",
    key: "dashboard",
    route: "/dashboard",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    component: <Dashboard />,
    noCollapse: true,
  },
  { type: "divider", key: "divider-1", userPermission: "Admin" },
  {
    type: "collapse",
    userPermission: "Admin",
    name: "NAVBAR.USER_MANAGEMENT",
    key: "usermanagement",
    icon: <Icon fontSize="medium">manage_accounts</Icon>,
    collapse: [
      {
        userPermission: "Admin",
        name: "NAVBAR.ARTISTS",
        key: "artists",
        route: "/usermanagement/artists",
        component: <Artists />,
      },
      {
        userPermission: "Admin",
        name: "NAVBAR.CUSTOMERS",
        key: "customers",
        route: "/usermanagement/customers",
        component: <Customers />,
      },
      {
        userPermission: "SuperAdmin",
        name: "NAVBAR.STAFF",
        key: "staff",
        route: "/usermanagement/staff",
        component: <Staff />,
      },
    ],
  },
  {
    type: "collapse",
    userPermission: "SuperAdmin",
    name: "NAVBAR.FLOAT_MANAGEMENT",
    key: "float-management",
    route: "/float-management",
    component: <FloatManagement />,
    noCollapse: true,
    icon: <Icon fontSize="medium">account_balance</Icon>,
  },
  {
    type: "collapse",
    userPermission: "SuperAdmin",
    name: "NAVBAR.PAYOUT",
    key: "payout",
    route: "/payout",
    component: <Payout />,
    noCollapse: true,
    icon: <Icon fontSize="medium">credit_card</Icon>,
  },
  {
    type: "collapse",
    userPermission: "Admin",
    icon: <Icon fontSize="medium">payments</Icon>,
    name: "NAVBAR.LOAN_MANAGEMENT",
    key: "application-management",
    route: "/application-management",
    collapse: [
      {
        userPermission: "Admin",
        name: "NAVBAR.LOANS",
        key: "applications",
        route: "/application-management/applications",
        component: <LoanManagement />,
      },
      {
        userPermission: "Admin",
        name: "NAVBAR.LOAN_APPROVAL",
        key: "application-Approval",
        route: "/application-management/application-Approval",
        component: <LoanApprovals />,
      },
    ],
  },
  {
    type: "collapse",
    userPermission: "Admin",
    icon: <Icon fontSize="medium">contact_mail</Icon>,
    name: "NAVBAR.CONTACT",
    key: "contact",
    component: <Feedback />,
    route: "/contact",
    noCollapse: true,
  },
  {
    type: "collapse",
    userPermission: "SuperAdmin",
    icon: <Icon fontSize="medium">admin_panel_settings</Icon>,
    name: "NAVBAR.SETTINGS",
    key: "settings",
    route: "/settings",
    collapse: [
      {
        userPermission: "SuperAdmin",
        name: "NAVBAR.SETTINGS",
        key: "settings",
        route: "/settings/settings",
        component: <Settings />,
      },
      {
        userPermission: "SuperAdmin",
        name: "NAVBAR.CONTENT",
        key: "content",
        route: "/settings/content",
        component: <Content />,
      },
    ],
  },
];

export default routes;
