/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import jwtDecode from "jwt-decode";
import {
  login,
  mfaResponse,
  changeTempPassword,
  forgotPassword,
  resetPassword,
  changePasswordRequestCode,
  changePassword,
  getMyProfile,
} from "../api/auth";

const initialState = {
  auth: null,
  accessToken: "",
  role: "",
  refreshToken: null,
  loading: false,
  errorMessage: "",
  generalError: false,
  signInInfo: null,
  mfaCodePageState: false,
  updatePasswordPageState: false,
  forgotPasswordState: false,
  forgotPasswordCodeState: false,
  resetPasswordCodePageState: false,
  successModalState: false,
  failModalState: false,
  loginError: false,
  email: null,
  profileDetails: {},
};

export const loginAdmin = createAsyncThunk(
  "User/loginAdmin",
  // eslint-disable-next-line consistent-return
  async (data, thunkAPI) => {
    try {
      const res = await login(data);
      return res;
    } catch (error) {
      if (error.response) {
        // Request made and server responded
        if (
          error.response.data.message === "CONTACT_ADMIN_FOR_PASSWORD_RESET"
        ) {
          return thunkAPI.rejectWithValue(
            "ERRORS.CONTACT_ADMIN_FOR_PASSWORD_RESET"
          );
        }
        if (
          error.response.data.message === "NOT_AUTHORIZED_EXCEPTION" ||
          error.response.data.message === "The requested resource was not found"
        ) {
          return thunkAPI.rejectWithValue("ERRORS.NOT_AUTHORIZED");
        }
        if (error.response.data.message === "ADMIN_BLOCKED") {
          return thunkAPI.rejectWithValue("ERRORS.ADMIN_BLOCKED");
        }
        if (error.response.status === 400) {
          return thunkAPI.rejectWithValue("ERRORS.NOT_AUTHORIZED");
        }
        if (error.response.status === 500) {
          return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
        }
        return thunkAPI.rejectWithValue(error.response);
      }
    }
  }
);

export const mfaSubmit = createAsyncThunk(
  "User/mfaSubmit",
  // eslint-disable-next-line consistent-return
  async (data, thunkAPI) => {
    try {
      const res = await mfaResponse(data);
      return res;
    } catch (error) {
      if (error.response) {
        // Request made and server responded
        if (error.response.data.message === "CODE_MISMATCH_EXCEPTION") {
          return thunkAPI.rejectWithValue("ERRORS.CODE_MISMATCH_EXCEPTION");
        }
        if (error.response.data.message === "EXPIRED_CODE_EXCEPTION") {
          return thunkAPI.rejectWithValue("ERRORS.EXPIRED_CODE_EXCEPTION");
        }
        if (error.response.data.message === "NOT_AUTHORIZED_EXCEPTION") {
          return thunkAPI.rejectWithValue("ERRORS.NOT_AUTHORIZED_EXCEPTION");
        }
        if (error.response.status === 400) {
          return thunkAPI.rejectWithValue("ERRORS.INCORRECT_LOGIN");
        }
        if (error.response.status === 500) {
          return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
        }
        return thunkAPI.rejectWithValue(error.response);
      }
    }
  }
);

export const changePasswordSubmit = createAsyncThunk(
  "User/changePasswordSubmit",
  // eslint-disable-next-line consistent-return
  async (data, thunkAPI) => {
    try {
      const res = await changeTempPassword(data);
      return res;
    } catch (error) {
      if (error.response) {
        // Request made and server responded
        if (error.response.data.message === "NOT_AUTHORIZED_EXCEPTION") {
          return thunkAPI.rejectWithValue("ERRORS.NOT_AUTHORIZED");
        }
        if (error.response.data.message === "EXPIRED_CODE_EXCEPTION") {
          return thunkAPI.rejectWithValue("ERRORS.EXPIRED_CODE_EXCEPTION");
        }
        if (error.response.status === 400) {
          return thunkAPI.rejectWithValue("ERRORS.INCORRECT_LOGIN");
        }
        if (error.response.status === 500) {
          return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
        }
        return thunkAPI.rejectWithValue(error.response);
      }
    }
  }
);

export const forgotPasswordSubmit = createAsyncThunk(
  "User/forgotPasswordSubmit",
  // eslint-disable-next-line consistent-return
  async (data, thunkAPI) => {
    try {
      const res = await forgotPassword(data);
      return res;
    } catch (error) {
      if (error.response) {
        // Request made and server responded
        if (error.response.data.message === "NOT_AUTHORIZED_EXCEPTION") {
          return thunkAPI.rejectWithValue("ERRORS.NOT_AUTHORIZED");
        }
        if (error.response.data.message === "INVALID_USER") {
          return thunkAPI.rejectWithValue("ERRORS.INVALID_USER");
        }
        if (error.response.data.message === "EXPIRED_CODE_EXCEPTION") {
          return thunkAPI.rejectWithValue("ERRORS.EXPIRED_CODE_EXCEPTION");
        }
        if (error.response.status === 400) {
          return thunkAPI.rejectWithValue("ERRORS.INCORRECT_LOGIN");
        }
        if (error.response.status === 500) {
          return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
        }
        return thunkAPI.rejectWithValue(error.response);
      }
    }
  }
);

export const forgotPasswordCodeSubmit = createAsyncThunk(
  "User/forgotPasswordCodeSubmit",
  // eslint-disable-next-line consistent-return
  async (data, thunkAPI) => {
    try {
      const res = await resetPassword(data);
      return res;
    } catch (error) {
      if (error.response) {
        // Request made and server responded
        if (
          error.response.data.message === "NOT_AUTHORIZED_EXCEPTION" ||
          error.response.data.message === "INVALID_USER" ||
          error.response.data.message === "INVALID_LOGIN" ||
          error.response.data.message === "USER_STATUS_UNCONFIRMED" ||
          error.response.data.message === "INVALID_PASSWORD" ||
          error.response.data.message === "The requested resource was not found"
        ) {
          return thunkAPI.rejectWithValue("ERRORS.NOT_AUTHORIZED");
        }
        if (error.response.data.message === "EXPIRED_CODE_EXCEPTION") {
          return thunkAPI.rejectWithValue("ERRORS.EXPIRED_CODE_EXCEPTION");
        }
        if (error.response.status === 400) {
          return thunkAPI.rejectWithValue("ERRORS.INCORRECT_LOGIN");
        }
        if (error.response.status === 500) {
          return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
        }
        return thunkAPI.rejectWithValue(error.response);
      }
    }
  }
);

export const changePasswordRequestCodeSubmit = createAsyncThunk(
  "User/changePasswordRequestCodeSubmit",
  // eslint-disable-next-line consistent-return
  async (data, thunkAPI) => {
    try {
      const res = await changePasswordRequestCode(data);
      return res;
    } catch (error) {
      if (error.response) {
        // Request made and server responded
        if (error.response.data.message === "NOT_AUTHORIZED_EXCEPTION") {
          return thunkAPI.rejectWithValue("ERRORS.NOT_AUTHORIZED");
        }
        if (error.response.data.message === "EXPIRED_CODE_EXCEPTION") {
          return thunkAPI.rejectWithValue("ERRORS.EXPIRED_CODE_EXCEPTION");
        }
        if (error.response.status === 400) {
          return thunkAPI.rejectWithValue("ERRORS.INCORRECT_LOGIN");
        }
        if (error.response.status === 500) {
          return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
        }
        return thunkAPI.rejectWithValue(error.response);
      }
    }
  }
);

export const changePasswordCodeSubmit = createAsyncThunk(
  "User/changePasswordCodeSubmit",
  // eslint-disable-next-line consistent-return
  async (data, thunkAPI) => {
    try {
      const res = await changePassword(data);
      return res;
    } catch (error) {
      if (error.response) {
        // Request made and server responded
        if (error.response.data.message === "NOT_AUTHORIZED_EXCEPTION") {
          return thunkAPI.rejectWithValue("ERRORS.NOT_AUTHORIZED");
        }
        if (error.response.data.message === "EXPIRED_CODE_EXCEPTION") {
          return thunkAPI.rejectWithValue("ERRORS.EXPIRED_CODE_EXCEPTION");
        }
        if (error.response.data.message === "CODE_MISMATCH_EXCEPTION") {
          return thunkAPI.rejectWithValue("ERRORS.CODE_MISMATCH_EXCEPTION");
        }
        if (error.response.status === 400) {
          return thunkAPI.rejectWithValue("ERRORS.INCORRECT_LOGIN");
        }
        if (error.response.status === 500) {
          return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
        }
        return thunkAPI.rejectWithValue(error.response);
      }
    }
  }
);

export const getProfile = createAsyncThunk(
  "auth/getProfile",
  // eslint-disable-next-line consistent-return
  async (thunkAPI) => {
    try {
      const res = await getMyProfile();
      return res;
    } catch (error) {
      if (error.response) {
        // Request made and server responded
        if (error.response.data.message === "NOT_AUTHORIZED_EXCEPTION") {
          return thunkAPI.rejectWithValue("ERRORS.NOT_AUTHORIZED");
        }
        if (error.response.status === 400) {
          return thunkAPI.rejectWithValue("ERRORS.INCORRECT_LOGIN");
        }
        if (error.response.status === 500) {
          return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
        }
        return thunkAPI.rejectWithValue(error.response);
      }
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    reset: (state) => {
      // eslint-disable-next-line no-unused-vars
      state = initialState;
    },
    updateEmail: (state, action) => {
      state.email = action.payload;
    },
    resetLoginError: (state) => {
      state.loginError = false;
    },
    toggleGeneralError: (state) => {
      state.generalError = !state.generalError;
    },
    resetChangePasswordState: (state) => {
      state.updatePasswordPageState = false;
    },
    toggleForgotPassword: (state) => {
      state.forgotPasswordState = !state.forgotPasswordState;
    },
    toggleForgotPasswordCodeState: (state) => {
      state.forgotPasswordCodeState = !state.forgotPasswordCodeState;
    },
    toggleResetPasswordCodePageState: (state) => {
      state.resetPasswordCodePageState = !state.resetPasswordCodePageState;
    },
    toggleFailModalState: (state) => {
      state.failModalState = !state.failModalState;
    },
    toggleSuccessModalState: (state) => {
      state.successModalState = !state.successModalState;
    },
    resetErrorMessage: (state) => {
      state.errorMessage = "";
    },
    setMfaCodeState: (state, action) => {
      state.mfaCodePageState = action.payload;
    },
  },
  extraReducers: {
    [loginAdmin.pending]: (state) => {
      state.generalError = false;
      state.errorMessage = "";
      state.loading = true;
    },
    [loginAdmin.rejected]: (state, action) => {
      state.loading = false;
      if (action.payload === "ERRORS.GENERAL_ERROR") {
        state.generalError = true;
      }
      state.errorMessage = action.payload;
    },
    [loginAdmin.fulfilled]: (state, { payload }) => {
      // eslint-disable-next-line no-param-reassign
      state.loading = false;
      // eslint-disable-next-line no-param-reassign
      state.signInInfo = payload;
      if (payload.responseRequired === "SMS_MFA") {
        // eslint-disable-next-line no-param-reassign
        state.mfaCodePageState = true;
      }
      if (payload.responseRequired === "NEW_PASSWORD_REQUIRED") {
        // eslint-disable-next-line no-param-reassign
        state.updatePasswordPageState = true;
      }
    },
    [mfaSubmit.pending]: (state) => {
      state.generalError = false;
      state.errorMessage = "";
      state.loading = true;
    },
    [mfaSubmit.rejected]: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.loading = false;
      if (action.payload === "ERRORS.GENERAL_ERROR") {
        // eslint-disable-next-line no-param-reassign
        state.generalError = true;
      }
      if (
        action.payload === "ERRORS.EXPIRED_CODE_EXCEPTION" ||
        action.payload === "ERRORS.NOT_AUTHORIZED_EXCEPTION"
      ) {
        // eslint-disable-next-line no-param-reassign
        state.signInInfo = null;
        // eslint-disable-next-line no-param-reassign
        state.mfaCodePageState = false;
      }
      // eslint-disable-next-line no-param-reassign
      state.errorMessage = action.payload;
    },
    [mfaSubmit.fulfilled]: (state, { payload }) => {
      // eslint-disable-next-line no-param-reassign
      state.loading = false;
      state.generalError = false;
      state.errorMessage = "";
      state.accessToken = payload.accessToken;
      state.refreshToken = payload.refreshToken;
      const decoded = jwtDecode(payload.accessToken);
      sessionStorage.setItem("access_token", payload.accessToken);
      sessionStorage.setItem("refresh_token", payload.refreshToken);
      sessionStorage.setItem("isRetry", false);
      // eslint-disable-next-line prefer-destructuring
      state.role = decoded["cognito:groups"][0];
    },
    [changePasswordSubmit.pending]: (state) => {
      state.generalError = false;
      state.errorMessage = "";
      state.loading = true;
    },
    [changePasswordSubmit.rejected]: (state, action) => {
      state.loading = false;
      if (action.payload === "ERRORS.GENERAL_ERROR") {
        state.generalError = true;
      }
      if (
        action.payload === "ERRORS.NOT_AUTHORIZED" ||
        action.payload === "ERRORS.EXPIRED_CODE_EXCEPTION"
      ) {
        state.updatePasswordPageState = false;
        state.loginError = true;
      }
      state.errorMessage = action.payload;
    },
    [changePasswordSubmit.fulfilled]: (state, { payload }) => {
      // eslint-disable-next-line no-param-reassign
      state.loading = false;
      // eslint-disable-next-line no-param-reassign
      state.updatePasswordPageState = false;
      state.mfaCodePageState = true;
      state.signInInfo.session = payload.session;
      state.signInInfo.userId = payload.userId;
    },
    [forgotPasswordSubmit.pending]: (state) => {
      state.generalError = false;
      state.errorMessage = "";
      state.loading = true;
    },
    [forgotPasswordSubmit.rejected]: (state, action) => {
      state.loading = false;
      if (action.payload === "ERRORS.GENERAL_ERROR") {
        state.generalError = true;
      }
      state.errorMessage = action.payload;
    },
    [forgotPasswordSubmit.fulfilled]: (state, { payload }) => {
      // eslint-disable-next-line no-param-reassign
      state.loading = false;
      // eslint-disable-next-line no-param-reassign
      state.forgotPasswordState = false;
      state.forgotPasswordCodeState = true;
    },
    [forgotPasswordCodeSubmit.pending]: (state) => {
      state.generalError = false;
      state.errorMessage = "";
      state.loading = true;
    },
    [forgotPasswordCodeSubmit.rejected]: (state, action) => {
      state.loading = false;
      if (action.payload === "ERRORS.GENERAL_ERROR") {
        state.generalError = true;
      }
      state.errorMessage = action.payload;
    },
    [forgotPasswordCodeSubmit.fulfilled]: (state, { payload }) => {
      // eslint-disable-next-line no-param-reassign
      state.loading = false;
      // eslint-disable-next-line no-param-reassign
      state.forgotPasswordCodeState = false;
      state.signInInfo = payload;
      if (payload.responseRequired === "SMS_MFA") {
        // eslint-disable-next-line no-param-reassign
        state.mfaCodePageState = true;
      }
    },
    [changePasswordRequestCodeSubmit.pending]: (state) => {
      state.generalError = false;
      state.errorMessage = "";
      state.loading = true;
    },
    [changePasswordRequestCodeSubmit.rejected]: (state, action) => {
      state.loading = false;
      if (action.payload === "ERRORS.GENERAL_ERROR") {
        state.generalError = true;
      }
      state.errorMessage = action.payload;
    },
    [changePasswordRequestCodeSubmit.fulfilled]: (state, { payload }) => {
      // eslint-disable-next-line no-param-reassign
      state.loading = false;
      // eslint-disable-next-line no-param-reassign
      state.signInInfo = payload;
      state.resetPasswordCodePageState = true;
    },
    [changePasswordCodeSubmit.pending]: (state) => {
      state.generalError = false;
      state.errorMessage = "";
      state.loading = true;
    },
    [changePasswordCodeSubmit.rejected]: (state, action) => {
      state.loading = false;
      if (action.payload === "ERRORS.GENERAL_ERROR") {
        state.generalError = true;
      }
      if (action.payload === "ERRORS.CODE_MISMATCH_EXCEPTION") {
      } else {
        state.resetPasswordCodePageState = false;
        state.failModalState = true;
      }
      state.errorMessage = action.payload;
    },
    [changePasswordCodeSubmit.fulfilled]: (state, { payload }) => {
      // eslint-disable-next-line no-param-reassign
      state.loading = false;
      // eslint-disable-next-line no-param-reassign
      state.signInInfo = payload;
      state.resetPasswordCodePageState = false;
      state.successModalState = true;
    },
    [getProfile.pending]: (state) => {
      state.generalError = false;
      state.errorMessage = "";
      state.loading = true;
    },
    [getProfile.rejected]: (state, action) => {
      state.loading = false;
      if (action.payload === "ERRORS.GENERAL_ERROR") {
        state.generalError = true;
      }
      state.errorMessage = action.payload;
    },
    [getProfile.fulfilled]: (state, { payload }) => {
      // eslint-disable-next-line no-param-reassign
      state.loading = false;
      // eslint-disable-next-line no-param-reassign
      state.profileDetails = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  reset,
  updateEmail,
  resetLoginError,
  toggleGeneralError,
  resetChangePasswordState,
  toggleForgotPassword,
  toggleResetPasswordCodePageState,
  toggleFailModalState,
  toggleSuccessModalState,
  resetErrorMessage,
  toggleForgotPasswordCodeState,
  setMfaCodeState,
} = authSlice.actions;

export default authSlice.reducer;
