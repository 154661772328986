import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
// @mui material components
import Card from "@mui/material/Card";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "components/DataTable";
import ViewMoreCell from "./ViewMoreCell";
import ContentDetails from "./ContentDetails";
import TOS from "./TOS";
import PrivacyPolicy from "./PrivacyPolicy";
import RatesTerms from "./RatesTerms";
import AboutUs from "./AboutUs";
import Contract from "./Contract";

import { getDateWithTime } from "helpers/helpers";
import { getContentData } from "store/settingSlice";
import PDFViewer from "./PDFViewer";

function Content() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const contentData = useSelector((state) => state.setting.content);
  const totalCount = useSelector((state) => state.setting.content.totalCount);
  const contentDetailState = useSelector(
    (state) => state.setting.contentDetailState
  );
  const [filterOption, setFilterOptions] = useState("");
  const [pageIndex, setPageIndex] = useState(1);
  const pageSize = 10;
  const loading = useSelector((state) => state.setting.loading);
  const [searchTerms, setSearchTerms] = useState("");
  const [content, setContent] = useState({});
  const [aboutState, setAboutState] = useState(false);
  const [tosState, setTosState] = useState(false);
  const [privacyPolicyState, setPrivacyPolicyState] = useState(false);
  const [ratesTermsState, setRatesTermsState] = useState(false);
  const [contractState, setContractState] = useState(false);
  const [previewContractState, setPreviewContractState] = useState(false);

  const getContentType = (name) => {
    if (name === "Privacy_Policy") {
      return t("CONTENT.PRIVACY_POLICY");
    } else if (name === "Terms_of_Service") {
      return t("CONTENT.TERMS_OF_SERVICE");
    } else if (name === "About_Us") {
      return t("CONTENT.ABOUT_US");
    } else if (name === "Rates_Terms") {
      return t("CONTENT.RATES_TERMS");
    } else {
      return name;
    }
  };
  useEffect(() => {
    if (contentData?.totalCount > 0) {
      setContent({
        columns: [
          { Header: t("TABLE.NAME"), accessor: "name" },
          { Header: t("TABLE.VERSION"), accessor: "version" },
          {
            Header: t("TABLE.CREATED"),
            accessor: "created",
          },
          {
            Header: t("TABLE.LANGUAGE"),
            accessor: "language",
          },

          {
            Header: t("TABLE.VIEW_MORE"),
            accessor: "viewMore",
          },
        ],
        rows: contentData.contents.map((content) => {
          return {
            name: getContentType(content.name),
            version: content.version,
            created: getDateWithTime(content.created),
            language: content.language,
            viewMore: (
              <ViewMoreCell name={content.name} version={content.version} />
            ),
          };
        }),
      });
    } else {
      setContent({
        columns: [
          { Header: t("TABLE.NAME"), accessor: "name" },
          { Header: t("TABLE.VERSION"), accessor: "version" },
          {
            Header: t("TABLE.CREATED"),
            accessor: "created",
          },
          {
            Header: t("TABLE.LANGUAGE"),
            accessor: "language",
          },

          {
            Header: t("TABLE.VIEW_MORE"),
            accessor: "viewMore",
          },
        ],
        rows: [],
      });
    }
  }, [contentData]);

  useEffect(() => {
    const data = {
      pageIndex,
      pageSize,
      forceSearchTerms: filterOption,
      searchTerms,
    };
    dispatch(getContentData(data));
  }, [filterOption, pageIndex, pageSize, searchTerms]);

  const handleAbout = () => {
    setAboutState(true);
  };

  const handleTOS = () => {
    setTosState(true);
  };

  const handlePrivacyPolicy = () => {
    setPrivacyPolicyState(true);
  };
  const handleRatesTerms = () => {
    setRatesTermsState(true);
  };

  const handleContract = () => {
    setContractState(true);
  };

  const viewPreviewContract = () => {
    setPreviewContractState(true);
  };

  const renderContent = () => {
    if (contentDetailState) {
      return <ContentDetails />;
    } else {
      return (
        <>
          <MDBox px={3} py={2} lineHeight={1}>
            <MDTypography variant="h5" fontWeight="medium">
              {t("TABLE.CONTENT")}
            </MDTypography>
          </MDBox>
          {!content || Object.keys(content).length === 0 ? null : (
            <DataTable
              table={content}
              totalCount={totalCount}
              filterOption={filterOption}
              canSearch
              setPageIndex={setPageIndex}
              parentPageIndex={pageIndex}
              parentPageSize={pageSize}
              loading={loading}
              setSearchTerms={setSearchTerms}
              searchTerms={searchTerms}
            />
          )}
        </>
      );
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {!aboutState &&
      !tosState &&
      !privacyPolicyState &&
      !ratesTermsState &&
      !contractState ? (
        <>
          <MDBox display="flex" justifyContent="flex-start">
            <MDBox pl={2}>
              <MDButton onClick={viewPreviewContract} color="primary">
                {t("CONTENT.VIEW_CONTRACT")}
              </MDButton>
            </MDBox>
            <MDBox pl={2}>
              <MDButton onClick={handleContract} color="primary">
                {t("CONTENT.UPDATE_CONTRACT")}
              </MDButton>
            </MDBox>
            <MDBox ml="auto" display="flex">
              <MDBox pl={2}>
                <MDButton onClick={handleAbout} color="primary">
                  {t("CONTENT.UPDATE_ABOUT")}
                </MDButton>
              </MDBox>
              <MDBox pl={2}>
                <MDButton onClick={handleTOS} color="primary">
                  {t("CONTENT.UPDATE_TOS")}
                </MDButton>
              </MDBox>
              <MDBox pl={2}>
                <MDButton onClick={handlePrivacyPolicy} color="primary">
                  {t("CONTENT.UPDATE_PP")}
                </MDButton>
              </MDBox>
              <MDBox pl={2}>
                <MDButton onClick={handleRatesTerms} color="primary">
                  {t("CONTENT.UPDATE_RATES_TERMS")}
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
          <MDBox pt={3} pb={3}>
            <Card>{renderContent()}</Card>
          </MDBox>
        </>
      ) : null}
      {aboutState ? (
        <MDBox pt={3} pb={3}>
          <AboutUs onClose={() => setAboutState(false)} />
        </MDBox>
      ) : null}
      {tosState ? (
        <MDBox pt={3} pb={3}>
          <TOS onClose={() => setTosState(false)} />
        </MDBox>
      ) : null}
      {privacyPolicyState ? (
        <MDBox pt={3} pb={3}>
          <PrivacyPolicy onClose={() => setPrivacyPolicyState(false)} />
        </MDBox>
      ) : null}{" "}
      {ratesTermsState ? (
        <MDBox pt={3} pb={3}>
          <RatesTerms onClose={() => setRatesTermsState(false)} />
        </MDBox>
      ) : null}
      {contractState ? (
        <MDBox pt={3} pb={3}>
          <Contract onClose={() => setContractState(false)} />
        </MDBox>
      ) : null}
      {previewContractState ? (
        <PDFViewer togglePDFViewer={setPreviewContractState} />
      ) : null}
    </DashboardLayout>
  );
}

export default Content;
