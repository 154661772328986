export const getDateWithTime = (isoDate) => {
  const date = new Date(isoDate);

  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let dt = date.getDate();
  let hour = date.getHours();
  let minute = date.getMinutes();

  if (month < 10) {
    month = "0" + month;
  }

  let amPm = "AM";
  if (dt < 10) {
    dt = "0" + dt;
  }
  if (hour >= 12) {
    amPm = "PM";
  }
  hour = hour % 12;
  hour = hour ? hour : 12;

  if (minute.toString().length === 1) {
    minute = `0${minute}`;
  }

  return `${dt}-${month}-${year} ${hour}:${minute}${amPm}`;
};

export const getDate = (isoDate) => {
  const date = new Date(isoDate);

  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let dt = date.getDate();

  if (month < 10) {
    month = "0" + month;
  }

  if (dt < 10) {
    dt = "0" + dt;
  }

  return `${dt}-${month}-${year}`;
};

export const htmlDecode = (input) => {
  var e = document.createElement("div");
  e.innerHTML = input;
  return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
};
