import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { markAsAnswered } from "api/feedback";
import { replyToFeedback } from "api/feedback";
import { getFeedbackTable } from "api/feedback";

const initialState = {
  loading: false,
  feedback: {},
  totalCount: 0,
  pageIndex: 0,
  errorMessage: "",
  feedbackDetails: {},
  feedbackDetailsState: false,
  successModalState: false,
  failModalState: false,
  successMessage: "",
};

export const getFeedbackData = createAsyncThunk(
  "feedback/getFeedbackData",
  // eslint-disable-next-line consistent-return
  async (data, thunkAPI) => {
    try {
      const res = await getFeedbackTable(data);
      return res;
    } catch (error) {
      if (error.response.status === 400) {
        return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
      }
      if (error.response.status === 500) {
        return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
      } else {
        return thunkAPI.rejectWithValue(error.response);
      }
    }
  }
);

export const replyToUserFeedback = createAsyncThunk(
  "feedback/replyToUserFeedback",
  // eslint-disable-next-line consistent-return
  async (data, thunkAPI) => {
    try {
      const res = await replyToFeedback(data);
      return res;
    } catch (error) {
      if (error.response.status === 400) {
        return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
      }
      if (error.response.status === 500) {
        return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
      } else {
        return thunkAPI.rejectWithValue(error.response);
      }
    }
  }
);

export const markFeedbackAsAnswered = createAsyncThunk(
  "feedback/markFeedbackAsAnswered",
  // eslint-disable-next-line consistent-return
  async (id, thunkAPI) => {
    try {
      const res = await markAsAnswered(id);
      return res;
    } catch (error) {
      if (error.response.status === 400) {
        return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
      }
      if (error.response.status === 500) {
        return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
      } else {
        return thunkAPI.rejectWithValue(error.response);
      }
    }
  }
);

export const feedbackSlice = createSlice({
  name: "feedback",
  initialState,
  reducers: {
    setPageIndex: (state, action) => {
      state.pageIndex = action.payload;
    },
    toggleFeedbackDetailsState: (state) => {
      state.feedbackDetailsState = !state.feedbackDetailsState;
    },
    toggleSuccessModalState: (state) => {
      state.successModalState = !state.successModalState;
    },
    toggleFailModalState: (state) => {
      state.failModalState = !state.failModalState;
    },
    resetErrorMessage: (state) => {
      state.errorMessage = "";
    },
    resetSuccessMessage: (state) => {
      state.successMessage = "";
    },
    sendFeedbackState: (state, action) => {
      state.feedbackDetails = state.feedback.feedbacks.filter(
        (form) => form.id === action.payload
      )[0];
    },
  },
  extraReducers: {
    [getFeedbackData.pending]: (state) => {
      state.errorMessage = "";
      state.loading = true;
    },
    [getFeedbackData.rejected]: (state, action) => {
      state.loading = false;
      state.errorMessage = action.payload;
    },
    [getFeedbackData.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.feedback = payload;
      state.totalCount = payload.totalCount;
    },
    [replyToUserFeedback.pending]: (state) => {
      state.errorMessage = "";
      state.loading = true;
    },
    [replyToUserFeedback.rejected]: (state, action) => {
      state.loading = false;
      state.errorMessage = action.payload;
      state.failModalState = true;
    },
    [replyToUserFeedback.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.successModalState = true;
      state.successMessage = "TABLE.FEEDBACK_SENT";
    },
    [markFeedbackAsAnswered.pending]: (state) => {
      state.errorMessage = "";
      state.loading = true;
    },
    [markFeedbackAsAnswered.rejected]: (state, action) => {
      state.loading = false;
      state.errorMessage = action.payload;
      state.failModalState = true;
    },
    [markFeedbackAsAnswered.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.feedbackDetails.hasBeenAnswered = true;
      state.successModalState = true;
      state.successMessage = "TABLE.FEEDBACK_ANSWERED";
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setPageIndex,
  toggleFeedbackDetailsState,
  toggleFailModalState,
  toggleSuccessModalState,
  resetErrorMessage,
  resetSuccessMessage,
  sendFeedbackState,
} = feedbackSlice.actions;

export default feedbackSlice.reducer;
