import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import ModalContainer from "components/ModalContainer/ModalContainer";
import MDTypography from "components/MDTypography";
import InputAdornment from "@mui/material/InputAdornment";
import { getSettingsData } from "store/settingSlice";
import { useDispatch } from "react-redux";
import { updateLoanFee } from "api/settings";
import { useTranslation } from "react-i18next";

const EditLoanFee = ({ fees, onButtonClose }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [threeMonthFee, setThreeMonthFee] = useState(fees.threeMonthFee);
  const [sixMonthFee, setSixMonthFee] = useState(fees.sixMonthFee);
  const [nineMonthFee, setNineMonthFee] = useState(fees.nineMonthFee);
  const [twelveMonthFee, setTwelveMonthFee] = useState(fees.twelveMonthFee);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const submitValues = async (term, fee) => {
    try {
      const res = await updateLoanFee(term, fee);
      toast.success(t("TOAST.FEE_UPDATED_SUCCESSFULLY"));
      dispatch(getSettingsData());
    } catch (e) {
      console.log(e.response);
      toast.error(e.response);
    }
  };

  useEffect(() => {
    if (isSubmitting && Object.keys(errors).length === 0) {
      if (threeMonthFee !== fees.threeMonthFee) {
        submitValues(3, threeMonthFee);
      }
      if (sixMonthFee !== fees.sixMonthFee) {
        submitValues(6, sixMonthFee);
      }
      if (nineMonthFee !== fees.nineMonthFee) {
        submitValues(9, nineMonthFee);
      }
      if (twelveMonthFee !== fees.twelveMonthFee) {
        submitValues(12, twelveMonthFee);
      }
      setIsSubmitting(false);
      onButtonClose();
    }
  }, [
    errors,
    isSubmitting,
    fees.threeMonthFee,
    fees.sixMonthFee,
    fees.nineMonthFee,
    fees.twelveMonthFee,
    threeMonthFee,
    sixMonthFee,
    nineMonthFee,
    twelveMonthFee,
  ]);

  const handleChange = (e) => {
    setIsSubmitting(false);
    if (e.target.name === "threeMonthFee") {
      setThreeMonthFee(e.target.value);
    }
    if (e.target.name === "sixMonthFee") {
      setSixMonthFee(e.target.value);
    }
    if (e.target.name === "nineMonthFee") {
      setNineMonthFee(e.target.value);
    }
    if (e.target.name === "twelveMonthFee") {
      setTwelveMonthFee(e.target.value);
    }
  };

  const handleSubmit = () => {
    let errorsCode = {};
    if (!threeMonthFee) {
      errorsCode.threeMonthFee = "ERRORS.FEE_REQUIRED";
    }
    if (!sixMonthFee) {
      errorsCode.sixMonthFee = "ERRORS.FEE_REQUIRED";
    }
    if (!nineMonthFee) {
      errorsCode.nineMonthFee = "ERRORS.FEE_REQUIRED";
    }
    if (!twelveMonthFee) {
      errorsCode.twelveMonthFee = "ERRORS.FEE_REQUIRED";
    }
    setErrors(errorsCode);
    setIsSubmitting(true);
  };

  return (
    <ModalContainer
      title={t("SETTINGS.EDIT_LOAN_FEES")}
      onButtonClose={onButtonClose}
    >
      <MDBox width="500px">
        <MDBox p={2}>
          <MDTypography variant="body2">
            {t("SETTINGS.EDIT_LOAN_FEES_MESSAGE")}
          </MDTypography>
        </MDBox>
        <MDBox p={2}>
          <MDInput
            type="text"
            name="threeMonthFee"
            label={t("SETTINGS.THREE_MONTH_FEE")}
            value={threeMonthFee}
            InputProps={{
              startAdornment: (
                <InputAdornment class="input_adornment" position="start">
                  $
                </InputAdornment>
              ),
            }}
            onChange={(e) => handleChange(e)}
            error={!errors ? null : errors.threeMonthFee}
            fullWidth
          />
          {!errors ? null : (
            <p className="error_message">{t(errors.threeMonthFee)}</p>
          )}
        </MDBox>
        <MDBox p={2}>
          <MDInput
            type="text"
            name="sixMonthFee"
            label={t("SETTINGS.SIX_MONTH_FEE")}
            value={sixMonthFee}
            InputProps={{
              startAdornment: (
                <InputAdornment class="input_adornment" position="start">
                  $
                </InputAdornment>
              ),
            }}
            onChange={(e) => handleChange(e)}
            error={!errors ? null : errors.sixMonthFee}
            fullWidth
          />
          {!errors ? null : (
            <p className="error_message">{t(errors.sixMonthFee)}</p>
          )}
        </MDBox>{" "}
        <MDBox p={2}>
          <MDInput
            type="text"
            name="nineMonthFee"
            label={t("SETTINGS.NINE_MONTH_FEE")}
            value={nineMonthFee}
            InputProps={{
              startAdornment: (
                <InputAdornment class="input_adornment" position="start">
                  $
                </InputAdornment>
              ),
            }}
            onChange={(e) => handleChange(e)}
            error={!errors ? null : errors.nineMonthFee}
            fullWidth
          />
          {!errors ? null : (
            <p className="error_message">{t(errors.nineMonthFee)}</p>
          )}
        </MDBox>
        <MDBox p={2}>
          <MDInput
            type="text"
            name="twelveMonthFee"
            label={t("SETTINGS.TWELVE_MONTH_FEE")}
            value={twelveMonthFee}
            InputProps={{
              startAdornment: (
                <InputAdornment class="input_adornment" position="start">
                  $
                </InputAdornment>
              ),
            }}
            onChange={(e) => handleChange(e)}
            error={!errors ? null : errors.twelveMonthFee}
            fullWidth
          />
          {!errors ? null : (
            <p className="error_message">{t(errors.twelveMonthFee)}</p>
          )}
        </MDBox>
        <MDBox display="flex" justifyContent="center">
          <MDButton color="primary" onClick={handleSubmit}>
            {t("SETTINGS.SUBMIT")}
          </MDButton>
        </MDBox>
      </MDBox>
    </ModalContainer>
  );
};

export default EditLoanFee;
