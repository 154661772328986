import React from "react";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

import {
  updateEmail,
  toggleForgotPassword,
  forgotPasswordSubmit,
} from "store/authSlice";
import { useSelector } from "react-redux";

const ForgotPasswordForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [isForgotPasswordSubmitting, setIsForgotPasswordSubmitting] =
    useState(false);

  const [errors, setErrors] = useState({});
  const errorMessage = useSelector((state) => state.auth.errorMessage);

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isForgotPasswordSubmitting) {
      const data = {
        email,
      };
      dispatch(forgotPasswordSubmit(data));
      dispatch(updateEmail(email));
      setIsForgotPasswordSubmitting(false);
      setEmail("");
    }
  }, [errors, isForgotPasswordSubmitting, email]);

  const handleChange = (e) => {
    setIsForgotPasswordSubmitting(false);
    if (e.target.name === "email") {
      setEmail(e.target.value);
    }
  };

  const handleForgotPasswordSubmit = (e) => {
    if (e) e.preventDefault();
    setIsForgotPasswordSubmitting(true);
    setErrors({});
    const regexEmail =
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
    const errorsCode = {};
    if (!email) {
      errorsCode.email = "ERRORS.EMAIL_REQUIRED";
    } else if (!regexEmail.test(email)) {
      errorsCode.email = "ERRORS.VALID_EMAIL";
    }
    setErrors(errorsCode);
  };

  return (
    <>
      <MDBox
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
        mx={2}
        mt={-3}
        p={2}
        mb={1}
        textAlign="center"
      >
        <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
          {t("AUTH.FORGOT_PASSWORD")}
        </MDTypography>
      </MDBox>
      <MDBox pt={4} pb={3} px={3}>
        <MDTypography variant="body2" fontWeight="regular" color="text" mb={3}>
          {t("AUTH.FORGOT_PASSWORD_MESSAGE")}
        </MDTypography>
        <form onSubmit={handleForgotPasswordSubmit}>
          <MDBox mb={2}>
            <MDInput
              type="email"
              name="email"
              label={t("AUTH.EMAIL")}
              value={email}
              onChange={(e) => handleChange(e)}
              error={!errors ? null : errors.email}
              fullWidth
            />
            {!errors.email ? null : (
              <p className="error_message">{t(errors.email)}</p>
            )}
            {!errorMessage ? null : (
              <p className="error_message">{t(errorMessage)}</p>
            )}
          </MDBox>
          <MDBox mt={4} mb={1}>
            <MDButton
              variant="gradient"
              color="info"
              onClick={handleForgotPasswordSubmit}
              fullWidth
            >
              {t("AUTH.SUBMIT")}
            </MDButton>
          </MDBox>
          <MDBox display="flex" justifyContent="flex-end" pt={2}>
            <MDButton
              onClick={() => dispatch(toggleForgotPassword())}
              variant="text"
              color="dark"
              size="large"
            >
              {t("AUTH.SIGN_IN")}
            </MDButton>
          </MDBox>
        </form>
      </MDBox>
    </>
  );
};

export default ForgotPasswordForm;
