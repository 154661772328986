import React from "react";
// Material Dashboard 2 PRO React components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import { getDateWithTime } from "helpers/helpers";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import { Grid, CircularProgress, Card, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import MDButton from "components/MDButton";
import { getReports } from "store/payoutSlice";
import { useEffect, useState } from "react";
import DataTable from "../../../components/DataTable";
import MDTypography from "components/MDTypography";
import ReportDetail from "./ReportDetail";
import { getPayoutReportExcel, markReportAsPaid } from "api/payout";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export default function Payout() {
  const { t } = useTranslation();
  const [report, setReport] = useState([]);
  // const [minFloat, setMinFloat] = useState(0);
  const [pageIndex, setPageIndex] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [searchTerms, setSearchTerms] = useState("");
  const [forceSearchTerms, setForceSearchTerms] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [selectedReport, setSelectedReport] = useState("");
  const dispatch = useDispatch();

  const allReports = useSelector((state) => state.payout.allReports);
  const loading = useSelector((state) => state.payout.getReportsLoading);

  useEffect(() => {
    if (allReports?.totalCount > 0) {
      setReport({
        columns: [
          { Header: t("PAYOUT.CREATED"), accessor: "created" },
          { Header: t("PAYOUT.TOTAL_PAYOUT"), accessor: "totalPayout" },
          { Header: t("PAYOUT.IS_PAID"), accessor: "isPaid" },
          {
            Header: "",
            accessor: "actions",
            width: 300,
            align: "right",
          },
        ],
        rows: allReports.reports.map((report) => {
          return {
            // "totalPayout": 0,
            // "created": "2022-07-20T21:54:56.156Z",
            // "isPaid": true
            totalPayout: report.totalPayout,
            isPaid: report.isPaid.toString()[0].toUpperCase() + report.isPaid.toString().substring(1),
            created: getDateWithTime(report.created),
            actions: (
              <div key={report.id}>
                {!report.isPaid && (
                  <>
                    <MDButton
                      onClick={() => {
                        markReportAsPaid(report.id)
                          .then((res) => {
                            toast.success(t("TOAST.MARK_AS_PAID"));
                            getData();
                          })
                          .catch((e) => {
                            toast.success(t("TOAST.FAILED_MARK_AS_PAID"));
                          });
                      }}
                      // key={report.id}
                      title={t("PAYOUT.MARK_AS_PAID")}
                      color="light"
                      style={{ color: "#7b809a" }}
                      variant="contained"
                    >
                      {t("PAYOUT.MARK_AS_PAID")}
                    </MDButton>{" "}
                  </>
                )}
                <MDButton
                  onClick={() => {
                    getPayoutReportExcel(report.id)
                      .then((blob) => {
                        let url = window.URL.createObjectURL(blob);
                        let a = document.createElement("a");
                        a.href = url;
                        a.download = "report.xlsx";
                        a.click();
                      })
                      .catch((e) => {
                        toast.error(t("TOAST.FAILED_GENARATE_THE_REPORT"));
                      });
                  }}
                  // key={report.id}
                  title={t("PAYOUT.DOWNLOAD_REPORT")}
                  color="light"
                  style={{ color: "#7b809a" }}
                  variant="contained"
                >
                  {t("PAYOUT.DOWNLOAD_REPORT")}
                </MDButton>{" "}
                <MDButton
                  onClick={() => {
                    setPage(2);
                    setSelectedReport(report.id);
                  }}
                  // key={report.id}
                  title={t("PAYOUT.VIEW_DETAIL")}
                  color="light"
                  style={{ color: "#7b809a" }}
                  variant="contained"
                >
                  {t("PAYOUT.VIEW_DETAIL")}
                </MDButton>
              </div>
            ),
          };
        }),
      });
      setTotalCount(allReports.totalCount);
    }
  }, [allReports]);

  function getData() {
    let data = {
      searchTerms: "",
      forceSearchTerms,
      pageIndex,
      pageSize,
    };
    dispatch(getReports(data));
  }
  // useEffect(() => {
  // 	// forceSearchTerms needs to be implemented as search
  // 	console.log("pageIndex payout", pageIndex);
  // 	getData();
  // }, [pageIndex]);

  useEffect(() => {
    if (forceSearchTerms.length > 0) {
      const timeoutId = setTimeout(() => {
        getData();
      }, 1000);
      return () => {
        clearTimeout(timeoutId);
      };
    } else {
      getData();
    }
  }, [forceSearchTerms, pageIndex]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={3}>
        {
          // page = 2 means that it is under detail page
          page === 2 ? (
            <ReportDetail
              selectedReport={selectedReport}
              goBack={() => {
                setPage(1);
              }}
            ></ReportDetail>
          ) : (
            <Card>
              <MDBox p={3} lineHeight={1}>
                <MDTypography variant="h5" fontWeight="medium">
                  {t("PAYOUT.ALL_REPORTS")}
                </MDTypography>
              </MDBox>
              {report?.rows?.length > 0 ? (
                <DataTable
                  table={report}
                  totalCount={totalCount}
                  setPageIndex={setPageIndex}
                  parentPageIndex={pageIndex}
                  canSearch={false}
                  parentPageSize={pageSize}
                  loading={loading}
                  setSearchTerms={setForceSearchTerms}
                  searchTerms={forceSearchTerms}
                />
              ) : (
                <MDBox p={3} lineHeight={1} display="flex" alignItems="center">
                  <MDTypography variant="h6" fontWeight="medium">
                    {t("PAYOUT.NO_DATA")}
                  </MDTypography>
                </MDBox>
              )}
            </Card>
          )
        }
      </MDBox>
    </DashboardLayout>
  );
}
