import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import ModalContainer from "components/ModalContainer/ModalContainer";
import MDTypography from "components/MDTypography";
import { getSettingsData } from "store/settingSlice";
import { newLoanThreshold } from "api/settings";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

const EditThreshold = ({ threshold, onButtonClose }) => {
  const dispatch = useDispatch();
  const {t}= useTranslation();
  const [tattooThreshold, setTattooThreshold] = useState(threshold);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const submitValues = async () => {
    try {
      const res = await newLoanThreshold(tattooThreshold);
      toast.success(t("TOAST.THRESHOLD_UPDATED_SUCCESSFULLY"));
      dispatch(getSettingsData());
    } catch (e) {
      console.log(e.response);
      toast.error(e.response);
    }
  };

  useEffect(() => {
    if (isSubmitting && Object.keys(errors).length === 0) {
      submitValues();
      setIsSubmitting(false);
      onButtonClose();
    }
  }, [errors, isSubmitting]);

  const handleChange = (e) => {
    setIsSubmitting(false);
    if (e.target.name === "tattooThreshold") {
      setTattooThreshold(e.target.value);
    }
  };

  const handleSubmit = () => {
    let errorsCode = {};
    if (!tattooThreshold) {
      errorsCode.tattooThreshold = "ERRORS.THRESHOLD_REQUIRED";
    } else if (tattooThreshold > 1) {
      errorsCode.tattooThreshold = "ERRORS.THRESHOLD_GREATER_THAN_1";
    }
    setErrors(errorsCode);
    setIsSubmitting(true);
  };

  return (
    <ModalContainer
      title={t("SETTINGS.EDIT_NEW_LOAN_THRESHOLD")}
      onButtonClose={onButtonClose}
    >
      <MDBox width="500px">
        <MDBox p={2}>
          <MDTypography variant="body2">
            {t("SETTINGS.EDIT_THRESHOLD_MESSAGE")}
          </MDTypography>
        </MDBox>
        <MDBox p={2}>
          <MDInput
            type="text"
            name="tattooThreshold"
            label={t("SETTINGS.LOAN_THRESHOLD")}
            value={tattooThreshold}
            onChange={(e) => handleChange(e)}
            error={!errors ? null : errors.tattooThreshold}
            fullWidth
          />
          {!errors ? null : (
            <p className="error_message">{t(errors.tattooThreshold)}</p>
          )}
        </MDBox>
        <MDBox display="flex" justifyContent="center">
          <MDButton color="primary" onClick={handleSubmit}>
            {t("SETTINGS.SUBMIT")}
          </MDButton>
        </MDBox>
      </MDBox>
    </ModalContainer>
  );
};

export default EditThreshold;
