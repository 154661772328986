/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { toast } from "react-toastify";
import Typography from "@mui/material/Typography";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";

// Material Dashboard 2 PRO React components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import { getDateWithTime } from "helpers/helpers";
// Billing page components
// import Transactions from "layouts/pages/float-management/components/Transactions";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "../../../components/DataTable";
import {
	getFloatTable,
	addFloat,
	freezeLoanApplications,
} from "api/floatManagement";
import { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { useTranslation } from "react-i18next";
// import "./index.css"

const label = { inputProps: { "aria-label": "Freeze Loan" } };

function FloatManagement() {
	const {t}= useTranslation();
	const [floatData, setFloatData] = useState([]);
	const [float, setFloat] = useState(0);
	// const [minFloat, setMinFloat] = useState(0);
	const [pageIndex, setPageIndex] = useState(1);
	const [totalCount, setTotalCount] = useState(0);
	const [totalBalance, setTotalBalance] = useState(0);
	const [loansAreFrozen, setLoansAreFrozen] = useState(false);
	const pageSize = 10;

	const getFloatData = async () => {
		try {
			const data = {
				pageIndex,
				pageSize,
			};
			const res = await getFloatTable(data);
			setTotalBalance(res.totalBalance);
			setLoansAreFrozen(res.loansAreFrozen);
			setFloatData({
				columns: [
					{ Header: t("FLOAT.CREATED"), accessor: "created" },
					{ Header: t("FLOAT.AMOUNT"), accessor: "amount" },
					{ Header: t("FLOAT.INITIATED_USER_TYPE"), accessor: "initiatedUserType" },
				],
				rows: res.floats.map((float) => {
					return {
						amount: float.amount,
						initiatedUserType: float.initiatedUserType,
						created: getDateWithTime(float.created),
					};
				}),
			});
			setTotalCount(res.totalCount);
		} catch (error) {
			console.log(error.response);
		}
	};

	useEffect(() => {
		getFloatData();
	}, [pageIndex]);

	const handleAddFloat = async () => {
		try {
			const res = await addFloat(float);
			setFloat(0);
			getFloatData();
			toast.success(t("TOAST.FLOAT_ADJUSTED_SUCCESSFULLY"));
		} catch (e) {
			console.log(e.response);
			toast.error(e.response);
		}
	};
	// const handleEditMinFloat = async () => {
	// 	try {
	// 		const res = await editMinFloat({ minFloatValue: minFloat });
	// 		setMinFloat(0);
	// 		toast.success("Successfully edit minimum float");
	// 	} catch (e) {
	// 		console.log(e.response);
	// 		toast.error(e.response);
	// 	}
	// };

	return (
		<DashboardLayout>
			<DashboardNavbar />
			<MDBox mt={4}>
				<MDBox mb={3}>
					<Grid
						container
						spacing={3}
						direction="row"
						justifyContent="space-between"
						alignItems="center"
					>
						<Grid item>
							<TextField
								id="totalBalance"
								label={t("FLOAT.AVAILABLE_FLOAT")}
								variant="outlined"
								value={"$" + totalBalance}
								disabled
							/>
							<TextField
								type="number"
								id="adjustFloat"
								label={t("FLOAT.ADJUST_FLOAT")}
								variant="outlined"
								value={float}
								onChange={(e) => {
									setFloat(e.target.value);
								}}
							/>
							<Button
								variant="outlined"
								style={{ color: "black" }}
								onClick={handleAddFloat}
							>
								{t("FLOAT.Add_Subtract_Float")}
							</Button>
						</Grid>
						{/* <Grid item>
							<TextField
								type="number"
								id="minFloat"
								label="Min Float"
								variant="outlined"
								value={minFloat}
								onChange={(e) => {
									setMinFloat(e.target.value);
								}}
							/>
							<Button
								variant="outlined"
								style={{ color: "black" }}
								onClick={handleEditMinFloat}
							>
								Edit Min Float
							</Button>
						</Grid>*/}
						<Grid item>
							{/* <Button
								variant="outlined"
								style={{ color: "black" }}
								onClick={() => {
									try {
										freezeLoanApplications(true);
										toast.success("Successfully frooze loan");
									} catch (e) {
										toast.error("Failed freeze loan");
									}
								}}
							>
								Freeze Loan Applications
							</Button>
							<Button
								variant="outlined"
								style={{ color: "black" }}
								onClick={() => {
									try {
										freezeLoanApplications(false);
										toast.success("Successfully unfrooze loan");
									} catch (e) {
										toast.error("Failed unfreeze loan");
									}
								}}
							>
								Unfreeze Loan Applications
							</Button> */}
							<Stack alignItems="center">
								<Typography variant="h6">
									{t("FLOAT.DISABLE_LOAN_APPLICATION")}
								</Typography>
								<Stack direction="row" spacing={1} alignItems="center">
									<Typography variant="h6">{t("FLOAT.OFF")}</Typography>

									<Switch
										{...label}
										label="Top"
										// defaultChecked={loansAreFrozen}
										checked={loansAreFrozen}
										color="error"
										onChange={async (e) => {
											try {
												setLoansAreFrozen(e.target.checked);
												let freezeLoanRes = await freezeLoanApplications(e.target.checked);
												if (e.target.checked) {
													toast.success(t("TOAST.CUSTOMER_LOAN_APPLICATION_DISABLED"));
												} else {
													toast.success(t("TOAST.CUSTOMER_LOAN_APPLICATION_ENABLED"));
												}
											} catch (e) {
												toast.error(t("TOAST.OPREATION_FAILED"));
											}
										}}
									/>

									<Typography variant="h6">{t("FLOAT.ON")}</Typography>
								</Stack>
							</Stack>
						</Grid>
						<Grid item xs={12}>
							{floatData?.rows?.length > 0 ? (
								<DataTable
									table={floatData}
									totalCount={totalCount}
									setPageIndex={setPageIndex}
									parentPageIndex={pageIndex}
								/>
							) : (
								<Grid
									container
									direction="row"
									justifyContent="center"
									alignItems="center"
								>
									<CircularProgress />
								</Grid>
							)}
						</Grid>
					</Grid>
				</MDBox>
			</MDBox>
		</DashboardLayout>
	);
}

export default FloatManagement;
