import React, { useEffect, useState } from "react";

import { toast } from "react-toastify";

import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import ModalContainer from "components/ModalContainer/ModalContainer";
import InputAdornment from "@mui/material/InputAdornment";
import MDTypography from "components/MDTypography";
import { loanMinMax } from "api/settings";
import { getSettingsData } from "store/settingSlice";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

const EditMaxMinLoanValue = ({ max, min, onButtonClose }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [maxValue, setMaxValue] = useState(max);
  const [minValue, setMinValue] = useState(min);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const submitValues = async () => {
    if (maxValue !== max) {
      try {
        const data = {
          isMax: true,
          value: maxValue,
        };
        const res = await loanMinMax(data);
        toast.success(t("TOAST.LOAN_VALUES_UPDATED_SUCCESSFULLY"));
        dispatch(getSettingsData());
      } catch (e) {
        if (
          e.response.data.message === "MAX_VALUE_NOT_GREATER_THAN_MIN_VALUE"
        ) {
          toast.error(t("ERRORS.MAX_VALUE_NOT_GREATER_THAN_MIN_VALUE"));
        }
        if (e.response.data.message === "MIN_LESS_THAN_SUBLOAN_MIN") {
          toast.error(t("ERRORS.MIN_LESS_THAN_SUBLOAN_MIN"));
        } else {
          console.log(e.response);
          toast.error(e.response);
        }
      }
    }
    if (minValue !== min) {
      try {
        const data = {
          isMax: false,
          value: minValue,
        };
        const res = await loanMinMax(data);
        toast.success(t("TOAST.LOAN_VALUES_UPDATED_SUCCESSFULLY"));
        dispatch(getSettingsData());
      } catch (e) {
        console.log(e.response);
        toast.error(e.response);
      }
    }
  };

  useEffect(() => {
    if (isSubmitting && Object.keys(errors).length === 0) {
      submitValues();
      setIsSubmitting(false);
      onButtonClose();
    }
  }, [errors, isSubmitting]);

  const handleChange = (e) => {
    setIsSubmitting(false);
    if (e.target.name === "maxValue") {
      setMaxValue(e.target.value);
    }
    if (e.target.name === "minValue") {
      setMinValue(e.target.value);
    }
  };

  const handleSubmit = () => {
    let errorsCode = {};
    if (!maxValue) {
      errorsCode.maxValue = "ERRORS.MAX_VALUE_REQUIRED";
    }
    if (!minValue) {
      errorsCode.minValue = "ERRORS.MIN_VALUE_REQUIRED";
    }
    if (minValue > maxValue) {
      errorsCode.minValue = "ERRORS.MAX_VALUE_NOT_GREATER_THAN_MIN_VALUE";
    }
    setErrors(errorsCode);
    setIsSubmitting(true);
  };

  return (
    <ModalContainer
      title={t("SETTINGS.EDIT_MAX_MIN_VALUE")}
      onButtonClose={onButtonClose}
    >
      <MDBox width="500px">
        <MDBox p={2}>
          <MDTypography variant="body2">
            {t("SETTINGS.EDIT_MAX_MIN_VALUE_MESSAGE")}
          </MDTypography>
        </MDBox>
        <MDBox p={2}>
          <MDInput
            type="text"
            name="maxValue"
            label={t("SETTINGS.MAX_LOAN_VALUE")}
            value={maxValue}
            onChange={(e) => handleChange(e)}
            InputProps={{
              startAdornment: (
                <InputAdornment class="input_adornment" position="start">
                  $
                </InputAdornment>
              ),
            }}
            error={!errors ? null : errors.maxValue}
            fullWidth
          />
          {!errors ? null : (
            <p className="error_message">{t(errors.maxValue)}</p>
          )}
        </MDBox>
        <MDBox p={2}>
          <MDInput
            type="text"
            name="minValue"
            label={t("SETTINGS.MIN_LOAN_VALUE")}
            value={minValue}
            onChange={(e) => handleChange(e)}
            InputProps={{
              startAdornment: (
                <InputAdornment class="input_adornment" position="start">
                  $
                </InputAdornment>
              ),
            }}
            error={!errors ? null : errors.minValue}
            fullWidth
          />
          {!errors ? null : (
            <p className="error_message">{t(errors.minValue)}</p>
          )}
        </MDBox>
        <MDBox display="flex" justifyContent="center">
          <MDButton color="primary" onClick={handleSubmit}>
            {t("SETTINGS.SUBMIT")}
          </MDButton>
        </MDBox>
      </MDBox>
    </ModalContainer>
  );
};

export default EditMaxMinLoanValue;
