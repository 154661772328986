import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

import { mfaSubmit } from "store/authSlice";
import { setMfaCodeState } from "store/authSlice";

const MfaCodeForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [smsCode, setSMSCode] = useState("");
  const [isSMSSubmitting, setIsSMSSubmitting] = useState(false);
  const signInInfo = useSelector((state) => state.auth.signInInfo);
  const errorMessage = useSelector((state) => state.auth.errorMessage);

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSMSSubmitting) {
      const data = {
        userID: signInInfo.userId,
        code: smsCode.trim(),
        session: signInInfo.session,
      };
      dispatch(mfaSubmit(data));
      setIsSMSSubmitting(false);
      setSMSCode("");
    }
  }, [errors, isSMSSubmitting, smsCode]);

  const handleChange = (e) => {
    setIsSMSSubmitting(false);
    if (e.target.name === "smsCode") {
      setSMSCode(e.target.value);
    }
  };
  const handleSMSCodeSubmit = (e) => {
    if (e) e.preventDefault();
    setIsSMSSubmitting(true);
    setErrors({});
    if (!smsCode) {
      const errorsCode = {};
      errorsCode.smsCode = "ERRORS.CODE_REQUIRED";
      setErrors(errorsCode);
    }
  };

  const handleReroute = () => {
    dispatch(setMfaCodeState(false));
  };

  return (
    <>
      <MDBox
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
        mx={2}
        mt={-3}
        p={2}
        mb={1}
        textAlign="center"
      >
        <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
          {t("AUTH.SMS_CODE")}
        </MDTypography>
      </MDBox>
      <MDBox pt={4} pb={3} px={3}>
        <MDBox mb={2}>
          <MDTypography
            variant="body2"
            fontWeight="regular"
            color="text"
            mb={3}
          >
            {!signInInfo.codeDeliveryDestination
              ? t("AUTH.SMS_CODE_MESSAGE_NO_PHONE")
              : t("AUTH.SMS_CODE_MESSAGE", {
                  phone: signInInfo.codeDeliveryDestination,
                })}
          </MDTypography>
          <form onSubmit={handleSMSCodeSubmit}>
            <MDInput
              type="number"
              name="smsCode"
              label={t("AUTH.CODE")}
              value={smsCode}
              onChange={(e) => handleChange(e)}
              error={!errors ? null : errors.smsCode}
              fullWidth
            />
            {!errors.smsCode ? null : (
              <p className="error_message">{t(errors.smsCode)}</p>
            )}
            {!errorMessage ? null : (
              <p className="error_message">{t(errorMessage)}</p>
            )}
          </form>
          <MDBox mt={4} mb={1}>
            <MDButton
              variant="gradient"
              color="info"
              onClick={handleSMSCodeSubmit}
              fullWidth
            >
              {t("AUTH.SIGN_IN")}
            </MDButton>
          </MDBox>
          <MDBox display="flex" justifyContent="flex-end" pt={2}>
            <MDButton
              onClick={handleReroute}
              variant="text"
              color="dark"
              size="large"
            >
              {t("AUTH.SIGN_IN")}
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </>
  );
};

export default MfaCodeForm;
