export default function passwordValidate(newPassword, confirmPassword) {
  const errors = {};
  const regexPassword =
    /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[~`!@#$%^&*()--+={}[\]|\\:;"'<>,.?/_₹]).{8,}$/;
  if (newPassword === "") {
    errors.newPassword = "ERRORS.PASSWORD_REQUIRED";
  }
  if (confirmPassword === "") {
    errors.confirmPassword = "ERRORS.PASSWORD_REQUIRED";
  }
  if (newPassword !== confirmPassword) {
    errors.confirmPassword = "ERRORS.PASSWORDS_MUST_MATCH";
  }
  if (!regexPassword.test(newPassword)) {
    errors.confirmPassword = "ERRORS.VALID_PASSWORD";
  }
  return errors;
}
