/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider as ReduxProvider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import "./index.css";
import "./i18n";
import App from "App";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
// Material Dashboard 2 PRO React Context Provider
import { MaterialUIControllerProvider } from "context";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import store, { persistor } from "./store/store";
import { injectStore } from "./api/api";
import { registerLicense } from "@syncfusion/ej2-base";

//adding sentry for error tracking
if (process.env.REACT_APP_ENV === "production" || process.env.REACT_APP_ENV === "staging") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.8,
    environment: process.env.REACT_APP_ENV,
  });
}

// Registering Syncfusion license key
registerLicense(process.env.REACT_APP_SYNCFUSION_KEY);
injectStore(store);

ReactDOM.render(
  <Suspense fallback="loading">
    <ReduxProvider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <MaterialUIControllerProvider>
            <App />
            <ToastContainer />
          </MaterialUIControllerProvider>
        </BrowserRouter>
      </PersistGate>
    </ReduxProvider>
  </Suspense>,
  document.getElementById("root")
);
