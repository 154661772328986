import React, { useEffect, useState } from "react";

import { toast } from "react-toastify";

import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import ModalContainer from "components/ModalContainer/ModalContainer";
import MDTypography from "components/MDTypography";
import { getSettingsData } from "store/settingSlice";
import { useDispatch } from "react-redux";
import { updateRiskScores } from "api/settings";
import { useTranslation } from "react-i18next";

const EditRiskScores = ({ maxScore, minScore, onButtonClose }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [maxRiskScore, setMaxRiskScore] = useState(maxScore);
  const [minRiskScore, setMinRiskScore] = useState(minScore);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const submitValues = async (data) => {
    try {
      const res = await updateRiskScores(data);
      toast.success(t("TOAST.RISK_SCORES_UPDATED_SUCCESSFULLY"));
      dispatch(getSettingsData());
    } catch (e) {
      console.log(e.response);
      toast.error(e.response);
    }
  };

  useEffect(() => {
    if (isSubmitting && Object.keys(errors).length === 0) {
      if (maxRiskScore !== maxScore) {
        const data = {
          isMax: true,
          value: maxRiskScore,
        };
        submitValues(data);
      }
      if (minRiskScore !== minScore) {
        const data = {
          isMax: false,
          value: minRiskScore,
        };
        submitValues(data);
      }
      setIsSubmitting(false);
      onButtonClose();
    }
  }, [errors, isSubmitting, maxRiskScore, minRiskScore, minScore, maxScore]);

  const handleChange = (e) => {
    setIsSubmitting(false);
    if (e.target.name === "maxRiskScore") {
      setMaxRiskScore(e.target.value);
    }
    if (e.target.name === "minRiskScore") {
      setMinRiskScore(e.target.value);
    }
  };

  const handleSubmit = () => {
    let errorsCode = {};
    if (!maxRiskScore) {
      errorsCode.maxRiskScore = "ERRORS.RISK_SCORE_REQUIRED";
    } else if (maxRiskScore > 19) {
      errorsCode.maxRiskScore = "ERRORS.RISK_SCORE_GREATER_THAN_19";
    }
    if (!minRiskScore) {
      errorsCode.minRiskScore = "ERRORS.RISK_SCORE_REQUIRED";
    }
    setErrors(errorsCode);
    setIsSubmitting(true);
  };

  return (
    <ModalContainer
      title={t("SETTINGS.EDIT_RISK_SCORES")}
      onButtonClose={onButtonClose}
    >
      <MDBox width="500px">
        <MDBox p={2}>
          <MDTypography variant="body2">
            {t("SETTINGS.EDIT_RISK_SCORES_MESSAGE")}
          </MDTypography>
        </MDBox>
        <MDBox p={2}>
          <MDInput
            type="number"
            name="maxRiskScore"
            label={t("SETTINGS.MAX_RISK_SCORE")}
            value={maxRiskScore}
            onChange={(e) => handleChange(e)}
            error={!errors ? null : errors.maxRiskScore}
            fullWidth
          />
          {!errors ? null : (
            <p className="error_message">{t(errors.maxRiskScore)}</p>
          )}
        </MDBox>
        <MDBox p={2}>
          <MDInput
            type="number"
            name="minRiskScore"
            label={t("SETTINGS.MIN_RISK_SCORE")}
            value={minRiskScore}
            onChange={(e) => handleChange(e)}
            error={!errors ? null : errors.minRiskScore}
            fullWidth
          />
          {!errors ? null : (
            <p className="error_message">{t(errors.minRiskScore)}</p>
          )}
        </MDBox>
        <MDBox display="flex" justifyContent="center">
          <MDButton color="primary" onClick={handleSubmit}>
            {t("SETTINGS.SUBMIT")}
          </MDButton>
        </MDBox>
      </MDBox>
    </ModalContainer>
  );
};

export default EditRiskScores;
