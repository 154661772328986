import React, { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "components/DataTable";
import { getReportArtistDetails } from "store/payoutSlice";
import { useDispatch, useSelector } from "react-redux";
import { getDateWithTime } from "helpers/helpers";
import { useTranslation } from "react-i18next";

export default function ReportArtistDetail({
  selectedReport,
  selectedAritist,
}) {
  const { t } = useTranslation();
  const [pageIndex, setPageIndex] = useState(1);
  const [artistDetails, setArtistDetails] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const dispatch = useDispatch();
  const reprotArtistDetails = useSelector(
    (state) => state.payout.reprotArtistDetails
  );
  const loading = useSelector(
    (state) => state.payout.getReportArtistDetailsLoading
  );

  const getArtistDetails = () => {
    let data = {
      searchTerms: "",
      forceSearchTerms: "",
      pageIndex,
      pageSize,
      selectedReport: selectedReport,
      selectedAritist: selectedAritist,
    };
    dispatch(getReportArtistDetails(data));
  };
  // useEffect(() => {
  // 	if (selectedAritist) {
  // 		getArtistDetails();
  // 	}
  // }, [selectedAritist]);

  useEffect(() => {
    if (reprotArtistDetails?.totalCount > 0) {
      setArtistDetails({
        columns: [
          { Header: t("PAYOUT.CREATED"), accessor: "created" },
          { Header: t("PAYOUT.IS_PAID"), accessor: "paid" },
          { Header: t("PAYOUT.AMOUNT"), accessor: "amount" },
        ],
        rows: reprotArtistDetails.payouts.map((payout) => {
          return {
            amount: payout.amount.toString(),
            created: getDateWithTime(payout.created),
            paid: payout.paid
              ? getDateWithTime(payout.paid)
              : t("PAYOUT.NOT_GET_PAID_YET"),
          };
        }),
      });
      setTotalCount(reprotArtistDetails.totalCount);
    }
  }, [reprotArtistDetails]);

  useEffect(() => {
    getArtistDetails();
  }, [pageIndex]);

  return (
    <>
      {artistDetails?.rows?.length > 0 ? (
        <>
          <MDTypography variant="h5" fontWeight="medium">
            {t("PAYOUT.ARTIST_DETAILS")}
          </MDTypography>
          <DataTable
            // entriesPerPage={{ entries: "2" }}
            table={artistDetails}
            totalCount={totalCount}
            setPageIndex={setPageIndex}
            parentPageIndex={pageIndex}
            parentPageSize={pageSize}
            loading={loading}
          />
        </>
      ) : (
        <MDBox p={3} lineHeight={1} display="flex" alignItems="center">
          <MDTypography variant="h6" fontWeight="medium">
            {t("PAYOUT.NO_DATA")}
          </MDTypography>
        </MDBox>
      )}
    </>
  );
}
