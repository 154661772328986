import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Trans } from "react-i18next";

import PaymentDetailsModal from "../PaymentDetailsModal/PaymentDetailsModal";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import PDFVIewer from "../PDFViewer";
import MDTypography from "components/MDTypography";

import iconTattooGun from "../../../../../assets/images/ink_me/icons/icon_background_tattoo_gun.svg";

import { getDateWithTime } from "helpers/helpers";
import "./LoanDetails.css";
import { getUpcomingPayments } from "store/loanSlice";
import { toggleUpcomingPaymentsState } from "store/loanSlice";
import { getLoanDetails } from "store/loanSlice";
import { getPaymentDetails } from "api/loan";

const ActiveLoanDetails = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loanDetails = useSelector((state) => state.loan.loanDetails);
  const upcomingPaymentState = useSelector(
    (state) => state.loan.upcomingPaymentsState
  );
  const [error, setError] = useState("");
  const [togglePaymentDetails, setTogglePaymentDetails] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState();
  const [viewContractState, setViewContractState] = useState(false);

  useEffect(() => {
    if (upcomingPaymentState) {
      toggleUpcomingPaymentsState();
    }
  }, []);

  useEffect(() => {
    const getUpcomingPaymentInfo = async () => {
      dispatch(getUpcomingPayments(loanDetails.id));
    };
    if (upcomingPaymentState) {
      getUpcomingPaymentInfo();
    }
  }, [dispatch, loanDetails.id, upcomingPaymentState]);

  useEffect(() => {
    const getHistoryInfo = async () => {
      dispatch(getLoanDetails(loanDetails.id));
    };
    if (!upcomingPaymentState) {
      getHistoryInfo();
    }
  }, [dispatch, loanDetails.id, upcomingPaymentState]);

  const handleViewContract = () => {
    setViewContractState(true);
  };

  const seePaymentDetails = async (e) => {
    e.preventDefault();
    const data = {
      loanId: loanDetails.id,
      date: e.target.value,
    };
    try {
      const res = await getPaymentDetails(data);
      setPaymentDetails(res);
      setTogglePaymentDetails(true);
    } catch (error) {
      console.log(error.response);
      setError("ERRORS.GENERAL_ERROR");
    }
  };

  const getPaymentDateMonth = (isoDate) => {
    const monthNames = [
      t("LOAN.JANUARY"),
      t("LOAN.FEBRUARY"),
      t("LOAN.MARCH"),
      t("LOAN.APRIL"),
      t("LOAN.MAY"),
      t("LOAN.JUNE"),
      t("LOAN.JULY"),
      t("LOAN.AUGUST"),
      t("LOAN.SEPTEMBER"),
      t("LOAN.OCTOBER"),
      t("LOAN.NOVEMBER"),
      t("LOAN.DECEMBER"),
    ];
    const date = new Date(isoDate);
    let year = date.getFullYear();
    let dt = date.getDate();
    let month = monthNames[date.getMonth()];

    return `${month} ${dt}, ${year}`;
  };

  const getPaymentDateMonthWithTime = (isoDate) => {
    const monthNames = [
      t("LOAN.JAN"),
      t("LOAN.FEB"),
      t("LOAN.MAR"),
      t("LOAN.APR"),
      t("LOAN.MAY"),
      t("LOAN.JUN"),
      t("LOAN.JUL"),
      t("LOAN.AUG"),
      t("LOAN.SEP"),
      t("LOAN.OCT"),
      t("LOAN.NOV"),
      t("LOAN.DEC"),
    ];
    const date = new Date(isoDate);
    let year = date.getFullYear();
    let dt = date.getDate();
    let month = monthNames[date.getMonth()];
    let hour = date.getHours();
    let minute = date.getMinutes();

    if (month < 10) {
      month = "0" + month;
    }

    let amPm = "AM";
    if (dt < 10) {
      dt = "0" + dt;
    }
    if (hour >= 12) {
      amPm = "PM";
    }
    hour = hour % 12;
    hour = hour ? hour : 12;

    if (minute.toString().length === 1) {
      minute = `0${minute}`;
    }

    return `${month} ${dt}, ${year} ${hour}:${minute} ${amPm}`;
  };

  const renderStatus = () => {
    if (
      loanDetails.status === "Loan_Awaiting_Approval" ||
      loanDetails.status === "Risk_Score_Waiting"
    ) {
      return <h3 className="loan_card_status">{t("LOAN.PENDING_APPROVAL")}</h3>;
    } else if (
      loanDetails.status === "Loan_Approved" ||
      loanDetails.status === "Active" ||
      loanDetails.status === "SubLoan_Waiting_Approval" ||
      loanDetails.status === "Completed"
    ) {
      return <h3 className="loan_card_status">{t("LOAN.APPROVED")}</h3>;
    } else if (
      loanDetails.status === "In_Collections" &&
      loanDetails.sentToCollections
    ) {
      return (
        <h3 className="loan_card_in_collections">{t("LOAN.IN_COLLECTIONS")}</h3>
      );
    } else if (
      loanDetails.status === "In_Collections" &&
      !loanDetails.sentToCollections
    ) {
      return (
        <h3 className="loan_card_in_collections">{t("LOAN.ADMIN_HOLD")}</h3>
      );
    }
  };
  const renderUpcomingPayments = () => {
    if (!loanDetails) {
      return (
        <div className="loan_details_history_no_content">
          <img src={iconTattooGun} alt="tattoo_gun_icon" />
          <p>{t("LOAN.NO_UPCOMING_PAYMENT_YET")}</p>
        </div>
      );
    } else if (loanDetails.payments === null) {
      return (
        <div className="loan_details_history_no_content">
          <img src={iconTattooGun} alt="tattoo_gun_icon" />
          <p>{t("LOAN.NO_UPCOMING_PAYMENT_YET")}</p>
        </div>
      );
    } else {
      return loanDetails.payments.map((payment) => {
        return (
          <div
            key={payment.lineDate + payment.realDate + payment.lineAmount}
            className="loan_details_history_item"
          >
            <div className="loan_details_history_main_details">
              <p className="loan_detail_payment_date">
                {getPaymentDateMonthWithTime(payment.lineDate)}
              </p>
              <p className="loan_detail_payment_description">
                {t("LOAN.PAYMENT_DUE")}
              </p>
              <p className="loan_detail_payment_amount">
                ${payment.lineAmount.toFixed(2)}
              </p>
            </div>
            <button
              onClick={seePaymentDetails}
              value={payment.realDate}
              className="payment_details_button"
            >
              {t("LOAN.PAYMENT_DETAILS")}
            </button>
          </div>
        );
      });
    }
  };

  const renderHistory = () => {
    if (
      !loanDetails ||
      loanDetails.payments === null ||
      Object.keys(loanDetails.payments).length === 0
    ) {
      return (
        <div className="loan_details_history_no_content">
          <img src={iconTattooGun} alt="tattoo_gun_icon" />
          <p>{t("LOAN.NO_PAYMENT_YET")}</p>
        </div>
      );
    } else {
      return loanDetails.payments.map((payment) => {
        if (payment.isPayment && !payment.isLatePayment) {
          return (
            <div
              key={payment.lineDate + payment.realDate + payment.lineAmount}
              className="loan_details_history_item"
            >
              <div className="loan_details_history_main_details">
                <p className="loan_detail_payment_date">
                  {getPaymentDateMonthWithTime(payment.lineDate)}
                </p>
                <p className="loan_detail_payment_description">
                  {t("LOAN.PAYMENT_MADE")}
                </p>
                <p className="loan_detail_payment_amount">
                  ${payment.lineAmount.toFixed(2)}
                </p>
              </div>
              <button
                onClick={seePaymentDetails}
                value={payment.realDate}
                className="payment_details_button"
              >
                {t("LOAN.PAYMENT_DETAILS")}
              </button>
            </div>
          );
        } else if (payment.isPayment && payment.isLatePayment) {
          return (
            <div key={payment.lineDate} className="loan_details_history_item">
              <div className="loan_details_history_main_details">
                <p className="loan_detail_payment_date">
                  {getPaymentDateMonthWithTime(payment.lineDate)}
                </p>
                <p className="loan_detail_payment_description">
                  {t("LOAN.MISSED_PAYMENT_DATE")}
                </p>
                <p className="loan_detail_payment_amount">
                  ${payment.lineAmount.toFixed(2)}
                </p>
              </div>
              <button
                onClick={seePaymentDetails}
                value={payment.realDate}
                className="payment_details_button"
              >
                {t("LOAN.PAYMENT_DETAILS")}
              </button>
            </div>
          );
        }else if (payment.wasSentToCollections) {
          return (
            <div
              key={payment.lineDate + payment.realDate + payment.lineAmount}
              className="loan_details_history_item"
            >
              <div className="loan_details_history_main_details">
                <p className="loan_detail_payment_date">
                  {getPaymentDateMonthWithTime(payment.lineDate)}
                </p>
                <p className="loan_detail_payment_description">
                  {t("LOAN.COLLECTIONS_PAYMENT")}
                </p>
                <p className="loan_detail_payment_amount">
                  ${payment.lineAmount.toFixed(2)}
                </p>
              </div>
            </div>
          );
        } else {
          return (
            <div key={payment.lineDate} className="loan_details_history_item">
              <div className="loan_details_history_main_details">
                <p className="loan_detail_payment_date">
                  {getPaymentDateMonthWithTime(payment.lineDate)}
                </p>
                <p className="loan_detail_payment_description">
                  {t("LOAN.BALANCE_USED")}
                </p>
                <p className="loan_detail_payment_amount">
                  ${payment.lineAmount.toFixed(2)}
                </p>
              </div>
            </div>
          );
        }
      });
    }
  };

  const renderMissedPayment = () => {
    if (!loanDetails.hasMissedPayment || upcomingPaymentState) {
      return;
    } else {
      return (
        <div className="loan_details_missed_payment_item">
          <div className="loan_details_history_main_details">
            <p className="loan_detail_missed_payment">
              {getPaymentDateMonthWithTime(
                loanDetails.nextPayment === null ||
                  loanDetails.missedPaymentAmount === 0
                  ? loanDetails.payments[0].lineDate
                  : loanDetails.nextPayment
              )}
            </p>
            <p className="loan_detail_missed_payment">
              {loanDetails.missedPaymentAmount === 0
                ? t("LOAN.MISSED_PAYMENT_SHORT")
                : t("LOAN.MISSED_PAYMENT")}
            </p>
            <p className="loan_detail_missed_payment bold">
              +${loanDetails.missedPaymentAmount.toFixed(2)}
            </p>
          </div>
        </div>
      );
    }
  };

  const renderButtonorText = () => {
    if (!loanDetails || Object.keys(loanDetails).length === 0) {
      return;
    } else if (
      !loanDetails.payments &&
      loanDetails.status === "Subloan_Waiting_Approval"
    ) {
      return (
        <div className="loan_approve_message">
          <p>
            {t("LOAN.APPROVED_MESSAGE1")}
            {loanDetails.approvedAmount.toFixed(2)}
            <Trans i18nKey="LOAN.APPROVED_MESSAGE2">
              through Ink Me Financials. You have
              <strong>30 days</strong>
              to begin your tattoo
            </Trans>
          </p>
        </div>
      );
    } else if (loanDetails.status === "Completed" && loanDetails.isPaidOff) {
      return;
    }
  };

  return (
    <MDBox>
      <MDBox display="flex" alignItems="center">
        <MDTypography variant="h6">
          {t("LOAN.INVERITE_AGE_URL")}
          {": "}
        </MDTypography>
        <MDBox pl={2}>
          <a
            className="inverite_link"
            href={
              loanDetails.inveriteAgeUrl ===
              "https://sandbox.inverite.com/merchant/request/view/"
                ? null
                : loanDetails.inveriteAgeUrl
            }
            target="_blank"
            rel="noreferrer"
          >
            <MDTypography variant="body2">
              {loanDetails.inveriteAgeUrl ===
              "https://sandbox.inverite.com/merchant/request/view/"
                ? null
                : loanDetails.inveriteAgeUrl}
            </MDTypography>
          </a>
        </MDBox>
      </MDBox>
      <MDBox display="flex" alignItems="center">
        <MDTypography variant="h6">
          {t("LOAN.INVERITE_RISK_URL")}
          {": "}
        </MDTypography>
        <MDBox pl={2}>
          <a
            className="inverite_link"
            href={
              loanDetails.inveriteRiskUrl ===
              "https://sandbox.inverite.com/merchant/request/view/"
                ? null
                : loanDetails.inveriteRiskUrl
            }
            target="_blank"
            rel="noreferrer"
          >
            <MDTypography variant="body2">
              {loanDetails.inveriteRiskUrl ===
              "https://sandbox.inverite.com/merchant/request/view/"
                ? null
                : loanDetails.inveriteRiskUrl}
            </MDTypography>
          </a>
        </MDBox>
      </MDBox>
      <MDBox display="flex">
        <MDTypography variant="h6">
          {t("LOAN.INVERITE_RISK_SCORE")}
          {": "}
        </MDTypography>
        <MDBox pl={2}>
          <MDTypography variant="body2">
            {loanDetails.riskBinScore}
          </MDTypography>
        </MDBox>
      </MDBox>

      <MDBox display="flex" justifyContent="space-between" py={2}>
        {!loanDetails ||
        Object.keys(loanDetails).length === 0 ||
        !loanDetails.payments ||
        (loanDetails.status === "Completed" && loanDetails.isPaidOff) ? null : (
          <React.Fragment>
            <div className="individual_loan_card_details">
              <p className="client_details_title">
                {t("LOAN.UPCOMING_PAYMENT")}
              </p>
              <p className="client_card_details_values nextPayment">
                {loanDetails.nextPayment === null
                  ? t("LOAN.NO_UPCOMING_PAYMENT")
                  : getPaymentDateMonth(loanDetails.nextPayment)}
              </p>
              <p className="client_details_title">
                {t("LOAN.UPCOMING_PAYMENT_MESSAGE")}
              </p>
            </div>
            <div className="individual_loan_card_details">
              <p className="client_details_title">
                {t("LOAN.NEXT_PAYMENT_AMOUNT")}
              </p>
              <p className="client_card_details_values">
                ${Number(loanDetails.nextPaymentAmount).toFixed(2)}
              </p>
              <p className="client_details_title">
                {t("LOAN.NEXT_PAYMENT_MESSAGE")}
              </p>
            </div>
          </React.Fragment>
        )}
        <MDBox>
          <MDButton color="primary" size="medium" onClick={handleViewContract}>
            {t("LOAN.VIEW_CONTRACT")}
          </MDButton>
        </MDBox>
      </MDBox>
      <div className="individual_loan_details_right">
        <div className="individual_loan_details_header">
          <div className="client_card_status">{renderStatus()}</div>
          <div className="loan_details_header_details">
            <MDBox display="flex" justifyContent="space-between">
              <div className="client_card_details">
                <p className="client_details_title">
                  {loanDetails.status === "Loan_Awaiting_Approval"
                    ? t("LOAN.REQUESTED_AMOUNT")
                    : t("LOAN.APPROVED_AMOUNT")}
                </p>
                <p className="client_card_details_values">
                  {loanDetails.status === "Loan_Awaiting_Approval"
                    ? `$${Number(loanDetails.requestedAmount).toFixed(2)}`
                    : `$${Number(loanDetails.approvedAmount).toFixed(2)}`}
                </p>
                <p className="client_details_date">
                  {loanDetails.status === "Loan_Awaiting_Approval"
                    ? `${t("LOAN.REQUESTED_ON")}
                  ${getDateWithTime(loanDetails.details.created)}`
                    : `${t("LOAN.APPROVED_ON")} ${getDateWithTime(
                        loanDetails.approvedDate
                      )}`}
                </p>
              </div>
              <div className="client_card_details">
                <p className="client_details_title">{t("LOAN.AMOUNT_USED")}</p>
                <p className="client_card_details_values">
                  ${Number(loanDetails.usedAmount).toFixed(2)}
                </p>
              </div>
              <div className="client_card_details">
                <p className="client_details_title">{t("LOAN.FEES")}</p>
                <p className="client_card_details_values">
                  ${Number(loanDetails.totalFees).toFixed(2)}
                </p>
                <p className="client_details_date">{t("LOAN.FEES_MESSAGE")}</p>
              </div>
              <div className="client_card_details">
                <p className="client_details_title">{t("LOAN.LOAN_TERM")}</p>
                <p className="client_card_details_values">
                  {loanDetails.details.term} {t("LOAN.MONTHS")}
                </p>
              </div>
            </MDBox>
            {renderButtonorText()}
          </div>
        </div>
        <div className="loan_details_history_container">
          <div className="loan_details_history_title">
            <h2
              className={
                upcomingPaymentState ? "loan_details_history_tab_inactive" : ""
              }
              onClick={() => dispatch(toggleUpcomingPaymentsState())}
            >
              {t("LOAN.HISTORY")}
            </h2>
            <h2
              onClick={() => dispatch(toggleUpcomingPaymentsState())}
              className={
                upcomingPaymentState ? "" : "loan_details_history_tab_inactive"
              }
            >
              {t("LOAN.UPCOMING_PAYMENTS")}
            </h2>
          </div>
          <div className="client_details_history_content">
            {loanDetails.hasMissedPayment ? renderMissedPayment() : null}
            {upcomingPaymentState ? renderUpcomingPayments() : renderHistory()}
            {togglePaymentDetails ? (
              <PaymentDetailsModal
                togglePaymentDetails={setTogglePaymentDetails}
                paymentDetails={paymentDetails}
              />
            ) : null}
            {viewContractState ? (
              <PDFVIewer togglePDFViewer={setViewContractState} />
            ) : null}
          </div>
        </div>
      </div>
    </MDBox>
  );
};

export default ActiveLoanDetails;
