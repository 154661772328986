/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
// @mui material components
import Card from "@mui/material/Card";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import SuccessModal from "components/SuccessModal/SuccessModal";
import FailModal from "components/FailModal/FailModal";
import FeedbackDetails from "./FeedbackDetails";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "components/DataTable";
import ViewMoreCell from "./ViewMoreCell";

import { getDateWithTime } from "helpers/helpers";

import {
  getFeedbackData,
  toggleFailModalState,
  toggleSuccessModalState,
  resetSuccessMessage,
  resetErrorMessage,
} from "store/feedbackSlice";

function Feedback() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const feedbackData = useSelector((state) => state.feedback.feedback);
  const totalCount = useSelector((state) => state.feedback.totalCount);
  const feedbackDetailsState = useSelector(
    (state) => state.feedback.feedbackDetailsState
  );
  const [filterOption, setFilterOptions] = useState("");
  const successModalState = useSelector(
    (state) => state.feedback.successModalState
  );
  const failModalState = useSelector((state) => state.feedback.failModalState);
  const errorMessage = useSelector((state) => state.feedback.errorMessage);
  const successMessage = useSelector((state) => state.feedback.successMessage);
  const [pageIndex, setPageIndex] = useState(1);
  const pageSize = 10;
  const loading = useSelector((state) => state.feedback.loading);
  const [searchTerms, setSearchTerms] = useState("");
  const [feedback, setFeedback] = useState({});

  const getUserRole = (userRole) => {
    if (userRole === "Customer") {
      return t("TABLE.CUSTOMER");
    } else if (userRole === "Artist") {
      return t("TABLE.ARTIST");
    } else {
      return t("TABLE.CONTACT_US");
    }
  };

  const getSubject = (subject) => {
    let text = "";
    switch (subject) {
      case "Feature_Request":
        text = t("TABLE.FEATURE_REQUEST");
        break;
      case "General":
        text = t("TABLE.GENERAL");
        break;
      case "Bug_Reports":
        text = t("TABLE.BUG_REPORTS");
        break;
      case "Complaint":
        text = t("TABLE.COMPLAINT");
        break;
      case "Help_Request":
        text = t("TABLE.HELP_REQUEST");
        break;
      default:
        text = t("TABLE.CONTACT_US");
    }
    return text;
  };

  useEffect(() => {
    if (feedbackData?.totalCount > 0) {
      setFeedback({
        columns: [
          { Header: t("TABLE.EMAIL"), accessor: "email", width: "15%" },
          { Header: t("TABLE.NAME"), accessor: "user", width: "10%" },
          { Header: t("TABLE.ROLE"), accessor: "userType", width: "10%" },
          {
            Header: t("TABLE.CREATED"),
            accessor: "created",
          },
          { Header: t("TABLE.SUBJECT"), accessor: "subject" },
          {
            Header: t("TABLE.MESSAGE"),
            accessor: "message",
            width: "150px",
          },
          {
            Header: t("TABLE.ANSWERED"),
            accessor: "hasBeenAnswered",
            width: "10%",
          },
          {
            Header: t("TABLE.REPLY"),
            accessor: "viewMore",
          },
        ],
        rows: feedbackData.feedbacks.map((feedback) => {
          return {
            email: <MDBox maxWidth="250px">{feedback.email}</MDBox>,
            user: feedback.user,
            userType: getUserRole(feedback.userType),
            created: getDateWithTime(feedback.created),
            subject: getSubject(feedback.subject),
            message: (
              <MDBox
                display="inline-block"
                maxWidth="200px"
                color="text"
                sx={{
                  verticalAlign: "middle",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {feedback.message}
              </MDBox>
            ),
            hasBeenAnswered: feedback.hasBeenAnswered
              ? t("TABLE.YES")
              : t("TABLE.NO"),
            viewMore: <ViewMoreCell id={feedback.id} />,
          };
        }),
      });
    } else {
      setFeedback({
        columns: [
          { Header: t("TABLE.EMAIL"), accessor: "email" },
          { Header: t("TABLE.NAME"), accessor: "user" },
          {
            Header: t("TABLE.CREATED"),
            accessor: "created",
            Cell: "dateCreated",
          },
          {
            Header: t("TABLE.MESSAGE"),
            accessor: "message",
            Cell: "message",
            width: "150px",
          },
          {
            Header: t("TABLE.ANSWERED"),
            accessor: "hasBeenAnswered",
            Cell: "hasBeenAnswered",
          },
          {
            Header: t("TABLE.REPLY"),
            accessor: "id",
            Cell: "ViewMoreCell",
          },
        ],
        rows: [],
      });
    }
  }, [feedbackData]);

  useEffect(() => {
    let timer1;
    if (successModalState) {
      timer1 = setTimeout(() => {
        dispatch(toggleSuccessModalState());
        dispatch(resetSuccessMessage());
      }, 1500);
    }

    return () => {
      clearTimeout(timer1);
    };
  }, [successModalState]);

  useEffect(() => {
    let timer1;
    if (failModalState) {
      timer1 = setTimeout(() => {
        dispatch(toggleFailModalState());
        dispatch(resetErrorMessage());
      }, 1500);
    }

    return () => {
      clearTimeout(timer1);
    };
  }, [failModalState]);

  useEffect(() => {
    const data = {
      pageIndex,
      pageSize,
      forceSearchTerms: filterOption,
      searchTerms,
    };
    dispatch(getFeedbackData(data));
  }, [filterOption, pageIndex, pageSize, searchTerms]);

  const renderContent = () => {
    if (feedbackDetailsState) {
      return <FeedbackDetails />;
    } else {
      return (
        <>
          <MDBox px={3} py={2} lineHeight={1}>
            <MDTypography variant="h5" fontWeight="medium">
              {t("TABLE.FEEDBACK")}
            </MDTypography>
          </MDBox>
          {!feedback || Object.keys(feedback).length === 0 ? null : (
            <DataTable
              table={feedback}
              totalCount={totalCount}
              filterOption={filterOption}
              canSearch
              setPageIndex={setPageIndex}
              parentPageIndex={pageIndex}
              parentPageSize={pageSize}
              loading={loading}
              setSearchTerms={setSearchTerms}
              searchTerms={searchTerms}
            />
          )}
        </>
      );
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Card>{renderContent()}</Card>
      </MDBox>
      {failModalState ? (
        <FailModal title={t("ERRORS.ERROR")} message={t(errorMessage)} />
      ) : null}
      {successModalState ? (
        <SuccessModal title={t("AUTH.SUCCESS")} message={t(successMessage)} />
      ) : null}
    </DashboardLayout>
  );
}

export default Feedback;
