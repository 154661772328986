import axios from "./api";

export function login(data) {
  return axios({
    url: `/User/SignInAdmin`,
    method: "post",
    data,
  });
}

export function mfaResponse(data) {
  return axios({
    url: `/User/mfaResponse`,
    method: "post",
    data,
  });
}

export function changeTempPassword(data) {
  return axios({
    url: `/Admin/changeTempPassword`,
    method: "post",
    data,
  });
}

export function forgotPassword(data) {
  return axios({
    url: `/User/ForgotPassword`,
    method: "post",
    data,
  });
}

export function resetPassword(data) {
  return axios({
    url: `/User/MfaChangePasswordCodeRequired`,
    method: "post",
    data,
  });
}

export function changePasswordRequestCode(data) {
  return axios({
    url: `/User/changePassword/requestMFAcode`,
    method: "post",
    data,
  });
}

export function changePassword(data) {
  return axios({
    url: `/User/changePassword/useMFAcode`,
    method: "post",
    data,
  });
}

export function getMyProfile() {
  return axios({
    url: `/Admin/myprofile`,
    method: "get",
  });
}
