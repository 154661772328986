import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getLoanTable } from "api/loan";
import { getLoanHistory } from "api/loan";
import { getUcpomingDetails } from "api/loan";
import { getLoanApprovalTable } from "api/loan";

const initialState = {
  loading: false,
  loans: {},
  totalCount: 0,
  pageIndex: 0,
  errorMessage: "",
  loanDetailState: false,
  loanDetails: {},
  successMessage: "",
  successModalState: false,
  failModalState: false,
  upcomingPaymentsState: false,
  targetLoanId: "",
  loanApprovals: {},
  loanApprovalCount: 0,
  floatBelowMin: false,
  loansAreFrozen: false,
  approvalPageIndex: 0,
  approvalLoanDetailState: false,
};

export const getLoanData = createAsyncThunk(
  "loan/getLoanData",
  // eslint-disable-next-line consistent-return
  async (data, thunkAPI) => {
    try {
      const res = await getLoanTable(data);
      return res;
    } catch (error) {
      if (error.response.status === 400) {
        return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
      }
      if (error.response.status === 500) {
        return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
      } else {
        return thunkAPI.rejectWithValue(error.response);
      }
    }
  }
);

export const getLoanApprovals = createAsyncThunk(
  "loan/getLoanApprovals",
  // eslint-disable-next-line consistent-return
  async (data, thunkAPI) => {
    try {
      const res = await getLoanApprovalTable(
        data.sortByDate,
        data.ascendingOrder,
        data.data
      );
      return res;
    } catch (error) {
      if (error.response.status === 400) {
        return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
      }
      if (error.response.status === 500) {
        return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
      } else {
        return thunkAPI.rejectWithValue(error.response);
      }
    }
  }
);

export const getLoanDetails = createAsyncThunk(
  "loan/getLoanDetails",
  // eslint-disable-next-line consistent-return
  async (id, thunkAPI) => {
    try {
      const res = await getLoanHistory(id);
      res.id = id;
      return res;
    } catch (error) {
      if (error.response.data.message === "NO_PENDING_LOANS") {
        return thunkAPI.rejectWithValue("NO_PENDING_LOANS");
      }
      if (error.response.status === 400) {
        return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
      }
      if (error.response.status === 500) {
        return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
      } else {
        return thunkAPI.rejectWithValue(error.response);
      }
    }
  }
);

export const getUpcomingPayments = createAsyncThunk(
  "loan/getUpcomingPayments",
  // eslint-disable-next-line consistent-return
  async (id, thunkAPI) => {
    try {
      const res = await getUcpomingDetails(id);
      res.id = id;
      return res;
    } catch (error) {
      if (error.response.status === 400) {
        return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
      }
      if (error.response.status === 500) {
        return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
      } else {
        return thunkAPI.rejectWithValue(error.response);
      }
    }
  }
);

export const loanSlice = createSlice({
  name: "loan",
  initialState,
  reducers: {
    setPageIndex: (state, action) => {
      state.pageIndex = action.payload;
    },
    setApprovalPageIndex: (state, action) => {
      state.approvalPageIndex = action.payload;
    },
    setLoanDetailState: (state, action) => {
      state.loanDetailState = action.payload;
    },
    toggleSuccessModalState: (state) => {
      state.successModalState = !state.successModalState;
    },
    toggleFailModalState: (state) => {
      state.failModalState = !state.failModalState;
    },
    resetErrorMessage: (state) => {
      state.errorMessage = "";
    },
    resetSuccessMessage: (state) => {
      state.successMessage = "";
    },
    toggleUpcomingPaymentsState: (state) => {
      state.upcomingPaymentsState = !state.upcomingPaymentsState;
    },
    resetLoanState: (state) => {
      state.upcomingPaymentsState = false;
      state.loanDetailState = false;
    },
    setLoanApprovalDetailsState: (state, action) => {
      state.approvalLoanDetailState = action.payload;
    },
    setTargetLoanId: (state, action) => {
      state.targetLoanId = action.payload;
    },
  },
  extraReducers: {
    [getLoanData.pending]: (state) => {
      state.errorMessage = "";
      state.loading = true;
    },
    [getLoanData.rejected]: (state, action) => {
      state.loading = false;
      state.errorMessage = action.payload;
    },
    [getLoanData.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.loans = payload;
      state.totalCount = payload.totalCount;
    },
    [getLoanDetails.pending]: (state) => {
      state.errorMessage = "";
      state.loading = true;
    },
    [getLoanDetails.rejected]: (state, action) => {
      state.loading = false;
      state.errorMessage = action.payload;
    },
    [getLoanDetails.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.loanDetails = payload;
      if (state.approvalLoanDetailState) {
        state.loanDetails.details = state.loanApprovals.loans.filter(
          (loan) => loan.id === payload.id
        )[0];
      } else {
        state.loanDetailState = true;
        state.loanDetails.details = state.loans.loans.filter(
          (loan) => loan.id === payload.id
        )[0];
      }
    },
    [getUpcomingPayments.pending]: (state) => {
      state.errorMessage = "";
      state.loading = true;
    },
    [getUpcomingPayments.rejected]: (state, action) => {
      state.loading = false;
      state.errorMessage = action.payload;
    },
    [getUpcomingPayments.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.loanDetails = payload;
      state.upcomingPaymentsState = true;
      state.loanDetails.details = state.loans.loans.filter(
        (loan) => loan.id === payload.id
      )[0];
    },
    [getLoanApprovals.pending]: (state) => {
      state.errorMessage = "";
      state.loading = true;
    },
    [getLoanApprovals.rejected]: (state, action) => {
      state.loading = false;
      state.errorMessage = action.payload;
      if (action.payload === "NO_PENDING_LOANS") {
        state.loanApprovals = {};
      }
    },
    [getLoanApprovals.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.loanApprovals = payload;
      state.loanApprovalCount = payload.totalCount;
      state.floatBelowMin = payload.floatBelowMin;
      state.loansAreFrozen = payload.loansAreFrozen;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setPageIndex,
  setApprovalPageIndex,
  setLoanDetailState,
  toggleFailModalState,
  toggleSuccessModalState,
  resetErrorMessage,
  resetSuccessMessage,
  setLoanDetails,
  toggleUpcomingPaymentsState,
  resetLoanState,
  setLoanApprovalDetailsState,
  setTargetLoanId,
} = loanSlice.actions;

export default loanSlice.reducer;
