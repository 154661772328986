/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import ErrorModal from "components/ErrorModal/ErrorModal";
import LoanApprovalDetails from "./loanApprovalDetails";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "components/DataTable";
import ViewMoreCell from "./ViewMoreCell";

import { getDateWithTime } from "helpers/helpers";

// Data
import {
  getLoanApprovals,
  setLoanApprovalDetailsState,
} from "../../../store/loanSlice";

function LoanApprovals() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loanApprovalData = useSelector((state) => state.loan.loanApprovals);
  const loanApprovalCount = useSelector(
    (state) => state.loan.loanApprovalCount
  );
  const [orderMenu, setOrderMenu] = useState(null);
  const [sortByMenu, setSortByMenu] = useState(null);
  const [order, setOrder] = useState(true);
  const [sortBy, setSortBy] = useState(true);
  const [filterOption, setFilterOptions] = useState("");
  const floatBelowMin = useSelector((state) => state.loan.floatBelowMin);
  const loansAreFrozen = useSelector((state) => state.loan.loansAreFrozen);
  const approvalLoanDetailState = useSelector(
    (state) => state.loan.approvalLoanDetailState
  );
  const [pageIndex, setPageIndex] = useState(1);
  const pageSize = 10;
  const loading = useSelector((state) => state.loan.loading);
  const [searchTerms, setSearchTerms] = useState("");
  const [loanApprovals, setLoanApprovals] = useState({});

  const getLoanStatus = (status) => {
    let text = "";
    switch (status) {
      case "In Applying Process":
        text = t("LOAN.IN_APPLYING_PROCESS");
        break;
      case "Pending Approval":
        text = t("LOAN.PENDING_APPROVAL");
        break;
      case "Active":
        text = t("LOAN.ACTIVE");
        break;
      case "Tattoo Complete":
        text = t("LOAN.TATTOO_COMPLETE");
        break;
      case "Complete And Paid":
        text = t("LOAN.COMPLETE_PAID");
        break;
      case "Cancelled":
        text = t("LOAN.CANCELLED");
        break;
      case "Denied":
        text = t("LOAN.DENIED");
        break;
      case "Admin Hold":
        text = t("LOAN.ADMIN_HOLD");
        break;
      default:
        text = t("LOAN.IN_COLLECTIONS");
    }
    return text;
  };

  useEffect(() => {
    if (loanApprovalData?.totalCount > 0) {
      setLoanApprovals({
        columns: [
          { Header: t("TABLE.NAME"), accessor: "userName" },
          {
            Header: t("TABLE.DATE"),
            accessor: "created",
          },
          { Header: t("TABLE.ARTIST_NAME"), accessor: "artistName" },
          {
            Header: t("TABLE.LOAN_STATUS"),
            accessor: "loanStatus",
          },
          {
            Header: t("TABLE.REQUESTED_AMOUNT"),
            accessor: "requestedAmount",
          },
          {
            Header: t("TABLE.TERM"),
            accessor: "term",
          },
          {
            Header: t("TABLE.RISK_SCORE"),
            accessor: "riskScore",
          },
          {
            Header: t("TABLE.VIEW_MORE"),
            accessor: "viewMore",
          },
        ],
        rows: loanApprovalData.loans.map((loan) => {
          return {
            userName: loan.userName,
            created: getDateWithTime(loan.created),
            artistName: loan.artistName,
            loanStatus: getLoanStatus(loan.loanStatus),
            requestedAmount: `$ ${loan.requestedAmount.toFixed(2)}`,
            term: loan.term,
            riskScore: loan.riskScore,
            viewMore: <ViewMoreCell id={loan.id} />,
          };
        }),
      });
    } else {
      setLoanApprovals({
        columns: [
          { Header: t("TABLE.NAME"), accessor: "userName" },
          {
            Header: t("TABLE.DATE"),
            accessor: "created",
          },
          { Header: t("TABLE.ARTIST_NAME"), accessor: "artistName" },
          {
            Header: t("TABLE.LOAN_STATUS"),
            accessor: "loanStatus",
          },
          {
            Header: t("TABLE.REQUESTED_AMOUNT"),
            accessor: "requestedAmount",
          },
          {
            Header: t("TABLE.TERM"),
            accessor: "term",
          },
          {
            Header: t("TABLE.RISK_SCORE"),
            accessor: "riskScore",
          },
          {
            Header: t("TABLE.VIEW_MORE"),
            accessor: "viewMore",
          },
        ],
        rows: [],
      });
    }
  }, [loanApprovalData]);

  useEffect(() => {
    const data = {
      sortByDate: sortBy,
      ascendingOrder: order,
      data: {
        pageIndex,
        pageSize,
        forceSearchTerms: filterOption,
        searchTerms,
      },
    };
    dispatch(getLoanApprovals(data));
  }, [filterOption, order, sortBy, pageIndex, searchTerms]);

  const openOrderMenu = (event) => setOrderMenu(event.currentTarget);
  const openSortByMenu = (event) => setSortByMenu(event.currentTarget);
  const closeOrderMenu = (state) => {
    if (state === true || state === false) {
      setOrder(state);
    }
    setOrderMenu(null);
  };

  const closeSortByMenu = (state) => {
    if (state === true || state === false) {
      setSortBy(state);
    }
    setSortByMenu(null);
  };

  const renderOrderMenu = (
    <Menu
      anchorEl={orderMenu}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(orderMenu)}
      onClose={closeOrderMenu}
      keepMounted
    >
      <MenuItem onClick={() => closeOrderMenu(true)}>
        {t("LOAN.FILTER_ASCENDING_ORDER")}
      </MenuItem>
      <MenuItem onClick={() => closeOrderMenu(false)}>
        {t("LOAN.FILTER_DESCENDING_ORDER")}
      </MenuItem>
    </Menu>
  );

  const renderSortByMenu = (
    <Menu
      anchorEl={sortByMenu}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(sortByMenu)}
      onClose={closeSortByMenu}
      keepMounted
    >
      <MenuItem onClick={() => closeSortByMenu(true)}>
        {t("LOAN.FILTER_DATE")}
      </MenuItem>
      <MenuItem onClick={() => closeSortByMenu(false)}>
        {t("LOAN.FILTER_RISK_SCORE")}
      </MenuItem>
    </Menu>
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ErrorModal />
      <MDBox pt={6} pb={3}>
        <MDBox display="flex" justifyContent="flex-end" px={3} pb={1}>
          {loansAreFrozen ? (
            <MDBox border="1px solid #Ff5a5a" borderRadius="6px">
              <MDTypography color="error">
                {t("LOAN.LOANS_FROZEN")}
              </MDTypography>
            </MDBox>
          ) : null}
          {floatBelowMin ? (
            <MDBox border="1px solid #Ff5a5a" borderRadius="6px" p={1}>
              <MDTypography color="error">
                {t("LOAN.FLOAT_BELOW_MIN")}
              </MDTypography>
            </MDBox>
          ) : null}
        </MDBox>
        <Card>
          {approvalLoanDetailState ? (
            <>
              <MDBox
                p={3}
                pb={1}
                lineHeight={1}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <MDBox display="flex" alignItems="center">
                  <MDBox
                    mr={2}
                    onClick={() => dispatch(setLoanApprovalDetailsState(false))}
                  >
                    <Icon>arrow_back</Icon>
                  </MDBox>
                  <MDTypography variant="h5" fontWeight="medium">
                    {t("LOAN.LOAN_APPROVAL_TABLE")}
                  </MDTypography>
                </MDBox>
              </MDBox>
              <LoanApprovalDetails />
            </>
          ) : (
            <>
              <MDBox
                p={3}
                pb={1}
                lineHeight={1}
                display="flex"
                justifyContent="space-between"
              >
                <MDTypography variant="h5" fontWeight="medium">
                  {t("TABLE.LOAN_APPROVALS")}
                </MDTypography>
                <MDBox display="flex">
                  <MDBox textAlign="center">
                    <MDButton
                      variant={sortByMenu ? "contained" : "outlined"}
                      color="dark"
                      onClick={openSortByMenu}
                    >
                      {t("TABLE.SORTBY")}&nbsp;
                      <Icon>keyboard_arrow_down</Icon>
                    </MDButton>

                    {renderSortByMenu}
                    <MDBox>
                      {sortByMenu ? (
                        <MDTypography variant="body2">
                          {t("LOAN.FILTER_DATE")}
                        </MDTypography>
                      ) : (
                        <MDTypography variant="body2">
                          {t("LOAN.FILTER_RISK_SCORE")}
                        </MDTypography>
                      )}
                    </MDBox>
                  </MDBox>
                  <MDBox pl={2} textAlign="center">
                    <MDButton
                      variant={orderMenu ? "contained" : "outlined"}
                      color="dark"
                      onClick={openOrderMenu}
                    >
                      {t("TABLE.SORT_DIRECTION")}&nbsp;
                      <Icon>keyboard_arrow_down</Icon>
                    </MDButton>
                    {renderOrderMenu}
                    {orderMenu ? (
                      <MDTypography variant="body2">
                        {t("LOAN.FILTER_ASCENDING_ORDER")}
                      </MDTypography>
                    ) : (
                      <MDTypography variant="body2">
                        {t("LOAN.FILTER_DESCENDING_ORDER")}
                      </MDTypography>
                    )}
                  </MDBox>
                </MDBox>
              </MDBox>
              {!loanApprovals ||
              Object.keys(loanApprovals).length === 0 ? null : (
                <DataTable
                  table={loanApprovals}
                  totalCount={loanApprovalCount}
                  filterOption={filterOption}
                  canSearch
                  setPageIndex={setPageIndex}
                  parentPageIndex={pageIndex}
                  parentPageSize={pageSize}
                  loading={loading}
                  setSearchTerms={setSearchTerms}
                  searchTerms={searchTerms}
                />
              )}
            </>
          )}
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default LoanApprovals;
