import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import MDBox from "components/MDBox";
import { Icon } from "@mui/material";
import MDTypography from "components/MDTypography";

import { toggleContentDetailState } from "store/settingSlice";
import { htmlDecode } from "helpers/helpers";
import { getDateWithTime } from "helpers/helpers";

const ContentDetails = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const contentDetails = useSelector((state) => state.setting.contentDetail);
  const decodedContent = htmlDecode(contentDetails.htmlContent);

  const getContentType = (name) => {
    if (name === "Privacy_Policy") {
      return t("CONTENT.PRIVACY_POLICY");
    } else if (name === "Terms_of_Service") {
      return t("CONTENT.TERMS_OF_SERVICE");
    } else if (name === "About_Us") {
      return t("CONTENT.ABOUT_US");
    } else if (name === "Rates_Terms") {
      return t("CONTENT.RATES_TERMS");
    } else {
      return name;
    }
  };

  return (
    <MDBox px={2} pb={5}>
      <MDBox py={3} pb={1} lineHeight={1} display="flex" alignItems="center">
        <MDBox
          mr={2}
          onClick={() => {
            dispatch(toggleContentDetailState());
          }}
        >
          <Icon>arrow_back</Icon>
        </MDBox>
        <MDTypography variant="h5" fontWeight="medium">
          {t("CONTENT.CONTENT_TABLE")}
        </MDTypography>
      </MDBox>
      <MDBox px={10}>
        <MDBox p={3}>
          <MDTypography variant="h4" textTransform="uppercase">
            {getContentType(contentDetails.name)}
          </MDTypography>
        </MDBox>
        <MDBox px={3} display="flex" alignItems="center">
          <MDTypography variant="h6">
            {t("TABLE.DATE")}
            {":"}
          </MDTypography>
          <MDBox pl={2}>
            <MDTypography variant="body2">
              {getDateWithTime(contentDetails.created)}
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox px={3} display="flex" alignItems="center">
          <MDTypography variant="h6">
            {t("TABLE.VERSION")}
            {":"}
          </MDTypography>
          <MDBox pl={2}>
            <MDTypography variant="body2">
              {contentDetails.version}
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox px={3} display="flex" alignItems="center">
          <MDTypography variant="h6">
            {t("TABLE.LANGUAGE")}
            {":"}
          </MDTypography>
          <MDBox pl={2}>
            <MDTypography variant="body2">
              {contentDetails.language}
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox
          pt={2}
          pb={5}
          px={2}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <div className="content_creation_container" dangerouslySetInnerHTML={{ __html: decodedContent }}></div>
        </MDBox>
      </MDBox>
    </MDBox>
  );
};

export default ContentDetails;
