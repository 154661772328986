export default function validate(email, password) {
  const errors = {};
  const regexEmail =
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
  if (!email) {
    errors.email = "ERRORS.EMAIL_REQUIRED";
  } else if (!regexEmail.test(email)) {
    errors.email = "ERRORS.VALID_EMAIL";
  }
  if (!password) {
    errors.password = "ERRORS.PASSWORD_REQUIRED";
  }
  return errors;
}
