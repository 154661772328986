import axios from "./api";

export function dashboard() {
  return axios({
    url: `/Reports/dashboard`,
    method: "get",
  });
}

export function howHeard() {
  return axios({
    url: `/Reports/howheard`,
    method: "get",
  });
}

export function monthlyRevenue() {
  return axios({
    url: `/Reports/monthly/revenue`,
    method: "get",
  });
}

export function monthlyLoanApplications() {
  return axios({
    url: `/Reports/monthly/applications`,
    method: "get",
  });
}
