import axios from "axios";
import { logoutUser } from "../layouts/authentication/logout";
// eslint-disable-next-line no-unused-vars
let store;

export const injectStore = (_store) => {
  store = _store;
};

export const api = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
  timeout: 50000,
  headers: {
    "Content-Type": "application/json;",
  },
});

api.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem("access_token");
    if (token) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (res) => res.data,
  async (err) => {
    const originalConfig = err.config;
    if (originalConfig.url !== "User/SignIn" && err.response) {
      // Access Token was expired
      if (
        err.response.status === 401 &&
        (sessionStorage.getItem("isRetry") === "false" ||
          sessionStorage.getItem("isRetry") === null)
      ) {
        sessionStorage.setItem("isRetry", true);
        try {
          const rs = await api.post("/User/RefreshToken", {
            email: sessionStorage.getItem("email"),
            refreshToken: sessionStorage.getItem("refresh_token"),
          });
          const { accessToken, refreshToken } = rs;
          sessionStorage.setItem("access_token", accessToken);
          sessionStorage.setItem("refresh_token", refreshToken);
          sessionStorage.setItem("isRetry", false);
          return api(originalConfig);
        } catch (_error) {
          console.log(_error);
          sessionStorage.setItem("isRetry", false);
          store.dispatch({ type: "LOGOUT" });
          logoutUser();
          window.location.href = `${window.location.origin}/login`;
          return Promise.reject(_error);
        }
      }
    }
    if (sessionStorage.getItem("isRetry") === "true") {
      sessionStorage.setItem("isRetry", false);
      store.dispatch({ type: "LOGOUT" });
      logoutUser();
      window.location.href = `${window.location.origin}/login`;
    }
    return Promise.reject(err);
  }
);

export default api;
