import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import Radio from "@mui/material/Radio";
import { Icon } from "@mui/material";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import MDButton from "components/MDButton";

import validate from "./createAdminValidation";
import MDTypography from "components/MDTypography";
import { toggleCreateNewAdminState } from "store/staffSlice";
import {
  createNewAdmin,
  createNewSuperAdmin,
  toggleAdminDetailsState,
} from "store/staffSlice";
import { useSelector } from "react-redux";
import { toggleUpdateAdminState } from "store/staffSlice";

const CreateAdmin = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState(0);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [role, setRole] = useState("admin");
  const adminDetailsState = useSelector(
    (state) => state.staff.adminDetailsState
  );
  const createNewAdminState = useSelector(
    (state) => state.staff.createNewAdminState
  );
  const updateAdminState = useSelector((state) => state.staff.updateAdminState);

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      const data = {
        firstName,
        lastName,
        email,
        phone,
      };
      if (role === "admin") {
        dispatch(createNewAdmin(data));
      }
      if (role === "superAdmin") {
        dispatch(createNewSuperAdmin(data));
      }
      setIsSubmitting(false);
      setFirstName("");
      setLastName("");
      setEmail("");
      setPhone(0);
      setRole("Admin");
    }
  }, [errors, isSubmitting, firstName, lastName, email, phone, role]);

  const handleRoleChange = (e) => {
    setRole(e.target.value);
  };

  const handleChange = (e) => {
    setIsSubmitting(false);
    if (e.target.name === "firstName") {
      setFirstName(e.target.value);
    }
    if (e.target.name === "lastName") {
      setLastName(e.target.value);
    }
    if (e.target.name === "email") {
      setEmail(e.target.value);
    }
    if (e.target.name === "phone") {
      setPhone(e.target.value);
    }
  };
  const handleSubmit = (e) => {
    if (e) e.preventDefault();
    setIsSubmitting(true);
    setErrors(validate(firstName, lastName, email, phone));
  };
  return (
    <>
      <MDBox p={3} pb={1} lineHeight={1} display="flex" alignItems="center">
        <MDBox
          mr={2}
          onClick={() => {
            if (adminDetailsState) {
              dispatch(toggleAdminDetailsState());
            }
            if (createNewAdminState) {
              dispatch(toggleCreateNewAdminState());
            }
            if (updateAdminState) {
              dispatch(toggleUpdateAdminState());
            }
          }}
        >
          <Icon>arrow_back</Icon>
        </MDBox>
        <MDTypography variant="h5" fontWeight="medium">
          {t("STAFF.STAFF_TABLE")}
        </MDTypography>
      </MDBox>
      <MDBox display="flex" flexDirection="column" alignItems="center">
        <MDBox width={"50%"}>
          {/* <MDBox py={2} px={4}>
            <FormControl>
              <FormLabel
                sx={{
                  fontFamily: "Roboto, sans-serif",
                  fontSize: 14,
                  fontWeight: 400,
                  letterSpacing: 0.13132,
                  lineHeight: "20.12px",
                }}
                id="role-radio-buttons-group"
              >
                {t("STAFF.ADMIN_ROLE")}
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="role-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={role}
                onChange={handleRoleChange}
              >
                <FormControlLabel
                  value="admin"
                  control={<Radio color="primary" />}
                  label={t("STAFF.ADMIN")}
                />
                <FormControlLabel
                  value="superAdmin"
                  control={<Radio color="primary" />}
                  label={t("STAFF.SUPERADMIN")}
                />
              </RadioGroup>
            </FormControl>
          </MDBox> */}
          <MDBox p={2}>
            <MDInput
              type="text"
              name="firstName"
              label={t("TABLE.FIRST_NAME")}
              value={firstName}
              onChange={(e) => handleChange(e)}
              error={!errors ? null : errors.firstName}
              fullWidth
            />
            {!errors ? null : (
              <p className="error_message">{t(errors.firstName)}</p>
            )}
          </MDBox>
          <MDBox p={2}>
            <MDInput
              type="text"
              name="lastName"
              label={t("TABLE.LAST_NAME")}
              value={lastName}
              onChange={(e) => handleChange(e)}
              error={!errors ? null : errors.lastName}
              fullWidth
            />
            {!errors ? null : (
              <p className="error_message">{t(errors.lastName)}</p>
            )}
          </MDBox>
          <MDBox p={2}>
            <MDInput
              type="email"
              name="email"
              label={t("TABLE.EMAIL")}
              value={email}
              onChange={(e) => handleChange(e)}
              error={!errors ? null : errors.email}
              fullWidth
            />
            {!errors ? null : (
              <p className="error_message">{t(errors.email)}</p>
            )}
          </MDBox>
          <MDBox p={2}>
            <MDInput
              type="tel"
              name="phone"
              label={t("TABLE.PHONE")}
              value={phone}
              onChange={(e) => handleChange(e)}
              error={!errors ? null : errors.phone}
              fullWidth
            />
            {!errors ? null : (
              <p className="error_message">{t(errors.phone)}</p>
            )}
          </MDBox>
          <MDBox display="flex" justifyContent="center" p={2}>
            <MDButton color="primary" onClick={handleSubmit}>
              {t("STAFF.CREATE_ADMIN")}
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </>
  );
};

export default CreateAdmin;
