import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

import { changePasswordSubmit } from "store/authSlice";

import passwordValidate from "helpers/passwordValidation";

const UpdatePasswordForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isChangePasswordSubmitting, setIsChangePasswordSubmitting] =
    useState(false);
  const [errors, setErrors] = useState({});
  const signInInfo = useSelector((state) => state.auth.signInInfo);
  const savedEmail = useSelector((state) => state.auth.email);
  const errorMessage = useSelector((state) => state.auth.errorMessage);

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isChangePasswordSubmitting) {
      const data = {
        email: savedEmail,
        newPassword: password,
        newPasswordConfirmation: confirmPassword,
        session: signInInfo.session,
      };
      dispatch(changePasswordSubmit(data));
      setIsChangePasswordSubmitting(false);
      setPassword("");
      setConfirmPassword("");
    }
  }, [
    errors,
    isChangePasswordSubmitting,
    password,
    confirmPassword,
    savedEmail,
    signInInfo,
  ]);

  const handleChange = (e) => {
    setIsChangePasswordSubmitting(false);
    if (e.target.name === "password") {
      setPassword(e.target.value);
    }
    if (e.target.name === "confirmPassword") {
      setConfirmPassword(e.target.value);
    }
  };

  const handleChangePasswordSubmit = (e) => {
    if (e) e.preventDefault();
    setIsChangePasswordSubmitting(true);
    setErrors(passwordValidate(password, confirmPassword));
  };

  return (
    <>
      <MDBox
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
        mx={2}
        mt={-3}
        p={2}
        mb={1}
        textAlign="center"
      >
        <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
          {t("AUTH.CHANGE_PASSWORD")}
        </MDTypography>
      </MDBox>
      <MDBox pt={4} pb={3} px={3}>
        <form onSubmit={handleChangePasswordSubmit}>
          <MDBox mb={2}>
            <MDInput
              type="password"
              name="password"
              label={t("AUTH.PASSWORD")}
              value={password}
              onChange={(e) => handleChange(e)}
              error={!errors ? null : errors.password}
              fullWidth
            />
            {!errors.password ? null : (
              <p className="error_message">{t(errors.password)}</p>
            )}
            {!errors.email ? null : (
              <p className="error_message">{t(errors.email)}</p>
            )}
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              type="password"
              name="confirmPassword"
              label={t("AUTH.CONFIRM_PASSWORD")}
              value={confirmPassword}
              onChange={(e) => handleChange(e)}
              error={!errors ? null : errors.confirmPassword}
              fullWidth
            />
            {!errors.confirmPassword ? null : (
              <p className="error_message">{t(errors.confirmPassword)}</p>
            )}
            {!errorMessage ? null : (
              <p className="error_message">{t(errorMessage)}</p>
            )}
          </MDBox>
          <MDBox mt={4} mb={1}>
            <MDButton
              variant="gradient"
              color="info"
              onClick={handleChangePasswordSubmit}
              fullWidth
            >
              {t("AUTH.SUBMIT")}
            </MDButton>
          </MDBox>
        </form>
      </MDBox>
    </>
  );
};

export default UpdatePasswordForm;
