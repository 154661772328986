/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
// @mui material components
import Divider from "@mui/material/Divider";
import { Icon } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import MDInput from "components/MDInput";
import "../ArtistDetails/artistDetails.css";

import validate from "./artistProfileValidation";
import "./updateProfile.css";
import { updateArtistProfile } from "store/artistSlice";

function UpdateProfile() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const artistDetails = useSelector((state) => state.artist.artistDetails);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [artistName, setArtistName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [profileEmail, setProfileEmail] = useState("");
  const [paymentEmail, setPaymentEmail] = useState("");
  const [studioName, setStudioName] = useState("");
  const [banner, setBanner] = useState({});
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [province, setProvince] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [phone, setPhone] = useState(0);
  const [website, setWebsite] = useState("");
  const [instagram, setInstagram] = useState("");
  const [description, setDescription] = useState("");
  const [tag, setTag] = useState("");
  const [tags, setTags] = useState([]);
  const [portfolioImages, setPortfolioImages] = useState([]);

  useEffect(() => {
    if (!artistDetails) {
    } else {
      setFirstName(artistDetails.firstName);
      setLastName(artistDetails.lastName);
      setProfileEmail(artistDetails.profileEmail);
      setPaymentEmail(artistDetails.paymentEmail);
      setBanner(artistDetails.banner);
      setStudioName(artistDetails.studioName);
      setAddress(artistDetails.street);
      setCity(artistDetails.city);
      setProvince(artistDetails.province);
      setPostalCode(artistDetails.postalCode);
      setPhone(artistDetails.phone);
      setWebsite(artistDetails.website);
      setInstagram(artistDetails.instagram);
      setDescription(
        !artistDetails.description ? "" : artistDetails.description
      );
      setTags(!artistDetails.tags ? [] : artistDetails.tags.split(","));
      setPortfolioImages(artistDetails.artistProfileImages);
      setArtistName(artistDetails.artistName);
    }
  }, [artistDetails]);

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      const data = {
        id: artistDetails.id,
        firstName,
        lastName,
        profileEmail,
        paymentEmail,
        banner,
        studioName,
        street: address,
        city,
        province,
        postalCode,
        phone,
        website,
        instagram,
        description,
        tags: tags.join(","),
        artistProfileImages: portfolioImages,
        artistName,
        status: artistDetails.status,
      };
      dispatch(updateArtistProfile(data));
      setIsSubmitting(false);
      setFirstName("");
      setLastName("");
      setProfileEmail("");
      setPaymentEmail("");
      setBanner({});
      setStudioName("");
      setAddress("");
      setCity("");
      setProvince("");
      setPostalCode("");
      setPhone(0);
      setWebsite("");
      setInstagram("");
      setDescription("");
      setTags("");
      setArtistName("");
      setErrors({});
    }
  }, [
    errors,
    isSubmitting,
    firstName,
    lastName,
    profileEmail,
    paymentEmail,
    studioName,
    banner,
    address,
    city,
    province,
    postalCode,
    phone,
    website,
    instagram,
    description,
    tags,
    artistName,
  ]);

  const handleChange = (e) => {
    setIsSubmitting(false);
    if (e.target.name === "firstName") {
      setFirstName(e.target.value);
    }
    if (e.target.name === "lastName") {
      setLastName(e.target.value);
    }
    if (e.target.name === "profileEmail") {
      setProfileEmail(e.target.value);
    }
    if (e.target.name === "paymentEmail") {
      setPaymentEmail(e.target.value);
    }
    if (e.target.name === "studioName") {
      setStudioName(e.target.value);
    }
    if (e.target.name === "address") {
      setAddress(e.target.value);
    }
    if (e.target.name === "city") {
      setCity(e.target.value);
    }
    if (e.target.name === "postalCode") {
      setPostalCode(e.target.value);
    }
    if (e.target.name === "phone") {
      const input = e.target.value.replace(/\D/g, "").substring(0, 10);
      const areaCode = input.substring(0, 3);
      const middle = input.substring(3, 6);
      const last = input.substring(6, 11);
      let phoneNumber;
      if (input.length > 6) {
        phoneNumber = `(${areaCode})${middle}-${last}`;
      } else if (input.length > 3) {
        phoneNumber = `(${areaCode})${middle}`;
      } else if (input.length > 0) {
        phoneNumber = `(${areaCode}`;
      }
      setPhone(phoneNumber);
    }
    if (e.target.name === "website") {
      setWebsite(e.target.value);
    }
    if (e.target.name === "instagram") {
      setInstagram(e.target.value);
    }
    if (e.target.name === "description") {
      setDescription(e.target.value);
    }
    if (e.target.name === "tag") {
      setTag(e.target.value);
    }
    if (e.target.name === "artistName") {
      setArtistName(e.target.value);
    }
  };

  const handleDeleteBannerImage = () => {
    setBanner({});
  };

  const handleDeleteTag = (e) => {
    const name = e.target.parentNode.getAttribute("name");
    e.preventDefault();
    setTags(tags.filter((tag) => tag !== name));
  };

  const handleAddTag = (e) => {
    let errors = {};
    if (e.key === "Enter" || e.type === "click") {
      if (tags.length === 10) {
        errors.tags = "ERRORS.TAG_LIMIT";
        setErrors(errors);
        return;
      }
      setTags([...tags, tag]);
      setTag("");
      setErrors("");
    }
  };

  const handleDeletePortfolioImage = (e) => {
    e.preventDefault();
    const name = e.target.parentNode.getAttribute("name");
    setPortfolioImages(portfolioImages.filter((image) => image.id !== name));
  };

  const handleProvinceChange = (event: SelectChangeEvent) => {
    setProvince(event.target.value);
  };

  const handleSubmit = (e) => {
    if (e) e.preventDefault();
    setIsSubmitting(true);
    setErrors(
      validate(
        firstName,
        lastName,
        profileEmail,
        paymentEmail,
        phone,
        address,
        city,
        province,
        postalCode,
        artistName,
        website,
        description
      )
    );
  };

  const renderStatus = () => {
    if (artistDetails.status === "Active") {
      return t("ARTISTS.ACTIVE");
    } else if (artistDetails.status === "Email_Not_Verified") {
      return t("ARTISTS.EMAIL_NOT_VERIFIED");
    } else if (artistDetails.status === "Waiting_Verification") {
      return t("ARTISTS.WAITING_VERIFICATION");
    } else if (artistDetails.status === "Declined") {
      return t("ARTISTS.DECLINED");
    } else if (artistDetails.status === "Blocked") {
      return t("ARTISTS.BLOCKED");
    } else return null;
  };

  const renderTags = () => {
    if (!artistDetails) {
      return <div></div>;
    }
    if (!tags) {
      return <div></div>;
    } else {
      return tags.map((tag) => {
        return (
          <MDBox key={tag} m={1}>
            <div className="tag_wrapper">
              <MDBadge badgeContent={tag} size="lg" />
              <div className="delete_tag">
                <MDButton
                  className="delete_tag"
                  iconOnly={true}
                  circular={true}
                  name={tag}
                  value={tag}
                  size="small"
                  color="error"
                  onClick={(e) => handleDeleteTag(e)}
                >
                  <Icon value={tag} size="20">
                    delete
                  </Icon>
                </MDButton>
              </div>
            </div>
          </MDBox>
        );
      });
    }
  };

  const renderImages = () => {
    if (!artistDetails) {
      return <div></div>;
    }
    if (!portfolioImages) {
      return <div></div>;
    } else {
      return portfolioImages.map((image) => {
        return (
          <MDBox key={image.id}>
            {image.url === null ? (
              <div className="portfolio_image_update"></div>
            ) : (
              <div className="portfolio_image_wrapper">
                <img
                  className="portfolio_image_update"
                  src={image.url}
                  alt="portfolio"
                />
                <MDButton
                  className="delete_portfolio_image"
                  iconOnly={true}
                  circular={true}
                  name={image.id}
                  size="medium"
                  color="error"
                  onClick={(e) => handleDeletePortfolioImage(e)}
                >
                  <Icon value={image.id} opticalSize="20">
                    delete
                  </Icon>
                </MDButton>
              </div>
            )}
          </MDBox>
        );
      });
    }
  };

  return (
    <>
      <MDBox m={1}>
        <MDBox px={8} py={3} pb={1}>
          <MDTypography variant="h6" color="primary">
            {t("TABLE.STATUS")}
            {": "}
            {renderStatus()}
          </MDTypography>
        </MDBox>
        <MDBox
          pt={2}
          px={2}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <MDBox p={3} pb={1} width={"40%"}>
            <MDBox component="form" role="form">
              <MDBox p={2}>
                <MDInput
                  type="text"
                  name="artistName"
                  label={t("ARTISTS.ARTIST_NAME")}
                  value={artistName}
                  onChange={(e) => handleChange(e)}
                  error={!errors ? null : errors.artistName}
                  fullWidth
                />
                {!errors ? null : (
                  <p className="error_message">{t(errors.artistName)}</p>
                )}
              </MDBox>
              <MDBox p={2}>
                <MDInput
                  type="text"
                  name="firstName"
                  label={t("TABLE.FIRST_NAME")}
                  value={firstName}
                  onChange={(e) => handleChange(e)}
                  error={!errors ? null : errors.firstName}
                  fullWidth
                />
                {!errors ? null : (
                  <p className="error_message">{t(errors.firstName)}</p>
                )}
              </MDBox>
              <MDBox p={2}>
                <MDInput
                  type="text"
                  name="lastName"
                  label={t("TABLE.LAST_NAME")}
                  value={lastName}
                  onChange={(e) => handleChange(e)}
                  error={!errors ? null : errors.lastName}
                  fullWidth
                />
                {!errors ? null : (
                  <p className="error_message">{t(errors.lastName)}</p>
                )}
              </MDBox>
              <MDBox p={2}>
                <MDInput
                  type="email"
                  name="paymentEmail"
                  label={t("ARTISTS.PAYMENT_EMAIL")}
                  value={paymentEmail}
                  onChange={(e) => handleChange(e)}
                  error={!errors ? null : errors.paymentEmail}
                  fullWidth
                />
                {!errors ? null : (
                  <p className="error_message">{t(errors.paymentEmail)}</p>
                )}
              </MDBox>
            </MDBox>
          </MDBox>
        </MDBox>
        <Divider />
        <MDBox py={3} px={5} pb={1}>
          <MDTypography variant="h5" textTransform="uppercase">
            {t("ARTISTS.PROFILE_DETAILS")}
          </MDTypography>
        </MDBox>
        <MDBox display="flex" justifyContent="center" pb={2}>
          {banner === null || banner.url === null ? (
            <div className="banner_image"></div>
          ) : (
            <div className="banner_wrapper">
              <img
                className="banner_image"
                src={banner.url}
                alt="artist banner"
              />
              <div className="delete_banner">
                <MDButton
                  className="delete_banner"
                  iconOnly={true}
                  circular={true}
                  size="large"
                  color="error"
                  onClick={handleDeleteBannerImage}
                >
                  <Icon fontSize="large">delete</Icon>
                </MDButton>
              </div>
            </div>
          )}
        </MDBox>
        <MDBox display="flex" justifyContent="center">
          <MDBox p={3} pb={1} width={"40%"}>
            <MDBox component="form" role="form">
              <MDBox p={2}>
                <MDInput
                  type="email"
                  name="profileEmail"
                  label={t("ARTISTS.PROFILE_EMAIL")}
                  value={profileEmail}
                  onChange={(e) => handleChange(e)}
                  error={!errors ? null : errors.profileEmail}
                  fullWidth
                />
                {!errors ? null : (
                  <p className="error_message">{t(errors.profileEmail)}</p>
                )}
              </MDBox>
              <MDBox p={2}>
                <MDInput
                  type="text"
                  name="studioName"
                  label={t("TABLE.STUDIO_NAME")}
                  value={studioName}
                  onChange={(e) => handleChange(e)}
                  error={!errors ? null : errors.studioName}
                  fullWidth
                />
                {!errors ? null : (
                  <p className="error_message">{t(errors.studioName)}</p>
                )}
              </MDBox>{" "}
              <MDBox p={2}>
                <MDInput
                  type="text"
                  name="address"
                  label={t("ARTISTS.ADDRESS")}
                  value={address}
                  onChange={(e) => handleChange(e)}
                  error={!errors ? null : errors.address}
                  fullWidth
                />
                {!errors ? null : (
                  <p className="error_message">{t(errors.address)}</p>
                )}
              </MDBox>
              <MDBox p={2}>
                <MDInput
                  type="text"
                  name="city"
                  label={t("ARTISTS.CITY")}
                  value={city}
                  onChange={(e) => handleChange(e)}
                  error={!errors ? null : errors.city}
                  fullWidth
                />
                {!errors ? null : (
                  <p className="error_message">{t(errors.city)}</p>
                )}
              </MDBox>
              <MDBox p={2}>
                <MDBox display="flex">
                  <FormControl fullWidth>
                    <InputLabel id="province-label">
                      {t("ARTISTS.PROVINCE")}
                    </InputLabel>
                    <Select
                      sx={{
                        py: 1.5,
                        px: 0,
                      }}
                      labelId="province-label"
                      id="province"
                      value={province}
                      label={t("ARTISTS.PROVINCE")}
                      onChange={handleProvinceChange}
                    >
                      <MenuItem value={"AB"}>{t("ARTISTS.ALBERTA")}</MenuItem>
                      <MenuItem value={"BC"}>
                        {t("ARTISTS.BRITISH_COLUMBIA")}
                      </MenuItem>
                      <MenuItem value={"MB"}>{t("ARTISTS.MANITOBA")}</MenuItem>
                      <MenuItem value={"NB"}>
                        {t("ARTISTS.NEW_BRUNSWICK")}
                      </MenuItem>
                      <MenuItem value={"NL"}>
                        {t("ARTISTS.NEWFOUNDLAND")}
                      </MenuItem>
                      <MenuItem value={"NS"}>
                        {t("ARTISTS.NOVA_SCOTIA")}
                      </MenuItem>
                      <MenuItem value={"NT"}>
                        {t("ARTISTS.NORTHWEST_TERRITORIES")}
                      </MenuItem>
                      <MenuItem value={"NU"}>{t("ARTISTS.NUNAVUT")}</MenuItem>
                      <MenuItem value={"ON"}>{t("ARTISTS.ONTARIO")}</MenuItem>
                      <MenuItem value={"PE"}>{t("ARTISTS.PEI")}</MenuItem>
                      <MenuItem value={"QC"}>{t("ARTISTS.QUEBEC")}</MenuItem>
                      <MenuItem value={"SK"}>
                        {t("ARTISTS.SASKATCHEWAN")}
                      </MenuItem>
                      <MenuItem value={"YT"}>{t("ARTISTS.YUKON")}</MenuItem>
                    </Select>
                  </FormControl>
                </MDBox>
                {!errors ? null : (
                  <p className="error_message">{t(errors.province)}</p>
                )}
              </MDBox>
              <MDBox p={2}>
                <MDInput
                  type="text"
                  name="postalCode"
                  label={t("ARTISTS.POSTAL_CODE")}
                  value={postalCode}
                  onChange={(e) => handleChange(e)}
                  error={!errors ? null : errors.postalCode}
                  fullWidth
                />
                {!errors ? null : (
                  <p className="error_message">{t(errors.postalCode)}</p>
                )}
              </MDBox>
            </MDBox>
          </MDBox>
          <MDBox p={3} pb={1} width={"40%"}>
            <MDBox p={2}>
              <MDInput
                type="tel"
                name="phone"
                label={t("TABLE.PHONE")}
                value={phone}
                onChange={(e) => handleChange(e)}
                error={!errors ? null : errors.phone}
                fullWidth
              />
              {!errors ? null : (
                <p className="error_message">{t(errors.phone)}</p>
              )}
            </MDBox>
            <MDBox p={2}>
              <MDInput
                type="text"
                name="website"
                label={t("ARTISTS.WEBSITE")}
                value={website}
                onChange={(e) => handleChange(e)}
                error={!errors ? null : errors.website}
                fullWidth
              />
              {!errors ? null : (
                <p className="error_message">{t(errors.website)}</p>
              )}
            </MDBox>
            <MDBox p={2}>
              <MDInput
                type="text"
                name="instagram"
                label={t("ARTISTS.INSTAGRAM")}
                value={instagram}
                onChange={(e) => handleChange(e)}
                error={!errors ? null : errors.instagram}
                fullWidth
              />
              {!errors ? null : (
                <p className="error_message">{t(errors.winstagram)}</p>
              )}
            </MDBox>
            <MDBox p={2}>
              <MDInput
                type="text"
                name="description"
                label={t("ARTISTS.DESCRIPTION")}
                value={description}
                onChange={(e) => handleChange(e)}
                error={!errors ? null : errors.description}
                multiline
                inputProps={{ maxlength: "500" }}
                rows={10}
                fullWidth
              />
              <MDBox display="flex" justifyContent="flex-end">
                <MDTypography variant="caption">{`${
                  !description ? 0 : description.length
                }/500`}</MDTypography>
              </MDBox>
              {!errors ? null : (
                <p className="error_message">{t(errors.description)}</p>
              )}
            </MDBox>
          </MDBox>
        </MDBox>
        <MDBox display="flex" flexDirection="column" alignItems="center">
          <MDBox display="flex" alignItems="center">
            <MDTypography variant="h6">
              {t("ARTISTS.TAGS")}
              {": "}
            </MDTypography>
            <MDBox display="flex" alignItems="center">
              {renderTags()}
            </MDBox>
            <MDBox p={2} ml={8} display="flex" alignItems="center">
              <MDBox display="flex" flexDirection="column">
                <MDInput
                  type="text"
                  name="tag"
                  label={t("ARTISTS.ADD_TAG")}
                  value={tag}
                  onChange={(e) => handleChange(e)}
                  error={!errors ? null : errors.tags}
                  fullWidth
                />
                {!errors ? null : (
                  <p className="error_message">{t(errors.tags)}</p>
                )}
              </MDBox>
              <MDBox ml={2} width={"200px"}>
                <MDButton color="primary" size="small" onClick={handleAddTag}>
                  {t("ARTISTS.ADD_TAG")}
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
          <MDBox
            display="flex"
            flexDirection="column"
            alignItmes="center"
            alignSelf="flex-start"
            m={2}
            ml={8}
          >
            <MDBox p={2}>
              <MDTypography variant="h6">
                {t("ARTISTS.PORTFOLIO_IMAGES")}
                {": "}
              </MDTypography>
            </MDBox>
            <MDBox display="flex">{renderImages()}</MDBox>
          </MDBox>
        </MDBox>
        <MDBox display="flex" justifyContent="center" m={3} mb={4}>
          <MDButton color="primary" size="large" onClick={handleSubmit}>
            {t("ARTISTS.SAVE_CHANGES")}
          </MDButton>
        </MDBox>
      </MDBox>
    </>
  );
}

export default UpdateProfile;
