import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";

import Icon from "@mui/material/Icon";
import { Card } from "@mui/material";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import { useTranslation } from "react-i18next";
import { updateContract } from "api/settings";

function Contract({ onClose }) {
  const { t } = useTranslation();
  const [selectedFile, setSelectedFile] = useState();
  const [error, setError] = useState();

  const handleChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleUpdate = async () => {
    if (!selectedFile) {
      setError("ERRORS.UPLOAD_FILE");
    } else {
      setError("");
      const formData = new FormData();
      formData.append("File", selectedFile);
      try {
        const res = await updateContract(formData);
        toast.success(t("TOAST.CONTRACT_UPDATED_SUCCESSFULLY"));
        onClose();
      } catch (e) {
        console.log(e.response);
        toast.error(e.response);
      }
    }
  };

  return (
    <>
      <Card>
        <MDBox p={2} pb={5}>
          <MDBox display="flex" alignItems="center">
            <MDBox mr={2} onClick={() => onClose()}>
              <Icon>arrow_back</Icon>
            </MDBox>
            <MDTypography variant="h5" fontWeight="medium">
              {t("CONTENT.UPDATE_CONTRACT")}
            </MDTypography>
          </MDBox>
          <MDBox p={2} display="flex" justifyContent="center" width="100%">
            <form>
              <MDBox
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                pt={3}
              >
                <MDInput type="file" color="primary" onChange={handleChange} />
                {!error ? null : <p className="error_message">{t(error)}</p>}
                <MDBox pt={3}>
                  <MDButton
                    onClick={handleUpdate}
                    color="primary"
                    size="medium"
                    className="btn-round"
                  >
                    {t("CONTENT.SAVE")}
                  </MDButton>
                </MDBox>
              </MDBox>
            </form>
          </MDBox>
        </MDBox>
      </Card>
    </>
  );
}

export default Contract;
