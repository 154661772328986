import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { SignatureComponent, Signature } from "@syncfusion/ej2-react-inputs";
import {
  PdfViewerComponent,
  Toolbar,
  Magnification,
  Navigation,
  LinkAnnotation,
  BookmarkView,
  ThumbnailView,
  Print,
  Annotation,
  TextSelection,
  TextSearch,
  Inject,
  FormDesigner,
  FormFields,
} from "@syncfusion/ej2-react-pdfviewer";
import ModalContainer from "components/ModalContainer/ModalContainer";

import { getPreviewContract } from "../../../../api/settings";

import "./PDFViewer.css";

const PDFViewer = ({ togglePDFViewer }) => {
  const { t } = useTranslation();
  const viewerRef = useRef(null);
  let signObj: Signature = new Signature(null, "#signature");
  const viewerCreatedHandler = async () => {
    try {
      const res = await getPreviewContract();
      viewerRef.current.load(`data:application/pdf;base64, ${res}`, null);
      viewerRef.current.fileName = "contract.pdf";
    } catch (error) {
      console.log(error);
    }
  };

  const OnClear = () => {
    signObj?.clear();
  };

  return (
    <div>
      <ModalContainer
        title={t("CONTENT.VIEW_CONTRACT")}
        onButtonClose={() => togglePDFViewer(false)}
      >
        <div className="contract_container">
          <div className="control-section">
            <PdfViewerComponent
              id="container"
              ref={viewerRef}
              serviceUrl={`${process.env.REACT_APP_BACKEND_BASE_URL}/pdfviewer`}
              created={viewerCreatedHandler}
              showNotificationDialog={false}
              style={{ height: "640px" }}
              toolbarSettings={{
                showTooltip: true,
                toolbarItems: [
                  "OpenOption",
                  "PageNavigationTool",
                  "MagnificationTool",
                  "PanTool",
                  "SelectionTool",
                  "SignatureOption",
                  "SearchOption",
                  "DownloadOption",
                ],
                annotationToolbarItems: [
                  "HighlightTool",
                  "UnderlineTool",
                  "StrikethroughTool",
                  "ColorEditTool",
                  "OpacityEditTool",
                  "AnnotationDeleteTool",
                  "StampAnnotationTool",
                  "HandWrittenSignatureTool",
                  "InkAnnotationTool",
                  "ShapeTool",
                  "CalibrateTool",
                  "StrokeColorEditTool",
                  "ThicknessEditTool",
                  "FreeTextAnnotationTool",
                  "FontFamilyAnnotationTool",
                  "FontSizeAnnotationTool",
                  "FontStylesAnnotationTool",
                  "FontAlignAnnotationTool",
                  "FontColorAnnotationTool",
                  "CommentPanelTool",
                ],
                formDesignerToolbarItems: [
                  "TextboxTool",
                  "PasswordTool",
                  "CheckBoxTool",
                  "RadioButtonTool",
                  "DropdownTool",
                  "ListboxTool",
                  "DrawSignatureTool",
                  "DeleteTool",
                ],
              }}
            >
              <Inject
                services={[
                  Toolbar,
                  Magnification,
                  Annotation,
                  Navigation,
                  LinkAnnotation,
                  BookmarkView,
                  ThumbnailView,
                  Print,
                  TextSelection,
                  TextSearch,
                  FormFields,
                  FormDesigner,
                ]}
              />
            </PdfViewerComponent>
            <div className="accept_contract_container">
              <div className="contract_signature_title">
                <h4>Sign here:</h4>
                <button onClick={OnClear}>Clear</button>
              </div>
              <SignatureComponent
                ref={(sign) => (signObj = sign)}
                style={{ maxWidth: "350px", height: "100px" }}
              ></SignatureComponent>
            </div>
          </div>
        </div>
      </ModalContainer>
    </div>
  );
};

export default PDFViewer;
