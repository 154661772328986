import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import { Icon } from "@mui/material";
import MDButton from "components/MDButton";

import MDTypography from "components/MDTypography";

import { toggleFeedbackDetailsState } from "store/feedbackSlice";

import { getDateWithTime } from "helpers/helpers";
import { replyToUserFeedback } from "store/feedbackSlice";
import { markFeedbackAsAnswered } from "store/feedbackSlice";

const FeedbackDetails = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const feedbackDetails = useSelector(
    (state) => state.feedback.feedbackDetails
  );

  const getUserRole = (userRole) => {
    if (userRole === "Customer") {
      return t("TABLE.CUSTOMER");
    } else if (userRole === "Artist") {
      return t("TABLE.ARTIST");
    } else {
      return t("TABLE.CONTACT_US");
    }
  };

  const getSubject = (subject) => {
    let text = "";
    switch (subject) {
      case "Feature_Request":
        text = t("TABLE.FEATURE_REQUEST");
        break;
      case "General":
        text = t("TABLE.GENERAL");
        break;
      case "Bug_Reports":
        text = t("TABLE.BUG_REPORTS");
        break;
      case "Complaint":
        text = t("TABLE.COMPLAINT");
        break;
      case "Help_Request":
        text = t("TABLE.HELP_REQUEST");
        break;
      default:
        text = t("TABLE.CONTACT_US");
    }
    return text;
  };

  useEffect(() => {
    if (!feedbackDetails) {
    } else {
      setName(feedbackDetails.user);
      setEmail(feedbackDetails.email);
    }
  }, [feedbackDetails]);

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      const data = {
        feedbackId: feedbackDetails.id,
        userEmail: email,
        userName: name,
        message,
      };
      dispatch(replyToUserFeedback(data));
      setIsSubmitting(false);
      setName("");
      setEmail("");
      setMessage("");
    }
  }, [errors, isSubmitting, email, name, message, feedbackDetails.id]);

  const handleChange = (e) => {
    setIsSubmitting(false);
    if (e.target.name === "name") {
      setName(e.target.value);
    }
    if (e.target.name === "email") {
      setEmail(e.target.value);
    }
    if (e.target.name === "message") {
      setMessage(e.target.value);
    }
  };

  const handleMarkAnswered = (e) => {
    e.preventDefault();
    dispatch(markFeedbackAsAnswered(feedbackDetails.id));
  };

  const handleReply = (e) => {
    if (e) e.preventDefault();
    setIsSubmitting(true);
    setErrors({});
    let errorsSet = {};
    const regexEmail =
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
    if (!email) {
      errorsSet.email = "ERRORS.EAMIL_REQUIRED";
    } else if (!regexEmail.test(email)) {
      errorsSet.email = "ERRORS.VALID_EMAIL";
    }
    if (!name) {
      errorsSet.name = "ERRORS.NAME_REQUIRED";
    }
    if (!message) {
      errorsSet.message = "ERRORS.MESSAGE_REQUIRED";
    }
    setErrors(errorsSet);
  };

  return (
    <>
      <MDBox p={3} pb={1} lineHeight={1} display="flex" alignItems="center">
        <MDBox
          mr={2}
          onClick={() => {
            dispatch(toggleFeedbackDetailsState());
          }}
        >
          <Icon>arrow_back</Icon>
        </MDBox>
        <MDTypography variant="h5" fontWeight="medium">
          {t("TABLE.FEEDBACK_TABLE")}
        </MDTypography>
      </MDBox>
      <MDBox display="flex" flexDirection="column" alignItems="flex-end" px={4}>
        <MDButton color="primary" onClick={handleMarkAnswered}>
          {t("TABLE.MARK_ANSWERED")}
        </MDButton>
        <MDBox display="flex" pt={2}>
          <MDTypography variant="h6">
            {t("TABLE.ANSWERED")}
            {": "}
          </MDTypography>
          <MDTypography variant="body2" pl={2}>
            {feedbackDetails.hasBeenAnswered ? "Yes" : "No"}
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox display="flex" flexDirection="column" alignItems="center" p={2}>
        <MDBox
          m={3}
          p={3}
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          width={"70%"}
          bgColor="light"
          borderRadius="6px"
        >
          <MDTypography variant="h6">
            {`${getDateWithTime(feedbackDetails.created)}`}
          </MDTypography>
          <MDBox display="flex">
            <MDTypography variant="h6">
              {t("TABLE.EMAIL")}
              {": "}
            </MDTypography>
            <MDTypography variant="body2" pl={2}>
              {feedbackDetails.email}
            </MDTypography>
          </MDBox>
          <MDBox display="flex">
            <MDTypography variant="h6">
              {t("TABLE.NAME")}
              {": "}
            </MDTypography>
            <MDTypography variant="body2" pl={2}>
              {feedbackDetails.user}
            </MDTypography>
          </MDBox>
          <MDBox display="flex">
            <MDTypography variant="h6">
              {t("TABLE.ROLE")}
              {": "}
            </MDTypography>
            <MDTypography variant="body2" pl={2}>
              {getUserRole(feedbackDetails.userType)}
            </MDTypography>
          </MDBox>
          <MDBox display="flex">
            <MDTypography variant="h6">
              {t("TABLE.SUBJECT")}
              {": "}
            </MDTypography>
            <MDTypography variant="body2" pl={2}>
              {getSubject(feedbackDetails.subject)}
            </MDTypography>
          </MDBox>
          <MDBox display="flex" flexDirection="column">
            <MDTypography variant="h6">
              {t("TABLE.MESSAGE")}
              {": "}
            </MDTypography>
            <MDTypography variant="body2" pl={2}>
              {feedbackDetails.message}
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox width={"50%"}>
          <MDBox p={2}>
            <MDInput
              type="text"
              name="name"
              label={t("TABLE.NAME")}
              value={name}
              onChange={(e) => handleChange(e)}
              error={!errors ? null : errors.name}
              fullWidth
            />
            {!errors ? null : <p className="error_message">{t(errors.name)}</p>}
          </MDBox>
          <MDBox p={2}>
            <MDInput
              type="email"
              name="email"
              label={t("TABLE.EMAIL")}
              value={email}
              onChange={(e) => handleChange(e)}
              error={!errors ? null : errors.email}
              fullWidth
            />
            {!errors ? null : (
              <p className="error_message">{t(errors.email)}</p>
            )}
          </MDBox>
          <MDBox p={2}>
            <MDInput
              type="text"
              name="message"
              label={t("TABLE.MESSAGE")}
              value={message}
              onChange={(e) => handleChange(e)}
              error={!errors ? null : errors.message}
              multiline
              rows={10}
              fullWidth
            />
            {!errors ? null : (
              <p className="error_message">{t(errors.message)}</p>
            )}
          </MDBox>
          <MDBox display="flex" justifyContent="center" p={2}>
            <MDButton color="primary" onClick={handleReply}>
              {t("TABLE.REPLY")}
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </>
  );
};

export default FeedbackDetails;
