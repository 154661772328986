import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
// react-router-dom components
// import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import { Icon } from "@mui/material";
// import Switch from "@mui/material/Switch";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import ErrorModal from "components/ErrorModal/ErrorModal";
import SuccessModal from "components/SuccessModal/SuccessModal";
import FailModal from "components/FailModal/FailModal";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// Images
import bgImage from "assets/images/ink_me/background_eye.png";
import passwordValidate from "helpers/passwordValidation";

import {
  changePasswordRequestCodeSubmit,
  changePasswordCodeSubmit,
  toggleSuccessModalState,
  toggleFailModalState,
  resetErrorMessage,
} from "store/authSlice";
import { toggleResetPasswordCodePageState } from "store/authSlice";

function ResetPassword() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({});
  const resetPasswordCodePageState = useSelector(
    (state) => state.auth.resetPasswordCodePageState
  );
  const loginErrorState = useSelector((state) => state.auth.loginError);
  const errorMessage = useSelector((state) => state.auth.errorMessage);
  const [smsCode, setSMSCode] = useState("");
  const [isSMSSubmitting, setIsSMSSubmitting] = useState(false);
  const signInInfo = useSelector((state) => state.auth.signInInfo);
  const failModalState = useSelector((state) => state.auth.failModalState);
  const successModalState = useSelector(
    (state) => state.auth.successModalState
  );

  useEffect(() => {
    let timer1;
    if (successModalState) {
      timer1 = setTimeout(() => {
        dispatch(toggleSuccessModalState());
      }, 1500);
    }

    return () => {
      clearTimeout(timer1);
    };
  }, [successModalState]);

  useEffect(() => {
    let timer1;
    if (failModalState) {
      timer1 = setTimeout(() => {
        dispatch(toggleFailModalState());
        dispatch(resetErrorMessage());
      }, 1500);
    }

    return () => {
      clearTimeout(timer1);
    };
  }, [failModalState]);

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSMSSubmitting) {
      const data = {
        newPassword,
        newPasswordConfirmation: confirmPassword,
        code: smsCode,
        session: signInInfo.parameters.SESSION,
      };
      dispatch(changePasswordCodeSubmit(data));
      setIsSMSSubmitting(false);
      setSMSCode("");
      setPassword("");
      setConfirmPassword("");
      setNewPassword("");
    }
  }, [
    errors,
    isSMSSubmitting,
    smsCode,
    password,
    newPassword,
    confirmPassword,
  ]);

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      const data = {
        email: sessionStorage.getItem("email"),
        password,
      };
      dispatch(changePasswordRequestCodeSubmit(data));
      setIsSubmitting(false);
    }
  }, [errors, isSubmitting, password]);

  const handleChange = (e) => {
    setIsSubmitting(false);
    setIsSMSSubmitting(false);
    if (e.target.name === "password") {
      setPassword(e.target.value);
    }
    if (e.target.name === "newPassword") {
      setNewPassword(e.target.value);
    }
    if (e.target.name === "confirmPassword") {
      setConfirmPassword(e.target.value);
    }

    if (e.target.name === "smsCode") {
      setSMSCode(e.target.value);
    }
  };

  const handleSMSCodeSubmit = (e) => {
    if (e) e.preventDefault();
    setErrors({});
    let errorsCode = {};
    errorsCode = passwordValidate(newPassword, confirmPassword);
    if (!smsCode) {
      errorsCode.smsCode = "ERRORS.CODE_REQUIRED";
    }
    setErrors(errorsCode);
    setIsSMSSubmitting(true);
  };

  const handleSubmit = (e) => {
    if (e) e.preventDefault();
    setIsSubmitting(true);
    setErrors({});
    const errorsCode = {};
    if (!password) {
      errorsCode.password = "ERRORS.PASSWORD_REQUIRED";
      setErrors(errorsCode);
    }
  };

  const renderForms = () => {
    if (resetPasswordCodePageState) {
      return (
        <>
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            mx={2}
            mt={-3}
            p={2}
            mb={1}
            textAlign="center"
          >
            <MDBox display="flex" justifyContent="flex-end">
              <MDButton
                iconOnly
                color="light"
                onClick={() => dispatch(toggleResetPasswordCodePageState())}
              >
                <Icon>close</Icon>
              </MDButton>
            </MDBox>
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              {t("AUTH.SMS_CODE")}
            </MDTypography>
          </MDBox>
          <MDBox pt={4} pb={3} px={3}>
            <MDBox mb={2}>
              <MDTypography
                variant="body2"
                fontWeight="regular"
                color="text"
                mb={3}
              >
                {!signInInfo.codeDeliveryDestination
                  ? t("AUTH.SMS_CODE_MESSAGE_NO_PHONE")
                  : t("AUTH.SMS_CODE_MESSAGE", {
                      phone: signInInfo.codeDeliveryDestination,
                    })}
              </MDTypography>
              <form onSubmit={handleSMSCodeSubmit}>
                <MDBox mb={2}>
                  <MDInput
                    type="number"
                    name="smsCode"
                    label={t("AUTH.CODE")}
                    value={smsCode}
                    onChange={(e) => handleChange(e)}
                    error={!errors ? null : errors.smsCode}
                    fullWidth
                  />
                  {!errors.smsCode ? null : (
                    <p className="error_message">{t(errors.smsCode)}</p>
                  )}
                  {!errorMessage ? null : (
                    <p className="error_message">{t(errorMessage)}</p>
                  )}
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    type="password"
                    name="newPassword"
                    label={t("AUTH.NEW_PASSWORD")}
                    value={newPassword}
                    onChange={(e) => handleChange(e)}
                    error={!errors ? null : errors.newPassword}
                    fullWidth
                  />
                  {!errors.newPassword ? null : (
                    <p className="error_message">{t(errors.newPassword)}</p>
                  )}
                  {!errorMessage ? null : (
                    <p className="error_message">{t(errorMessage)}</p>
                  )}
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    type="password"
                    name="confirmPassword"
                    label={t("AUTH.CONFIRM_NEW_PASSWORD")}
                    value={confirmPassword}
                    onChange={(e) => handleChange(e)}
                    error={!errors ? null : errors.confirmPassword}
                    fullWidth
                  />
                  {!errors.confirmPassword ? null : (
                    <p className="error_message">{t(errors.confirmPassword)}</p>
                  )}
                  {!errorMessage ? null : (
                    <p className="error_message">{t(errorMessage)}</p>
                  )}
                </MDBox>
                <MDBox mt={4} mb={1}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    onClick={handleSMSCodeSubmit}
                    fullWidth
                  >
                    {t("AUTH.CHANGE_PASSWORD")}
                  </MDButton>
                </MDBox>
              </form>
            </MDBox>
          </MDBox>
        </>
      );
    }
    return (
      <>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            {t("AUTH.CHANGE_PASSWORD")}
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <form onSubmit={handleSubmit}>
            <MDBox mb={2}>
              <MDInput
                type="password"
                name="password"
                label={t("AUTH.CURRENT_PASSWORD")}
                value={password}
                onChange={(e) => handleChange(e)}
                onKeyDown={(e) => handleChange(e)}
                error={!errors ? null : errors.password}
                fullWidth
              />
              {!errors.password ? null : (
                <p className="error_message">{t(errors.password)}</p>
              )}
            </MDBox>

            <MDBox mt={4} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                onClick={handleSubmit}
                fullWidth
              >
                {t("AUTH.CHANGE_PASSWORD")}
              </MDButton>
            </MDBox>
          </form>
        </MDBox>
      </>
    );
  };

  return (
    <DashboardLayout image={bgImage}>
      <DashboardNavbar />
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        mt={"10%"}
      >
        <MDBox width={"50%"}>
          <Card>{renderForms()} </Card>
        </MDBox>
      </MDBox>
      {loginErrorState ? <ErrorModal /> : null}
      {failModalState ? (
        <FailModal title={t("ERRORS.ERROR")} message={t(errorMessage)} />
      ) : null}
      {successModalState ? (
        <SuccessModal
          title={t("AUTH.SUCCESS")}
          message={t("AUTH.SUCCESS_CHANGE_PASSWORD")}
        />
      ) : null}
    </DashboardLayout>
  );
}

export default ResetPassword;
