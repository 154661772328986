/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useMemo, useEffect, useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-table components
import {
	useTable,
	usePagination,
	useGlobalFilter,
	useAsyncDebounce,
	useSortBy,
} from "react-table";

// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDPagination from "components/MDPagination";

// Material Dashboard 2 PRO React examples
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";
import { getArtist } from "api/artist";
import { useTranslation } from "react-i18next";
import { Grid, CircularProgress, Card, Button } from "@mui/material";

// pass in some dispatch actions to fetch data
function DataTable({
	entriesPerPage,
	canSearch,
	showTotalEntries,
	table,
	pagination,
	isSorted,
	noEndBorder,
	totalCount,
	setPageIndex,
	parentPageIndex,
	parentPageSize,
	loading = false,
	setSearchTerms,
	searchTerms,
}) {
	const [changed, setChanged] = useState(0);
	const { t } = useTranslation();
	const defaultValue = parentPageSize
		? parentPageSize
		: entriesPerPage.defaultValue
		? entriesPerPage.defaultValue
		: 10;
	const entries = entriesPerPage.entries
		? entriesPerPage.entries.map((el) => el.toString())
		: ["5", "10", "15", "20", "25"];
	const columns = useMemo(() => table.columns, [table]);
	const data = useMemo(() => table.rows, [table]);

	const tableInstance = useTable(
		{
			columns,
			data,
			initialState: { pageIndex: parentPageIndex ? parentPageIndex - 1 : 0 },
			manualPagination: true,
			pageCount: Math.ceil(totalCount / defaultValue),
		},
		useGlobalFilter,
		useSortBy,
		usePagination,
	);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		rows,
		page,
		pageOptions,
		canPreviousPage,
		canNextPage,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		setGlobalFilter,
		state: { pageIndex, pageSize, globalFilter },
	} = tableInstance;

	// pageIndex starts at 0
	// Set the default value for the entries per page when component mounts
	useEffect(() => setPageSize(defaultValue || 10), [defaultValue]);

	useEffect(() => {
		console.log('parentpageIndex118');
		gotoPage(parentPageIndex-1);
	}, [parentPageIndex]);

	// Set the entries per page value based on the select value
	const setEntriesPerPage = (value) => setPageSize(value);

	// Render the paginations
	const renderPagination = pageOptions.map((option) => {
		return (
			<MDPagination
				item
				key={option}
				onClick={() => {
					gotoPage(Number(option));
					setChanged(changed + 1);
					console.log("go to page: option", option);
				}}
				active={pageIndex === option}
			>
				{option + 1}
			</MDPagination>
		);
	});

	// Handler for the input to set the pagination index
	const handleInputPagination = ({ target: { value } }) => {
		console.log("handleInputPagination");
		return value > pageOptions.length || value < 0
			? gotoPage(0)
			: (gotoPage(Number(value)), setChanged(changed + 1));
	};

	// Customized page options starting from 1
	const customizedPageOptions = pageOptions.map((option) => option + 1);

	// Setting value for the pagination input
	const handleInputPaginationValue = ({ target: value }) => {
		console.log("handleInputPaginationValue");
		return gotoPage(Number(value.value - 1)), setChanged(changed + 1);
	};

	// Search input value state
	const [search, setSearch] = useState(globalFilter);

	// Search input state handle
	const onSearchChange = useAsyncDebounce((value) => {
		setGlobalFilter(value || undefined);
	}, 100);

	// A function that sets the sorted value for the table
	const setSortedValue = (column) => {
		let sortedValue;

		if (isSorted && column.isSorted) {
			sortedValue = column.isSortedDesc ? "desc" : "asce";
		} else if (isSorted) {
			sortedValue = "none";
		} else {
			sortedValue = false;
		}

		return sortedValue;
	};

	// Setting the entries starting point
	const entriesStart =
		pageIndex === 0 ? pageIndex + 1 : pageIndex * pageSize + 1;

	// Setting the entries ending point
	let entriesEnd;

	if (pageIndex === 0) {
		entriesEnd = totalCount < pageSize ? totalCount : pageSize;
	} else if (pageIndex === pageOptions.length - 1) {
		// here, original logic gives an bug
		// for example, this table has 220 entries
		// when it got the last page, and page size is 10
		// it should say, Showing 211 to 220 of 220 entries
		// but the bug is, Showing 211 to 10 of 220 entries.
		// so, change the following line of code to total count
		entriesEnd = totalCount;
	} else {
		entriesEnd = pageSize * (pageIndex + 1);
	}

	console.log("pageIndex", pageIndex, parentPageIndex);

	useEffect(() => {
		console.log("set page index from children", pageIndex, parentPageIndex);
		setPageIndex(pageIndex + 1);
	}, [changed]);

	return (
		<TableContainer sx={{ boxShadow: "none" }}>
			{entriesPerPage || canSearch ? (
				<MDBox
					display="flex"
					direction="row"
					justifyContent="flex-start"
					alignItems="center"
					p={3}
				>
					{/* {entriesPerPage && (
						<MDBox display="flex" alignItems="center">
							<Autocomplete
								disableClearable
								value={pageSize.toString()}
								options={entries}
								onChange={(event, newValue) => {
									setEntriesPerPage(parseInt(newValue, 10));
								}}
								size="small"
								sx={{ width: "5rem" }}
								renderInput={(params) => <MDInput {...params} />}
							/>
							<MDTypography variant="caption" color="secondary">
								&nbsp;&nbsp;entries per page
							</MDTypography>
						</MDBox>
					)} */}
					{canSearch && (
						<MDBox width="12rem" ml="auto" style={{ marginLeft: 0 }}>
							<MDInput
								placeholder="Search..."
								value={searchTerms}
								size="small"
								fullWidth
								onChange={({ currentTarget }) => {
									// setSearch(search);
									// onSearchChange(currentTarget.value);
									setSearchTerms(currentTarget.value)
									setPageIndex(1)
									console.log("currentTarget.value", currentTarget.value);
								}}
							/>
						</MDBox>
					)}
				</MDBox>
			) : null}
			{loading ? (
				<Grid container direction="row" justifyContent="center" alignItems="center">
					<CircularProgress />
				</Grid>
			) : (
				<>
					<Table {...getTableProps()}>
						<MDBox component="thead">
							{headerGroups.map((headerGroup) => (
								<TableRow {...headerGroup.getHeaderGroupProps()}>
									{headerGroup.headers.map((column) => {
										return (
											<DataTableHeadCell
												{...column.getHeaderProps(
													isSorted && column.getSortByToggleProps()
												)}
												width={column.width ? column.width : "300px"}
												align={column.align ? column.align : "left"}
												sorted={setSortedValue(column)}
											>
												{column.render("Header")}
											</DataTableHeadCell>
										);
									})}
								</TableRow>
							))}
						</MDBox>
						<TableBody {...getTableBodyProps()}>
							{page.map((row, key) => {
								prepareRow(row);
								return (
									<TableRow {...row.getRowProps()}>
										{row.cells.map((cell) => (
											<DataTableBodyCell
												noBorder={noEndBorder && rows.length - 1 === key}
												align={cell.column.align ? cell.column.align : "left"}
												{...cell.getCellProps()}
											>
												{cell.render("Cell")}
											</DataTableBodyCell>
										))}
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
					<MDBox
						display="flex"
						flexDirection={{ xs: "column", sm: "row" }}
						justifyContent="space-between"
						alignItems={{ xs: "flex-start", sm: "center" }}
						p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}
					>
						{showTotalEntries && (
							<MDBox mb={{ xs: 3, sm: 0 }}>
								<MDTypography variant="button" color="secondary" fontWeight="regular">
									{/* Showing {entriesStart} to {entriesEnd} of {rows.length} entries */}
									{t("TABLE.TOTAL_ENTRIES", {
										start: entriesStart,
										end: entriesEnd,
										total: totalCount,
									})}
								</MDTypography>
							</MDBox>
						)}
						{pageOptions.length > 1 && (
							<MDPagination
								variant={pagination.variant ? pagination.variant : "gradient"}
								color={pagination.color ? pagination.color : "info"}
							>
								{canPreviousPage && (
									<MDPagination
										item
										onClick={() => {
											// dispatch(getArtist(pageIndex + 1));
											previousPage();
											setChanged(changed + 1);
											console.log("previous page");
										}}
									>
										<Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
									</MDPagination>
								)}
								{renderPagination.length > 6 ? (
									<MDBox width="5rem" mx={1}>
										<MDInput
											inputProps={{
												type: "number",
												min: 1,
												max: customizedPageOptions.length,
											}}
											value={customizedPageOptions[pageIndex]}
											onChange={(handleInputPagination, handleInputPaginationValue)}
										/>
									</MDBox>
								) : (
									renderPagination
								)}
								{canNextPage && (
									<MDPagination
										item
										onClick={() => {
											// dispatch(getArtist(pageIndex + 1));
											nextPage();
											console.log("next page");
											setChanged(changed + 1);
										}}
									>
										<Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
									</MDPagination>
								)}
							</MDPagination>
						)}
					</MDBox>
				</>
			)}
		</TableContainer>
	);
}

// Setting default values for the props of DataTable
DataTable.defaultProps = {
	entriesPerPage: false,
	canSearch: false,
	showTotalEntries: true,
	pagination: { variant: "gradient", color: "info" },
	isSorted: false,
	noEndBorder: false,
};

// Typechecking props for the DataTable
DataTable.propTypes = {
	entriesPerPage: PropTypes.oneOfType([
		PropTypes.shape({
			defaultValue: PropTypes.number,
			entries: PropTypes.arrayOf(PropTypes.number),
		}),
		PropTypes.bool,
	]),
	canSearch: PropTypes.bool,
	showTotalEntries: PropTypes.bool,
	table: PropTypes.objectOf(PropTypes.array).isRequired,
	pagination: PropTypes.shape({
		variant: PropTypes.oneOf(["contained", "gradient"]),
		color: PropTypes.oneOf([
			"primary",
			"secondary",
			"info",
			"success",
			"warning",
			"error",
			"dark",
			"light",
		]),
	}),
	isSorted: PropTypes.bool,
	noEndBorder: PropTypes.bool,
};

export default DataTable;
