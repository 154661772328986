import axios from "./api";

export function getFloatTable(data) {
  return axios({
    url: `/Float/getpage`,
    method: "post",
    data,
  });
}
export function addFloat(value) {
  return axios({
    url: `/Float/transaction/${value}`,
    method: "post",
    // data:{"transactionAmount": value},
  });
}

// Float/freezeLoanApplications({freezeLoan}

export function freezeLoanApplications(value) {
  return axios({
    url: `/Float/freezeLoanApplications/${value}`,
    method: "put",
  });
}

export function editMinFloat(data) {
  return axios({
    url: `/Float/minFloat`,
    method: "put",
    data,
  });
}