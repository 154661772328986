import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import xIcon from "../../assets/images/ink_me/icons/icon_x_white.svg";

import {
  toggleGeneralError,
  resetLoginError,
  resetChangePasswordState,
} from "../../store/authSlice";

import { resetArtistGeneralError } from "store/artistSlice";

import "./ErrorModal.css";

function ErrorModal() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const generalErrorState = useSelector((state) => state.auth.generalError);
  const loginErrorState = useSelector((state) => state.auth.loginError);
  const errorMessage = useSelector((state) => state.auth.errorMessage);
  const generalArtistErrorState = useSelector(
    (state) => state.artist.generalError
  );
  const artistGeneralErrorMessage = useSelector(
    (state) => state.artist.errorMessage
  );

  useEffect(() => {
    const renderError = () => {
      if (generalErrorState) {
        setError(t("ERRORS.GENERAL_ERROR"));
      }
      if (generalArtistErrorState) {
        if (!artistGeneralErrorMessage) {
          setError(t("ERRORS.GENERAL_ERROR"));
        } else {
          setError(artistGeneralErrorMessage);
        }
      }
    };
    renderError();
  }, [generalErrorState, generalArtistErrorState, artistGeneralErrorMessage]);

  const onModalClose = () => {
    if (generalErrorState) {
      dispatch(toggleGeneralError());
    }
    if (generalArtistErrorState) {
      dispatch(resetArtistGeneralError());
    }
    if (loginErrorState) {
      dispatch(resetLoginError());
      dispatch(resetChangePasswordState());
    }
  };

  return (
    <div
      className={
        generalErrorState || loginErrorState || generalArtistErrorState
          ? "show_general_error"
          : "hidden"
      }
    >
      <div className="error_modal_background_container">
        <div className="error_modal_container">
          <div className="modal_title">
            <h3>{t("ERRORS.ERROR")}</h3>
            <button
              type="button"
              onClick={onModalClose}
              onKeyDown={onModalClose}
            >
              <img src={xIcon} alt="x icon" />
            </button>
          </div>
          <div className="modal_content">
            <p>{error}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ErrorModal;
