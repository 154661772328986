/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

// Overview page components
import Header from "layouts/pages/profile/components/Header";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getProfile } from "store/authSlice";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

// Dat

function Overview() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.auth.profileDetails);

  useEffect(() => {
    dispatch(getProfile());
  }, []);

  return (
    <DashboardLayout>
      <MDBox mb={2} />
      <Header>
        <MDBox mt={5} mb={3} display="flex" justifyContent="center">
          <MDBox
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            minWidth="250px"
          >
            <MDBox display="flex" alignItems="center">
              <MDTypography variant="h6">{t("TABLE.EMAIL")}:</MDTypography>
              <MDBox pl={2}>
                <MDTypography variant="body2">{profile.email}</MDTypography>
              </MDBox>
            </MDBox>
            <MDBox display="flex" alignItems="center">
              <MDTypography variant="h6">{t("TABLE.PHONE")}:</MDTypography>{" "}
              <MDBox pl={2}>
                <MDTypography variant="body2">{profile.phone}</MDTypography>
              </MDBox>
            </MDBox>
          </MDBox>
        </MDBox>
      </Header>
    </DashboardLayout>
  );
}

export default Overview;
