import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

// Material Dashboard 2 PRO React components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import StatisticsCard from "./Card";
import MDTypography from "components/MDTypography";
import MDBadgeDot from "components/MDBadgeDot";
import PieChart from "examples/Charts/PieChart";
import DefaultLineChart from "examples/Charts/LineCharts/DefaultLineChart";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import "./dashboard.css";
import {
  dashboard,
  howHeard,
  monthlyRevenue,
  monthlyLoanApplications,
} from "../../../api/dashboard";

function Dashboard() {
  const { t } = useTranslation();
  const [stats, setStats] = useState({});
  const [howHeardStats, setHowHeardStats] = useState({});
  const [monthlyRevenueStats, setMonthlyRevenueStats] = useState();
  const [monthlyLoanApplicationStats, setMonthlyLoanApplicationStats] =
    useState();

  const getMonth = (month) => {
    switch (month) {
      case "1":
        return t("DASHBOARD.JAN");
      case "2":
        return t("DASHBOARD.FEB");
      case "3":
        return t("DASHBOARD.MAR");
      case "4":
        return t("DASHBOARD.APR");
      case "5":
        return t("DASHBOARD.MAY");
      case "6":
        return t("DASHBOARD.JUN");
      case "7":
        return t("DASHBOARD.JUL");
      case "8":
        return t("DASHBOARD.AUG");
      case "9":
        return t("DASHBOARD.SEP");
      case "10":
        return t("DASHBOARD.OCT");
      case "11":
        return t("DASHBOARD.NOV");
      default:
        return t("DASHBOARD.DEC");
    }
  };

  useEffect(() => {
    const getDashboardStats = async () => {
      try {
        const res = await dashboard();
        setStats(res);
      } catch (error) {
        console.log(error.response);
      }
    };
    getDashboardStats();
  }, []);

  useEffect(() => {
    const getMonthlyRevenueStats = async () => {
      try {
        const res = await monthlyRevenue();
        res.labels = res.month.map((month) => getMonth(month));
        res.datasets = [{ label: "CAD", color: "primary", data: res.amount }];
        setMonthlyRevenueStats(res);
      } catch (error) {
        console.log(error.response);
      }
    };
    getMonthlyRevenueStats();
  }, []);

  useEffect(() => {
    const getMonthlyApplicationStats = async () => {
      try {
        const res = await monthlyLoanApplications();
        res.labels = res.months.map((month) => getMonth(month));
        res.datasets = [
          { label: "#", color: "primary", data: res.applications },
        ];
        setMonthlyLoanApplicationStats(res);
      } catch (error) {
        console.log(error.response);
      }
    };
    getMonthlyApplicationStats();
  }, []);

  useEffect(() => {
    const getHowHeardStats = async () => {
      try {
        const res = await howHeard();
        setHowHeardStats({
          labels: [
            t("DASHBOARD.SOCIAL_MEDIA"),
            t("DASHBOARD.IN_PERSON"),
            t("DASHBOARD.ONLINE_SEARCH"),
            t("DASHBOARD.OTHER"),
          ],
          datasets: {
            label: t("DASHBOARD.HOW_HEARD"),
            backgroundColors: [
              "warning",
              "primary",
              "error",
              "secondary",
              "primary",
            ],
            data: [res.socialMedia, res.inPerson, res.onlineSearch, res.other],
          },
        });
      } catch (error) {
        console.log(error.response);
      }
    };
    getHowHeardStats();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div>
        {!stats || Object.keys(stats).length === 0 ? null : (
          <MDBox m={2} mt={1.5}>
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              spacing={3}
            >
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                  <StatisticsCard
                    title={t("DASHBOARD.REGISTERED_USERS")}
                    count={stats.registeredCustomers}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                  <StatisticsCard
                    title={t("DASHBOARD.APPROVED_ARTISTS")}
                    count={stats.approvedArtists}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                  <StatisticsCard
                    title={t("DASHBOARD.PENDING_FEEDBACK")}
                    count={stats.pendingFeedback}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                  <StatisticsCard
                    title={t("DASHBOARD.TATTOOS_IN_30")}
                    count={stats.tattoosInLast30}
                  />
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
        )}
        <MDBox mb={3} mt={3} pt={3}>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            spacing={3}
          >
            <Grid item xs={20} sm={8} lg={5}>
              <Card sx={{ height: "100%" }}>
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  pt={2}
                  px={2}
                >
                  <MDTypography variant="h6">
                    {t("DASHBOARD.HOW_HEARD")}
                  </MDTypography>
                </MDBox>
                <MDBox mt={3}>
                  <Grid container alignItems="center">
                    <Grid item xs={7}>
                      <PieChart chart={howHeardStats} height="12.5rem" />
                    </Grid>
                    <Grid item xs={5}>
                      <MDBox pr={1}>
                        <MDBox mb={1}>
                          <MDBadgeDot
                            color="warning"
                            size="sm"
                            badgeContent={t("DASHBOARD.SOCIAL_MEDIA")}
                          />
                        </MDBox>
                        <MDBox mb={1}>
                          <MDBadgeDot
                            color="primary"
                            size="sm"
                            badgeContent={t("DASHBOARD.IN_PERSON")}
                          />
                        </MDBox>
                        <MDBox mb={1}>
                          <MDBadgeDot
                            color="error"
                            size="sm"
                            badgeContent={t("DASHBOARD.ONLINE_SEARCH")}
                          />
                        </MDBox>
                        <MDBox mb={1}>
                          <MDBadgeDot
                            color="secondary"
                            size="sm"
                            badgeContent={t("DASHBOARD.OTHER")}
                          />
                        </MDBox>
                      </MDBox>
                    </Grid>
                  </Grid>
                </MDBox>
              </Card>
            </Grid>
            <Grid item xs={20} md={12} lg={5}>
              <MDBox mb={1.5} pt={3}>
                {!monthlyRevenueStats ? null : (
                  <DefaultLineChart
                    title={t("DASHBOARD.MONTHLY_REVENUE")}
                    chart={monthlyRevenueStats}
                  />
                )}
              </MDBox>
            </Grid>
            <Grid item xs={20} md={12} lg={5}>
              <MDBox mb={1.5} pt={3}>
                {!monthlyLoanApplicationStats ? null : (
                  <DefaultLineChart
                    title={t("DASHBOARD.MONTHLY_LOAN_APPLICATIONS")}
                    chart={monthlyLoanApplicationStats}
                  />
                )}
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </div>
    </DashboardLayout>
  );
}

export default Dashboard;
