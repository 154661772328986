import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getPayoutReport, getPayoutReportDetail, getPayoutReportArtistDetail } from "../api/payout";

export const getReports = createAsyncThunk(
	"payoutReport/getReports",
	async (data, { dispatch, getState, rejectWithValue }) => {
		try {
			const res = await getPayoutReport(data);
			return res;
		} catch (error) {
			return rejectWithValue(error.response);
		}
	}
);

export const getReportDetails = createAsyncThunk(
	"payoutReport/getReportDetails",
	async (data, { dispatch, getState, rejectWithValue }) => {
		let selectedReport = data.selectedReport;
		
		let restData = {
			searchTerms: data.searchTerms,
			forceSearchTerms: data.forceSearchTerms,
			pageIndex: data.pageIndex,
			pageSize: data.pageSize,
		};
		try {
			const res = await getPayoutReportDetail(restData, selectedReport);
			return res;
		} catch (error) {
			return rejectWithValue(error.response);
		}
	}
);
export const getReportArtistDetails = createAsyncThunk(
	"payoutReport/getReportArtistDetails",
	async (data, { dispatch, getState, rejectWithValue }) => {
		let selectedReport = data.selectedReport;
		let selectedAritist = data.selectedAritist;
		let restData = {
			searchTerms: data.searchTerms,
			forceSearchTerms: data.forceSearchTerms,
			pageIndex: data.pageIndex,
			pageSize: data.pageSize,
		};
		try {
			const res = await getPayoutReportArtistDetail(restData, selectedReport, selectedAritist);
			return res;
		} catch (error) {
			return rejectWithValue(error.response);
		}
	}
);

const payoutReportSlice = createSlice({
	name: "payoutReport",
	initialState: {
		error: null,
		allReports: [],
		reportDetails: [],
		reprotArtistDetails: [],
		getReportsLoading: false,
		getReportDetailsLoading: false,
		getReportArtistDetailsLoading: false,
	},
	reducers: {},
	extraReducers: {
		[getReports.pending]: (state, action) => {
			state.getReportsLoading = true;
		},
		[getReports.fulfilled]: (state, { payload }) => {
			state.allReports = payload;
			state.getReportsLoading = false;
		},
		[getReports.rejected]: (state, action) => {
			state.getReportsLoading = false;
		},
		[getReportDetails.pending]: (state, action) => {
			state.getReportDetailsLoading = true;
		},
		[getReportDetails.fulfilled]: (state, { payload }) => {
			state.reportDetails = payload;
			state.getReportDetailsLoading = false;
		},
		[getReportDetails.rejected]: (state, action) => {
			state.getReportDetailsLoading = false;
		},
		[getReportArtistDetails.pending]: (state, action) => {
			state.getReportArtistDetailsLoading = true;
		},
		[getReportArtistDetails.fulfilled]: (state, { payload }) => {
			state.reprotArtistDetails = payload;
			state.getReportArtistDetailsLoading = false;
		},
		[getReportArtistDetails.rejected]: (state, action) => {
			state.getReportArtistDetailsLoading = false;
		},
	},
});

export default payoutReportSlice.reducer;
