import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import idVerifyIcon from "../../../../../assets/images/ink_me/icons/icon_id_verification_black.svg";
import idVerifyIconGrey from "../../../../../assets/images/ink_me/icons/icon_id_verification_grey.svg";
import idVerifyIconGreen from "../../../../../assets/images/ink_me/icons/icon_id_verification_green.svg";
import bankAccountIcon from "../../../../../assets/images/ink_me/icons/icon_bank_black.svg";
import bankAccountIconGrey from "../../../../../assets/images/ink_me/icons/icon_bank_grey.svg";
import bankAccountIconGreen from "../../../../../assets/images/ink_me/icons/icon_bank_green.svg";
import loanAgreementIcon from "../../../../../assets/images/ink_me/icons/icon_loan_black.svg";
import loanAgreementIconGrey from "../../../../../assets/images/ink_me/icons/icon_loan_grey.svg";
import loanAgreementIconGreen from "../../../../../assets/images/ink_me/icons/icon_loan_green.svg";
import stripeIcon from "../../../../../assets/images/ink_me/icons/icon_card_black.svg";
import stripeIconGrey from "../../../../../assets/images/ink_me/icons/icon_card_grey.svg";
import stripeIconGreen from "../../../../../assets/images/ink_me/icons/icon_card_green.svg";

import { getDate, getDateWithTime } from "helpers/helpers";

import "./InApplyingProcessDetails.css";
import MDButton from "components/MDButton";
import PDFVIewer from "../PDFViewer";

const InApplyingProcessDetails = () => {
  const { t } = useTranslation();
  const loanDetails = useSelector((state) => state.loan.loanDetails);
  const status = useSelector((state) => state.loan.loanDetails.status);
  const [viewContractState, setViewContractState] = useState(false);

  const handleViewContract = () => {
    setViewContractState(true);
  };

  return (
    <MDBox>
      <MDBox>
        <MDBox px={3} pt={3}>
          <MDTypography variant="h6" textTransform="uppercase">
            {t("LOAN.LOAN_DETAILS")}
          </MDTypography>
        </MDBox>
        <MDBox px={3} py={1}>
          {status === "Risk_Score_Completed" ||
          status === "Contract_Initiated" ||
          status === "Contract_Completed" ||
          status === "Stripe_Initiated" ||
          status === "Stripe_Completed" ||
          status === "Risk_Score_Denied" ||
          status === "Age_Denied" ||
          status === "Risk_Score_Initiated" ||
          status === "Loan_Awaiting_Approval" ? (
            <MDBox>
              <MDBox display="flex" alignItems="center">
                <MDTypography variant="h6">
                  {t("LOAN.INVERITE_AGE_URL")}
                  {": "}
                </MDTypography>
                <MDBox pl={2}>
                  <a
                    className="inverite_link"
                    href={
                      loanDetails.inveriteAgeUrl ===
                      "https://sandbox.inverite.com/merchant/request/view/"
                        ? null
                        : loanDetails.inveriteAgeUrl
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    <MDTypography variant="body2">
                      {loanDetails.inveriteAgeUrl ===
                      "https://sandbox.inverite.com/merchant/request/view/"
                        ? null
                        : loanDetails.inveriteAgeUrl}
                    </MDTypography>
                  </a>
                </MDBox>
              </MDBox>
              <MDBox display="flex" alignItems="center">
                <MDTypography variant="h6">
                  {t("LOAN.INVERITE_RISK_URL")}
                  {": "}
                </MDTypography>
                <MDBox pl={2}>
                  <a
                    className="inverite_link"
                    href={
                      loanDetails.inveriteRiskUrl ===
                      "https://sandbox.inverite.com/merchant/request/view/"
                        ? null
                        : loanDetails.inveriteRiskUrl
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    <MDTypography variant="body2">
                      {loanDetails.inveriteRiskUrl ===
                      "https://sandbox.inverite.com/merchant/request/view/"
                        ? null
                        : loanDetails.inveriteRiskUrl}
                    </MDTypography>
                  </a>
                </MDBox>
              </MDBox>
              <MDBox display="flex">
                <MDTypography variant="h6">
                  {t("LOAN.INVERITE_RISK_SCORE")}
                  {": "}
                </MDTypography>
                <MDBox pl={2}>
                  <MDTypography variant="body2">
                    {loanDetails.riskBinScore}
                  </MDTypography>
                </MDBox>
              </MDBox>
              {status === "Contract_Completed" ||
              status === "Stripe_Initiated" ||
              status === "Stripe_Completed" ? (
                <MDBox pt={1}>
                  <MDButton color="primary" onClick={handleViewContract}>
                    {t("LOAN.VIEW_CONTRACT")}
                  </MDButton>
                </MDBox>
              ) : null}
              {viewContractState ? (
                <PDFVIewer togglePDFViewer={setViewContractState} />
              ) : null}
            </MDBox>
          ) : null}
          <div className="loan_process_container">
            <div className="loan_approval_steps_container">
              {status === "Risk_Score_Denied" || status === "Age_Denied" ? (
                <div> </div>
              ) : (
                <>
                  <div
                    className={`loan_agreement_steps ${
                      status === "Age_Initiated" ||
                      status === "Waiting_Verification"
                        ? "loan_agreement_active_steps"
                        : ""
                    } ${
                      status === "Age_Completed" ||
                      status === "Age_Denied" ||
                      status === "Risk_Score_Initiated" ||
                      status === "Risk_Score_Completed" ||
                      status === "Risk_Score_Denied" ||
                      status === "Risk_Score_Error" ||
                      status === "Contract_Initiated" ||
                      status === "Contract_Completed" ||
                      status === "Stripe_Initiated" ||
                      status === "Stripe_Completed"
                        ? "loan_agreement_completed_steps"
                        : "loan_agreement_not_completed_steps"
                    }`}
                  >
                    <img
                      src={
                        status === "Age_Initiated" ||
                        status === "Waiting_Verification"
                          ? idVerifyIcon
                          : status === "Age_Completed" ||
                            status === "Risk_Score_Initiated" ||
                            status === "Risk_Score_Completed" ||
                            status === "Risk_Score_Error" ||
                            status === "Contract_Initiated" ||
                            status === "Contract_Completed" ||
                            status === "Stripe_Initiated" ||
                            status === "Stripe_Completed"
                          ? idVerifyIconGreen
                          : idVerifyIconGrey
                      }
                      alt="Id Verification Icon"
                    />
                    <p>{t("LOAN.ID_VERIFICATION")}</p>
                  </div>
                  <div
                    className={`loan_agreement_steps ${
                      status === "Risk_Score_Initiated" ||
                      status === "Age_Completed"
                        ? "loan_agreement_active_steps"
                        : ""
                    } ${
                      status === "Risk_Score_Completed" ||
                      status === "Risk_Score_Denied" ||
                      status === "Risk_Score_Error" ||
                      status === "Contract_Initiated" ||
                      status === "Contract_Completed" ||
                      status === "Stripe_Initiated" ||
                      status === "Stripe_Completed"
                        ? "loan_agreement_completed_steps"
                        : "loan_agreement_not_completed_steps"
                    }`}
                  >
                    <img
                      src={
                        status === "Risk_Score_Initiated" ||
                        status === "Age_Completed"
                          ? bankAccountIcon
                          : status === "Risk_Score_Completed" ||
                            status === "Risk_Score_Error" ||
                            status === "Contract_Initiated" ||
                            status === "Contract_Completed" ||
                            status === "Stripe_Initiated" ||
                            status === "Stripe_Completed"
                          ? bankAccountIconGreen
                          : bankAccountIconGrey
                      }
                      alt="Bank Verification Icon"
                    />
                    <p>{t("LOAN.BANK_VERIFICATION")}</p>
                  </div>
                  <div
                    className={`loan_agreement_steps ${
                      status === "Contract_Initiated" ||
                      status === "Risk_Score_Completed"
                        ? "loan_agreement_active_steps"
                        : ""
                    } ${
                      status === "Contract_Completed" ||
                      status === "Stripe_Initiated" ||
                      status === "Stripe_Completed"
                        ? "loan_agreement_completed_steps"
                        : "loan_agreement_not_completed_steps"
                    }`}
                  >
                    <img
                      src={
                        status === "Contract_Initiated" ||
                        status === "Risk_Score_Completed"
                          ? loanAgreementIcon
                          : status === "Contract_Completed" ||
                            status === "Stripe_Initiated" ||
                            status === "Stripe_Completed"
                          ? loanAgreementIconGreen
                          : loanAgreementIconGrey
                      }
                      alt="Loan Agreement Icon"
                    />
                    <p>{t("LOAN.LOAN_AGREEMENT")}</p>
                  </div>
                  <div
                    className={`loan_agreement_steps ${
                      status === "Stripe_Initiated" ||
                      status === "Contract_Completed"
                        ? "loan_agreement_active_steps"
                        : ""
                    } ${
                      status === "Stripe_Completed"
                        ? "loan_agreement_completed_steps"
                        : "loan_agreement_not_completed_steps"
                    }`}
                  >
                    <img
                      src={
                        status === "Stripe_Initiated"
                          ? stripeIcon
                          : status === "Stripe_Completed"
                          ? stripeIconGreen
                          : stripeIconGrey
                      }
                      alt="Stripe Icon"
                    />
                    <p>{t("LOAN.STRIPE_SETUP")}</p>
                  </div>
                </>
              )}
            </div>
            <div className="loan_details_container">
              <h2 className="loan_card_title">{loanDetails.artistName}</h2>
              <div className="loan_details_text_container">
                {status === "Age_Denied" || status === "Risk_Score_Denied" ? (
                  <div>
                    <h3 className="loan_card_status denied">
                      {t("LOAN.LOAN_DENIED_STATUS")}
                    </h3>
                    <p className="denied">{t("LOAN.LOAN_DENIED")}</p>
                  </div>
                ) : (
                  <div className="loan_details_inner_text_container">
                    <div className="loan_details">
                      <p className="loan_details_title">
                        {t("LOAN.REQUESTED_AMOUNT")}
                      </p>
                      <p className="loan_details_values">
                        ${loanDetails.requestedAmount.toFixed(2)}
                      </p>
                      <p className="loan_details_title">
                        {t("LOAN.REQUESTED_ON")}{" "}
                        {getDateWithTime(loanDetails.details.created)}
                      </p>
                    </div>
                    {loanDetails.approvedAmount !== 0 ? (
                      <div className="loan_details">
                        <p className="loan_details_title">
                          {t("LOAN.APPROVED_AMOUNT")}
                        </p>
                        <p className="loan_details_values">
                          ${loanDetails.approvedAmount.toFixed(2)}
                        </p>
                      </div>
                    ) : null}
                    <div className="loan_details">
                      <p className="loan_details_title">
                        {t("LOAN.LOAN_TERM")}
                      </p>
                      <p className="loan_details_values">
                        {loanDetails.details.term} {t("LOAN.MONTHS")}
                      </p>
                    </div>{" "}
                    <div className="loan_details">
                      <p className="loan_details_title">
                        {t("LOAN.TOTAL_FEES")}
                      </p>
                      <p className="loan_details_values">
                        {`$ ${loanDetails.totalFees.toFixed(2)}`}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </MDBox>
      </MDBox>
    </MDBox>
  );
};

export default InApplyingProcessDetails;
