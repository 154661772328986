import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// @mui material components
import Card from "@mui/material/Card";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import EditMaxMinLoanValue from "./EditMaxMinLoanValue";
import EditThreshold from "./EditThreshold";
import EditLoanFee from "./EditLoanFee";

import { getSettingsData } from "store/settingSlice";
import { useDispatch } from "react-redux";
import EditIDSimilarity from "./EditIDSimilarity";
import EditRiskScores from "./EditRiskScores";
import EditMinFloat from "./EditMinFloat";
import EditPaymentFee from "./EditPaymentFee";
import EditFailedPaymentDays from "./EditFailedPaymentDays";
import { StrikethroughSettings } from "@syncfusion/ej2-react-pdfviewer";
import { useTranslation } from "react-i18next";

const Settings = () => {
  const {t}= useTranslation();
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.setting.settings);
  const [editMaxMinLoanValueState, setEditMaxMinLoanValueState] =
    useState(false);
  const [editNewLoanThresholdState, setEditNewLoanThresholdState] =
    useState(false);
  const [editLoanFeesState, setEditLoanFeesState] = useState(false);
  const [editIDSimilarityState, setEditIDSimilarityState] = useState(false);
  const [editRiskScoresState, setEditRiskScoresState] = useState(false);
  const [editMinFloatState, setEditMinFloatState] = useState(false);
  const [editPaymentFeeState, setEditPaymentFeeState] = useState(false);
  const [editPaymentDaysState, setEditPaymentDaysState] = useState(false);

  useEffect(() => {
    dispatch(getSettingsData());
  }, []);

  const handleEditMaxMinLoanValue = () => {
    setEditMaxMinLoanValueState(true);
  };

  const handleEditNewLoanThreshold = () => {
    setEditNewLoanThresholdState(true);
  };

  const handleEditLoanFees = () => {
    setEditLoanFeesState(true);
  };

  const handleEditAgeSimilarity = () => {
    setEditIDSimilarityState(true);
  };

  const handleEditRiskScore = () => {
    setEditRiskScoresState(true);
  };

  const handleEditMinFloat = () => {
    setEditMinFloatState(true);
  };

  const handleEditFailedPaymentFee = () => {
    setEditPaymentFeeState(true);
  };

  const handleEditFailedPaymentDays = () => {
    setEditPaymentDaysState(true);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card>
        <MDBox p={3}>
          {!settings || Object.keys(settings).length === 0 ? (
            <MDBox></MDBox>
          ) : (
            <>
              <MDBox>
                <MDTypography variant="h4" textTransform="uppercase">
                  {t("SETTINGS.APPLICATION_SETTINGS")}
                </MDTypography>
                <MDBox
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  border="1px solid"
                  p={2}
                  borderRadius="6px"
                  m={2}
                >
                  <MDBox width="65%">
                    <MDBox
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      width="100%"
                      py={1}
                    >
                      <MDTypography variant="h6">
                        {t("SETTINGS.MAX_LOAN_VALUE")}
                        {": "}
                      </MDTypography>
                      <MDBox pl={2}>
                        <MDTypography variant="body2">
                          $ {settings.loanMaxValue.toFixed(2)}
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                    <MDBox
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      width="100%"
                      py={1}
                    >
                      <MDTypography variant="h6">
                        {t("SETTINGS.MIN_LOAN_VALUE")}
                        {": "}
                      </MDTypography>
                      <MDBox pl={2}>
                        <MDTypography variant="body2">
                          $ {settings.loanMinValue.toFixed(2)}
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                  <MDBox>
                    <MDButton
                      color="primary"
                      variant="outlined"
                      sx={{ width: "200px" }}
                      onClick={handleEditMaxMinLoanValue}
                    >
                      {t("SETTINGS.EDIT_MAX_MIN_VALUE")}
                    </MDButton>
                  </MDBox>
                  {editMaxMinLoanValueState ? (
                    <EditMaxMinLoanValue
                      max={settings.loanMaxValue}
                      min={settings.loanMinValue}
                      onButtonClose={() => setEditMaxMinLoanValueState(false)}
                    />
                  ) : null}
                </MDBox>
                <MDBox
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  border="1px solid"
                  p={2}
                  borderRadius="6px"
                  m={2}
                >
                  <MDBox width="65%">
                    <MDBox
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      width="100%"
                      py={1}
                    >
                      <MDTypography variant="h6">
                        {t("SETTINGS.LOAN_THRESHOLD")}
                        {": "}
                      </MDTypography>
                      <MDBox pl={2}>
                        <MDTypography variant="body2">
                          {settings.newLoanThreshold}
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                  <MDBox>
                    <MDButton
                      color="primary"
                      variant="outlined"
                      sx={{ width: "200px" }}
                      onClick={handleEditNewLoanThreshold}
                    >
                      {t("SETTINGS.EDIT_NEW_LOAN_THRESHOLD")}
                    </MDButton>
                  </MDBox>
                  {editNewLoanThresholdState ? (
                    <EditThreshold
                      threshold={settings.newLoanThreshold}
                      onButtonClose={() => setEditNewLoanThresholdState(false)}
                    />
                  ) : null}
                </MDBox>
                <MDBox
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  border="1px solid"
                  p={2}
                  borderRadius="6px"
                  m={2}
                >
                  <MDBox width="65%">
                    <MDBox
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      width="100%"
                      py={1}
                    >
                      <MDTypography variant="h6">
                        {t("SETTINGS.THREE_MONTH_FEE")}
                        {":"}
                      </MDTypography>
                      <MDBox pl={2}>
                        <MDTypography variant="body2">
                          $ {settings.threeMonthFee.toFixed(2)}
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                    <MDBox
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      width="100%"
                      py={1}
                    >
                      <MDTypography variant="h6">
                        {t("SETTINGS.SIX_MONTH_FEE")}
                        {":"}
                      </MDTypography>
                      <MDBox pl={2}>
                        <MDTypography variant="body2">
                          $ {settings.sixMonthFee.toFixed(2)}
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                    <MDBox
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      width="100%"
                      py={1}
                    >
                      <MDTypography variant="h6">
                        {t("SETTINGS.NINE_MONTH_FEE")}
                        {":"}
                      </MDTypography>
                      <MDBox pl={2}>
                        <MDTypography variant="body2">
                          $ {settings.nineMonthFee.toFixed(2)}
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                    <MDBox
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      width="100%"
                      py={1}
                    >
                      <MDTypography variant="h6">
                        {t("SETTINGS.TWELVE_MONTH_FEE")}
                        {":"}
                      </MDTypography>
                      <MDBox pl={2}>
                        <MDTypography variant="body2">
                          $ {settings.twelveMonthFee.toFixed(2)}
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                  <MDBox>
                    <MDButton
                      color="primary"
                      variant="outlined"
                      sx={{ width: "200px" }}
                      onClick={handleEditLoanFees}
                    >
                      {t("SETTINGS.EDIT_LOAN_FEES")}
                    </MDButton>
                  </MDBox>
                  {editLoanFeesState ? (
                    <EditLoanFee
                      fees={{
                        threeMonthFee: settings.threeMonthFee,
                        sixMonthFee: settings.sixMonthFee,
                        nineMonthFee: settings.nineMonthFee,
                        twelveMonthFee: settings.twelveMonthFee,
                      }}
                      onButtonClose={() => setEditLoanFeesState(false)}
                    />
                  ) : null}
                </MDBox>
              </MDBox>
              <MDBox pt={2}>
                <MDTypography variant="h4" textTransform="uppercase">
                  {t("SETTINGS.APPROVAL_SETTINGS")}
                </MDTypography>
                <MDBox
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  border="1px solid"
                  p={2}
                  borderRadius="6px"
                  m={2}
                >
                  <MDBox width="65%">
                    <MDBox
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      width="100%"
                      py={1}
                    >
                      <MDTypography variant="h6">
                        {t("SETTINGS.AGE_SIMILARITY_THRESHOLD")}
                        {":"}
                      </MDTypography>
                      <MDBox pl={2}>
                        <MDTypography variant="body2">
                          {settings.ageSimilarityThreshold}
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                  <MDBox>
                    <MDButton
                      color="primary"
                      variant="outlined"
                      sx={{ width: "200px" }}
                      onClick={handleEditAgeSimilarity}
                    >
                      {t("SETTINGS.EDIT_AGE_SIMILARITY")}
                    </MDButton>
                  </MDBox>
                  {editIDSimilarityState ? (
                    <EditIDSimilarity
                      idSimilarity={settings.ageSimilarityThreshold}
                      onButtonClose={() => setEditIDSimilarityState(false)}
                    />
                  ) : null}
                </MDBox>
                <MDBox
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  border="1px solid"
                  p={2}
                  borderRadius="6px"
                  m={2}
                >
                  <MDBox width="65%">
                    <MDBox
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      width="100%"
                      py={1}
                    >
                      <MDTypography variant="h6">
                        {t("SETTINGS.MAX_RISK_SCORE")}
                        {":"}
                      </MDTypography>
                      <MDBox pl={2}>
                        <MDTypography variant="body2">
                          {settings.riskScoreMax}
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                    <MDBox
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      width="100%"
                      py={1}
                    >
                      <MDTypography variant="h6">
                        {t("SETTINGS.MIN_RISK_SCORE")}
                        {":"}
                      </MDTypography>
                      <MDBox pl={2}>
                        <MDTypography variant="body2">
                          {settings.riskScoreMin}
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                  <MDBox>
                    <MDButton
                      color="primary"
                      variant="outlined"
                      sx={{ width: "200px" }}
                      onClick={handleEditRiskScore}
                    >
                      {t("SETTINGS.EDIT_RISK_SCORES")}
                    </MDButton>
                  </MDBox>
                  {editRiskScoresState ? (
                    <EditRiskScores
                      maxScore={settings.riskScoreMax}
                      minScore={settings.riskScoreMin}
                      onButtonClose={() => setEditRiskScoresState(false)}
                    />
                  ) : null}
                </MDBox>
              </MDBox>
              <MDBox pt={2}>
                <MDTypography variant="h4" textTransform="uppercase">
                  {t("SETTINGS.FLOAT_SETTINGS")}
                </MDTypography>
                <MDBox
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  border="1px solid"
                  p={2}
                  borderRadius="6px"
                  m={2}
                >
                  <MDBox width="65%">
                    <MDBox
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      width="100%"
                      py={1}
                    >
                      <MDTypography variant="h6">
                        {t("SETTINGS.FLOAT_MIN_VALUE")}
                        {":"}
                      </MDTypography>
                      <MDBox width="25%" textAlign="end" pl={2}>
                        <MDTypography variant="body2">
                          $ {settings.floatMinValue.toFixed(2)}
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                  <MDBox>
                    <MDButton
                      color="primary"
                      variant="outlined"
                      sx={{ width: "200px" }}
                      onClick={handleEditMinFloat}
                    >
                      {t("SETTINGS.EDIT_MIN_FLOAT")}
                    </MDButton>
                  </MDBox>
                  {editMinFloatState ? (
                    <EditMinFloat
                      min={settings.floatMinValue}
                      onButtonClose={() => setEditMinFloatState(false)}
                    />
                  ) : null}
                </MDBox>
              </MDBox>
              <MDBox pt={2}>
                <MDTypography variant="h4" textTransform="uppercase">
                  {t("SETTINGS.PAYMENT_SETTINGS")}
                </MDTypography>
                <MDBox
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  border="1px solid"
                  p={2}
                  borderRadius="6px"
                  m={2}
                >
                  <MDBox width="65%">
                    <MDBox
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      width="100%"
                      py={1}
                    >
                      <MDTypography variant="h6">
                        {t("SETTINGS.FAILED_PAYMENT_FEE")} {":"}
                      </MDTypography>
                      <MDBox pl={2}>
                        <MDTypography variant="body2">
                          $ {settings.failedPaymentFee.toFixed(2)}
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                  <MDBox>
                    <MDButton
                      color="primary"
                      variant="outlined"
                      sx={{ width: "200px" }}
                      onClick={handleEditFailedPaymentFee}
                    >
                      {t("SETTINGS.EDIT_FAILED_PAYMENT_FEE")}
                    </MDButton>
                  </MDBox>
                  {editPaymentFeeState ? (
                    <EditPaymentFee
                      fee={settings.failedPaymentFee}
                      onButtonClose={() => setEditPaymentFeeState(false)}
                    />
                  ) : null}
                </MDBox>
                <MDBox
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  border="1px solid"
                  p={2}
                  borderRadius="6px"
                  m={2}
                >
                  <MDBox width="65%">
                    <MDBox
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      width="100%"
                      py={1}
                    >
                      <MDTypography variant="h6">
                        {t("SETTINGS.FAILED_PAYMENT_TRY2")}
                        {":"}
                      </MDTypography>
                      <MDBox pl={2}>
                        <MDTypography variant="body2">
                          {settings.failedPaymentDaysUntilTry2}
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                    <MDBox
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      width="100%"
                      py={1}
                    >
                      <MDTypography variant="h6">
                        {t("SETTINGS.FAILED_PAYMENT_TRY3")}
                        {":"}
                      </MDTypography>
                      <MDBox pl={2}>
                        <MDTypography variant="body2">
                          {settings.failedPaymentDaysUntilTry3}
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                    <MDBox
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      width="100%"
                      py={1}
                    >
                      <MDTypography variant="h6">
                        {t("SETTINGS.FAILED_PAYMENT_TRY4")}
                        {":"}
                      </MDTypography>
                      <MDBox pl={2}>
                        <MDTypography variant="body2">
                          {settings.failedPaymentDaysUntilTry4}
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                  <MDBox>
                    <MDButton
                      color="primary"
                      variant="outlined"
                      sx={{ width: "200px" }}
                      onClick={handleEditFailedPaymentDays}
                    >
                      {t("SETTINGS.EDIT_FAILED_PAYMENT_DAYS")}
                    </MDButton>
                  </MDBox>
                  {editPaymentDaysState ? (
                    <EditFailedPaymentDays
                      failed1={settings.failedPaymentDaysUntilTry2}
                      failed2={settings.failedPaymentDaysUntilTry3}
                      failed3={settings.failedPaymentDaysUntilTry4}
                      onButtonClose={() => setEditPaymentDaysState(false)}
                    />
                  ) : null}
                </MDBox>
              </MDBox>
            </>
          )}
        </MDBox>
      </Card>
    </DashboardLayout>
  );
};

export default Settings;
