import axios from "./api";

export function getStaffTable(data) {
  return axios({
    url: `/UserAdmin/admin/getpage`,
    method: "post",
    data,
  });
}

export function createAdmin(data) {
  return axios({
    url: `/Admin`,
    method: "post",
    data,
  });
}

export function createSuperAdmin(data) {
  return axios({
    url: `/Admin/CreateSuperAdmin`,
    method: "post",
    data,
  });
}

export function editAdmin(data) {
  return axios({
    url: `/UserAdmin/admin/edit`,
    method: "put",
    data,
  });
}

export function blockAndUnblockAdmin(data) {
  return axios({
    url: `/UserAdmin/admin/blocking`,
    method: "put",
    data,
  });
}

export function resetTempPassword(data) {
  return axios({
    url: `/Admin/ResetTemporaryPassword`,
    method: "post",
    data,
  });
}

