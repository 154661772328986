import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import ModalContainer from "components/ModalContainer/ModalContainer";
import MDTypography from "components/MDTypography";
import InputAdornment from "@mui/material/InputAdornment";
import { getSettingsData } from "store/settingSlice";
import { useDispatch } from "react-redux";
import { updateMinFloat } from "api/settings";
import { useTranslation } from "react-i18next";

const EditMinFloat = ({ min, onButtonClose }) => {
  const dispatch = useDispatch();
  const{t}= useTranslation();
  const [minFloat, setminFloat] = useState(min);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const submitValues = async () => {
    const data = {
      minFloatValue: minFloat,
    };
    try {
      const res = await updateMinFloat(data);
      toast.success(t("TOAST.MIN_FLOAT_UPDATED_SUCCESSFULLY"));
      dispatch(getSettingsData());
    } catch (e) {
      console.log(e.response);
      toast.error(e.response);
    }
  };

  useEffect(() => {
    if (isSubmitting && Object.keys(errors).length === 0) {
      submitValues();
      setIsSubmitting(false);
      onButtonClose();
    }
  }, [errors, isSubmitting]);

  const handleChange = (e) => {
    setIsSubmitting(false);
    if (e.target.name === "minFloat") {
      setminFloat(e.target.value);
    }
  };

  const handleSubmit = () => {
    let errorsCode = {};
    if (!minFloat) {
      errorsCode.minFloat = "ERRORS.MIN_FLOAT_REQUIRED";
    }
    setErrors(errorsCode);
    setIsSubmitting(true);
  };

  return (
    <ModalContainer
      title={t("SETTINGS.EDIT_MIN_FLOAT")}
      onButtonClose={onButtonClose}
    >
      <MDBox width="500px">
        <MDBox p={2}>
          <MDTypography variant="body2">
            {t("SETTINGS.EDIT_MIN_FLOAT_MESSAGE")}
          </MDTypography>
        </MDBox>
        <MDBox p={2}>
          <MDInput
            type="number"
            name="minFloat"
            label={t("SETTINGS.FLOAT_MIN_VALUE_CHANGE")}
            value={minFloat}
            onChange={(e) => handleChange(e)}
            InputProps={{
              startAdornment: (
                <InputAdornment class="input_adornment" position="start">
                  $
                </InputAdornment>
              ),
            }}
            error={!errors ? null : errors.minFloat}
            fullWidth
          />
          {!errors ? null : (
            <p className="error_message">{t(errors.minFloat)}</p>
          )}
        </MDBox>
        <MDBox display="flex" justifyContent="center">
          <MDButton color="primary" onClick={handleSubmit}>
            {t("SETTINGS.SUBMIT")}
          </MDButton>
        </MDBox>
      </MDBox>
    </ModalContainer>
  );
};

export default EditMinFloat;
