/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import DefaultLineChart from "examples/Charts/LineCharts/DefaultLineChart";
import MDBadge from "components/MDBadge";
import UpdateProfile from "../UpdateProfile";
import BlockUserModal from "components/blockUserModal";

import "./artistDetails.css";

import { getDate, getDateWithTime } from "../../../../../helpers/helpers";

import {
  toggleUpdateProfileState,
  updateArtistStatusApproveDecline,
  changeArtistBlockUnblock,
  setArtistDetailState,
} from "store/artistSlice";

function ArtistDetails() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const artistDetails = useSelector((state) => state.artist.artistDetails);
  const [payoutHistory, setPayoutHistory] = useState({});
  const updateProfileState = useSelector(
    (state) => state.artist.updateProfileState
  );
  const role = useSelector((state) => state.auth.role);
  const [blockArtistState, setBlockArtistState] = useState(false);
  const [declineArtistState, setDeclineArtistState] = useState(false);
  const artistDetailState = useSelector(
    (state) => state.artist.artistDetailState
  );

  const getMonth = (month) => {
    switch (month) {
      case "1":
        return t("DASHBOARD.JAN");
      case "2":
        return t("DASHBOARD.FEB");
      case "3":
        return t("DASHBOARD.MAR");
      case "4":
        return t("DASHBOARD.APR");
      case "5":
        return t("DASHBOARD.MAY");
      case "6":
        return t("DASHBOARD.JUN");
      case "7":
        return t("DASHBOARD.JUL");
      case "8":
        return t("DASHBOARD.AUG");
      case "9":
        return t("DASHBOARD.SEP");
      case "10":
        return t("DASHBOARD.OCT");
      case "11":
        return t("DASHBOARD.NOV");
      default:
        return t("DASHBOARD.DEC");
    }
  };

  useEffect(() => {
    if (!artistDetails || !artistDetails.monthList) {
    } else {
      let chartData = {};
      chartData.labels = artistDetails.monthList.map((month) =>
        getMonth(month)
      );
      chartData.datasets = [
        { label: "CAD", color: "primary", data: artistDetails.payoutHistory },
      ];
      setPayoutHistory(chartData);
    }
    return () => {
      if (artistDetailState) {
        dispatch(setArtistDetailState(false));
      }
    };
  }, [artistDetails]);

  const handleApproveArtist = (e) => {
    e.preventDefault();
    const data = {
      id: artistDetails.id,
      status: "Approved",
      isApproved: true,
    };
    dispatch(updateArtistStatusApproveDecline(data));
  };

  const handleDeclineArtist = (e) => {
    e.preventDefault();
    setDeclineArtistState(true);
  };

  const handleSubmitDeclineArtist = (reason) => {
    const data = {
      id: artistDetails.id,
      status: "Declined",
      declineReason: reason,
    };
    dispatch(updateArtistStatusApproveDecline(data));
    setDeclineArtistState(false);
  };

  const handleSubmitBlockArtist = (reason, sendEmail) => {
    const data = {
      id: artistDetails.id,
      status: "Block",
      declineReason: reason,
      sendUserEmail: sendEmail,
    };
    dispatch(changeArtistBlockUnblock(data));
    setBlockArtistState(false);
  };

  const handleBlockArtist = () => {
    setBlockArtistState(true);
  };

  const handleUnBlockArtist = () => {
    const data = {
      id: artistDetails.id,
      status: "Unblock",
      declineReason: "",
    };
    dispatch(changeArtistBlockUnblock(data));
  };

  const renderStatus = () => {
    if (artistDetails.status === "Active") {
      return t("ARTISTS.ACTIVE");
    } else if (artistDetails.status === "Email_Not_Verified") {
      return t("ARTISTS.EMAIL_NOT_VERIFIED");
    } else if (artistDetails.status === "Waiting_Verification") {
      return t("ARTISTS.WAITING_VERIFICATION");
    } else if (artistDetails.status === "Declined") {
      return t("ARTISTS.DECLINED");
    } else if (artistDetails.status === "Blocked") {
      return t("ARTISTS.BLOCKED");
    } else return null;
  };

  const renderTags = () => {
    if (!artistDetails) {
      return <div></div>;
    }
    if (!artistDetails.tags) {
      return <div></div>;
    } else {
      return artistDetails.tags.split(",").map((tag) => {
        return (
          <MDBox key={tag}>
            <MDBadge badgeContent={tag} size="lg" />
          </MDBox>
        );
      });
    }
  };

  const renderImages = () => {
    if (!artistDetails) {
      return <div></div>;
    }
    if (!artistDetails.artistProfileImages) {
      return <div></div>;
    } else {
      return artistDetails.artistProfileImages.map((image) => {
        return (
          <MDBox key={image.id} display="flex">
            {image.url === null ? (
              <div className="portfolio_image"></div>
            ) : (
              <MDBox p={1}>
                <img
                  width="250"
                  className="portfolio_image"
                  src={image.url}
                  alt="portfolio"
                />
              </MDBox>
            )}
          </MDBox>
        );
      });
    }
  };

  const renderBlockReasons = () => {
    if (!artistDetails || artistDetails.blockForms.length === 0) {
      return;
    } else {
      return artistDetails.blockForms.map((reason) => {
        return (
          <MDBox key={reason.created}>
            <MDTypography variant="h6" pl={1}>
              {getDate(reason.created)}
            </MDTypography>
            <MDTypography variant="body2" pl={1}>
              {reason.blockReason}
            </MDTypography>
          </MDBox>
        );
      });
    }
  };

  const handleViewLoans = () => {
    const encodedEmail = encodeURIComponent(artistDetails.loginEmail);
    navigate(`/application-management/applications/?email=${encodedEmail}`);
  };

  return (
    <>
      {updateProfileState ? (
        <UpdateProfile />
      ) : (
        <>
          {role === "SuperAdmin" &&
          artistDetails.status !== "Email_Not_Verified" ? (
            <MDBox display="flex" justifyContent="flex-end" px={3}>
              <MDBox px={2}>
                <MDButton color="error" onClick={handleBlockArtist}>
                  {t("ARTISTS.BLOCK_ARTIST")}
                </MDButton>
              </MDBox>
              <MDButton color="primary" onClick={handleUnBlockArtist}>
                {t("ARTISTS.UNBLOCK_ARTIST")}
              </MDButton>
            </MDBox>
          ) : null}
          <MDBox
            display="flex"
            flexDirection="column"
            alignItems="flex-end"
            px={3}
            pt={3}
          >
            <MDBox display="flex" flexDirection="column">
              <MDTypography variant="h6" color="primary" pb={2}>
                {t("TABLE.STATUS")}
                {": "}
                {renderStatus()}
              </MDTypography>
              {artistDetails.status === "Waiting_Verification" ? (
                <MDBox display="flex">
                  <MDBox pr={2}>
                    <MDButton color="primary" onClick={handleApproveArtist}>
                      {t("ARTISTS.APPROVE_ARTIST")}
                    </MDButton>
                  </MDBox>
                  <MDButton color="error" onClick={handleDeclineArtist}>
                    {t("ARTISTS.DECLINE_ARTIST")}
                  </MDButton>
                </MDBox>
              ) : null}
              {artistDetails.status === "Declined" ? (
                <MDBox display="flex" justifyContent="flex-end" px={3}>
                  <MDButton color="primary" onClick={handleApproveArtist}>
                    {t("ARTISTS.APPROVE_ARTIST")}
                  </MDButton>
                </MDBox>
              ) : null}
              {artistDetails.status === "Blocked" ? (
                <MDBox>
                  <MDTypography variant="h6">
                    {t("ARTISTS.REASON_FOR_BLOCK")}
                    {": "}
                  </MDTypography>
                  <MDBox>
                    <MDTypography variant="body2" pl={1}>
                      {renderBlockReasons()}
                    </MDTypography>
                  </MDBox>
                </MDBox>
              ) : null}
            </MDBox>
          </MDBox>
          <MDBox
            pt={2}
            px={2}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <MDBox p={3} pb={1}>
              <MDTypography variant="h3" textTransform="uppercase">
                {`${artistDetails.firstName} ${artistDetails.lastName}`}
              </MDTypography>
              <MDBox display="flex" alignItmes="center">
                <MDTypography variant="h6">
                  {t("TABLE.EMAIL")}
                  {": "}
                </MDTypography>
                <MDTypography variant="body2" pl={1}>
                  {artistDetails.loginEmail}
                </MDTypography>
              </MDBox>
              <MDBox display="flex" alignItmes="center">
                <MDTypography variant="h6">
                  {t("ARTISTS.PAYMENT_EMAIL")}
                  {": "}
                </MDTypography>
                <MDTypography variant="body2" pl={1}>
                  {artistDetails.paymentEmail}
                </MDTypography>
              </MDBox>
              <MDBox display="flex" alignItmes="center">
                <MDTypography variant="h6">
                  {t("TABLE.REGISTRATION_DATE")}
                  {": "}
                </MDTypography>
                <MDTypography variant="body2" pl={1}>
                  {getDate(artistDetails.registerDate)}
                </MDTypography>
              </MDBox>
              <MDBox display="flex" alignItmes="center">
                <MDTypography variant="h6">
                  {t("ARTISTS.URL")}
                  {": "}
                </MDTypography>
                <MDTypography variant="body2" pl={1}>
                  {artistDetails.artistUrl}
                </MDTypography>
              </MDBox>
              <MDBox display="flex" alignItmes="center">
                <MDTypography variant="h6">
                  {t("ARTISTS.HOW_HEARD")}
                  {": "}
                </MDTypography>
                <MDTypography variant="body2" pl={1}>
                  {artistDetails.howHeard}
                </MDTypography>
              </MDBox>
              <MDBox display="flex" alignItmes="center">
                <MDTypography variant="h6">
                  {t("TABLE.TOS_AND_PP")}
                  {": "}
                </MDTypography>
                <MDTypography variant="body2" pl={1}>
                  {getDateWithTime(artistDetails.tosSigned)}
                </MDTypography>
              </MDBox>
            </MDBox>
          </MDBox>
          <Divider />
          <MDBox display="flex" justifyContent="center">
            <MDButton
              color="primary"
              variant="outlined"
              onClick={() => dispatch(toggleUpdateProfileState())}
            >
              {t("ARTISTS.UPDATE_INFO")}
            </MDButton>
          </MDBox>
          <MDBox py={3} px={5} pb={1}>
            <MDTypography variant="h5" textTransform="uppercase">
              {t("ARTISTS.PROFILE_DETAILS")}
            </MDTypography>
          </MDBox>
          <MDBox px={6}>
            <MDBox display="flex" justifyContent="center" pb={2}>
              {!artistDetails ||
              artistDetails.banner === null ||
              artistDetails.banner.url === null ? (
                <div className="banner_image"></div>
              ) : (
                <img
                  className="banner_image"
                  src={artistDetails.banner.url}
                  alt="artist banner"
                />
              )}
            </MDBox>
            <MDBox display="flex" justifyContent="space-between">
              <MDBox>
                <MDBox display="flex" alignItmes="center">
                  <MDTypography variant="h6">
                    {t("TABLE.STUDIO_NAME")}
                    {": "}
                  </MDTypography>
                  <MDTypography variant="body2" pl={1}>
                    {artistDetails.studioName}
                  </MDTypography>
                </MDBox>
                <MDBox display="flex" alignItmes="center">
                  <MDTypography variant="h6">
                    {t("ARTISTS.ADDRESS")}
                    {": "}
                  </MDTypography>
                  <MDTypography variant="body2" pl={1}>
                    {`${artistDetails.street}, ${artistDetails.city}, ${artistDetails.province}, ${artistDetails.postalCode}`}
                  </MDTypography>
                </MDBox>
                <MDBox display="flex" alignItmes="center">
                  <MDTypography variant="h6">
                    {t("ARTISTS.PROFILE_EMAIL")}
                    {": "}
                  </MDTypography>
                  <MDTypography variant="body2" pl={1}>
                    {artistDetails.profileEmail}
                  </MDTypography>
                </MDBox>
                <MDBox display="flex" alignItmes="center">
                  <MDTypography variant="h6">
                    {t("TABLE.PHONE")}
                    {": "}
                  </MDTypography>
                  <MDTypography variant="body2" pl={1}>
                    {artistDetails.phone}
                  </MDTypography>
                </MDBox>
                <MDBox display="flex" alignItmes="center">
                  <MDTypography variant="h6">
                    {t("ARTISTS.WEBSITE")}
                    {": "}
                  </MDTypography>
                  <MDTypography variant="body2" pl={1}>
                    {artistDetails.website}
                  </MDTypography>
                </MDBox>
                <MDBox display="flex" alignItmes="center">
                  <MDTypography variant="h6">
                    {t("ARTISTS.INSTAGRAM")}
                    {": "}
                  </MDTypography>
                  <MDTypography variant="body2" pl={1}>
                    {artistDetails.instagram}
                  </MDTypography>
                </MDBox>
                <MDBox display="flex" alignItmes="center">
                  <MDTypography variant="h6">
                    {t("ARTISTS.DESCRIPTION")}
                    {": "}
                  </MDTypography>
                  <MDTypography variant="body2" pl={1}>
                    {artistDetails.description}
                  </MDTypography>
                </MDBox>
              </MDBox>
              <MDBox>
                <MDBox
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                >
                  <MDBox display="flex" alignItems="center">
                    <MDTypography variant="h6">
                      {t("ARTISTS.TAGS")}
                      {": "}
                    </MDTypography>
                    <MDBox display="flex" alignItems="center">
                      {renderTags()}
                    </MDBox>
                  </MDBox>
                  <MDBox
                    display="flex"
                    alignItmes="center"
                    flexDirection="column"
                  >
                    <MDBox>
                      <MDTypography variant="h6">
                        {t("ARTISTS.PORTFOLIO_IMAGES")}
                        {": "}
                      </MDTypography>
                    </MDBox>
                    <MDBox
                      display="flex"
                      flexWrap="wrap"
                      maxWidth={"40vw"}
                      justifyContent="space-between"
                    >
                      {renderImages()}
                    </MDBox>
                  </MDBox>
                </MDBox>
              </MDBox>
            </MDBox>
          </MDBox>
          <Divider />
          <MDBox pt={1} pb={3}>
            <MDBox py={3} px={5} pb={1}>
              <MDTypography variant="h5" textTransform="uppercase">
                {t("ARTISTS.WORK_HISTORY")}
              </MDTypography>
            </MDBox>
            <MDBox display="flex" justifyContent="space-evenly">
              <Grid item xs={12} md={6} xl={3}>
                <DefaultInfoCard
                  icon="work"
                  title={t("ARTISTS.OUTSTANDING_JOBS")}
                  value={artistDetails.outstandingJobs}
                />
              </Grid>
              <Grid item xs={12} md={6} xl={3}>
                <DefaultInfoCard
                  icon="work_history"
                  title={t("ARTISTS.TOTAL_JOBS")}
                  value={artistDetails.totalJobs}
                />
              </Grid>
              <Grid item xs={12} md={6} xl={3}>
                <DefaultInfoCard
                  icon="paid"
                  title={t("ARTISTS.UPCOMING_PAYOUT")}
                  value={`$ ${
                    !artistDetails.upcomingPayout
                      ? Number(0).toFixed(2)
                      : artistDetails.upcomingPayout.toFixed(2)
                  }`}
                />
              </Grid>
            </MDBox>
            <MDBox display="flex" justifyContent="center">
              <Grid item xs={20} md={12} lg={5} bgColor="light">
                <MDBox pt={3}>
                  {!payoutHistory ? null : (
                    <DefaultLineChart
                      bgColor="light"
                      title={t("ARTISTS.PAYOUT_HISTORY")}
                      chart={payoutHistory}
                    />
                  )}
                </MDBox>
              </Grid>
            </MDBox>
            <MDBox p={3} pb={1} display="flex" justifyContent="flex-end">
              <MDButton color="primary" onClick={handleViewLoans}>
                {" "}
                {t("CUSTOMERS.VIEW_LOANS")}
              </MDButton>
            </MDBox>
          </MDBox>
          {blockArtistState ? (
            <BlockUserModal
              buttonText={"ARTISTS.BLOCK_ARTIST"}
              title={"ARTISTS.BLOCK_ARTIST"}
              onModalClose={() => setBlockArtistState(false)}
              onSubmit={handleSubmitBlockArtist}
            />
          ) : null}
          {declineArtistState ? (
            <BlockUserModal
              buttonText={"ARTISTS.DECLINE_ARTIST"}
              title={"ARTISTS.DECLINE_ARTIST"}
              onModalClose={() => setDeclineArtistState(false)}
              onSubmit={handleSubmitDeclineArtist}
            />
          ) : null}
        </>
      )}
    </>
  );
}

export default ArtistDetails;
