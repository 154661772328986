/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import CustomerDetails from "./CustomerDetails";
import SuccessModal from "components/SuccessModal/SuccessModal";
import FailModal from "components/FailModal/FailModal";
import ViewMoreCell from "./ViewMoreCell";
// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "components/DataTable";
import { getCustomerData } from "store/customerSlice";
import {
  setCustomerDetailState,
  toggleFailModalState,
  toggleSuccessModalState,
  resetErrorMessage,
  resetSuccessMessage,
} from "store/customerSlice";

// Data

function Customers() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const customerData = useSelector((state) => state.customer.customers);
  const totalCount = useSelector((state) => state.customer.totalCount);
  const customerDetailState = useSelector(
    (state) => state.customer.customerDetailState
  );
  const [menu, setMenu] = useState(null);
  const [filterOption, setFilterOptions] = useState("");
  const successModalState = useSelector(
    (state) => state.customer.successModalState
  );
  const failModalState = useSelector((state) => state.customer.failModalState);
  const errorMessage = useSelector((state) => state.customer.errorMessage);
  const successMessage = useSelector((state) => state.customer.successMessage);
  const [pageIndex, setPageIndex] = useState(1);
  const pageSize = 10;
  const loading = useSelector((state) => state.customer.loading);
  const [searchTerms, setSearchTerms] = useState("");
  const [customers, setCustomers] = useState({});

  const getCustomerStatus = (status) => {
    let text = "";
    switch (status) {
      case "Email_Not_Verified":
        text = t("CUSTOMERS.EMAIL_NOT_VERIFIED");
        break;
      case "Active":
        text = t("CUSTOMERS.ACTIVE");
        break;
      case "New_Loans_Blocked":
        text = t("CUSTOMERS.NEW_LOANS_BLOCKED");
        break;
      case "AdminBlocked":
        text = t("CUSTOMERS.ADMIN_BLOCKED");
        break;
      default:
        text = t("CUSTOMERS.IN_COLLECTIONS");
    }
    return text;
  };

  useEffect(() => {
    if (customerData?.totalCount > 0) {
      setCustomers({
        columns: [
          { Header: t("TABLE.FIRST_NAME"), accessor: "firstName" },
          { Header: t("TABLE.LAST_NAME"), accessor: "lastName" },
          { Header: t("TABLE.EMAIL"), accessor: "email" },
          { Header: t("TABLE.PHONE"), accessor: "phone" },
          {
            Header: t("TABLE.STATUS"),
            accessor: "status",
          },
          {
            Header: t("TABLE.VIEW_MORE"),
            accessor: "viewMore",
          },
        ],
        rows: customerData.customers.map((customer) => {
          return {
            firstName: customer.firstName,
            lastName: customer.lastName,
            email: customer.email,
            phone: customer.phone,
            status: getCustomerStatus(customer.status),
            viewMore: <ViewMoreCell id={customer.id} />,
          };
        }),
      });
    } else {
      setCustomers({
        columns: [
          { Header: t("TABLE.NAME"), accessor: "userName" },
          {
            Header: t("TABLE.DATE"),
            accessor: "created",
          },
          { Header: t("TABLE.ARTIST_NAME"), accessor: "artistName" },
          {
            Header: t("TABLE.LOAN_STATUS"),
            accessor: "loanStatus",
          },
          {
            Header: t("TABLE.APPROVED_AMOUNT"),
            accessor: "approvedAmount",
            width: "10%",
          },
          {
            Header: t("TABLE.AMOUNT_PAID_OFF"),
            accessor: "paidOffAmount",
            width: "10%",
          },
          {
            Header: t("TABLE.REDEEMED_AMOUNT"),
            accessor: "redeemedAmount",
            width: "10%",
          },
          {
            Header: t("TABLE.PAYMENT_STATUS"),
            accessor: "paymentStatus",
          },
          {
            Header: t("TABLE.VIEW_MORE"),
            accessor: "viewMore",
          },
        ],
        rows: [],
      });
    }
  }, [customerData]);

  useEffect(() => {
    let timer1;
    if (successModalState) {
      timer1 = setTimeout(() => {
        dispatch(toggleSuccessModalState());
        dispatch(resetSuccessMessage());
      }, 1500);
    }

    return () => {
      clearTimeout(timer1);
    };
  }, [successModalState]);

  useEffect(() => {
    let timer1;
    if (failModalState) {
      timer1 = setTimeout(() => {
        dispatch(toggleFailModalState());
        dispatch(resetErrorMessage());
      }, 1500);
    }

    return () => {
      clearTimeout(timer1);
    };
  }, [failModalState]);

  useEffect(() => {
    if (searchTerms.length > 0) {
      const timeoutId = setTimeout(() => {
        const data = {
          pageIndex,
          pageSize,
          searchTerms,
          forceSearchTerms: filterOption,
        };
        dispatch(getCustomerData(data));
      }, 1000);
      return () => {
        clearTimeout(timeoutId);
      };
    } else {
      const data = {
        pageIndex,
        pageSize,
        searchTerms,
        forceSearchTerms: filterOption,
      };
      dispatch(getCustomerData(data));
    }

    return () => {
      if (customerDetailState) {
        dispatch(setCustomerDetailState(false));
      }
    };
  }, [filterOption, pageIndex, searchTerms]);

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = (state) => {
    if (
      state === "Active" ||
      state === "Overdue" ||
      state === "New_Loans_Blocked" ||
      state === "AdminBlocked" ||
      state === "Collections"
    ) {
      setFilterOptions(state);
      setPageIndex(1);
    } else if (state === "None") {
      setFilterOptions("");
    }
    setMenu(null);
  };

  const renderMenu = (
    <Menu
      anchorEl={menu}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu)}
      onClose={closeMenu}
      keepMounted
    >
      <MenuItem onClick={() => closeMenu("Active")}>
        {t("CUSTOMERS.FILTER_ACTIVE")}
      </MenuItem>
      <MenuItem onClick={() => closeMenu("Overdue")}>
        {t("CUSTOMERS.FILTER_OVERDUE")}
      </MenuItem>
      <MenuItem onClick={() => closeMenu("New_Loans_Blocked")}>
        {t("CUSTOMERS.FILTER_NEW_LOANS_BLOCKED")}
      </MenuItem>
      <MenuItem onClick={() => closeMenu("AdminBlocked")}>
        {t("CUSTOMERS.FILTER_ADMIN_BLOCKED")}
      </MenuItem>
      <MenuItem onClick={() => closeMenu("Collections")}>
        {t("CUSTOMERS.FILTER_COLLECTIONS")}
      </MenuItem>
      <MenuItem onClick={() => closeMenu("None")}>
        {t("ARTISTS.FILTER_NONE")}
      </MenuItem>
    </Menu>
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Card>
          {customerDetailState ? (
            <>
              <MDBox
                p={3}
                pb={1}
                lineHeight={1}
                display="flex"
                alignItems="center"
              >
                <MDBox
                  mr={2}
                  onClick={() => dispatch(setCustomerDetailState(false))}
                >
                  <Icon>arrow_back</Icon>
                </MDBox>
                <MDTypography variant="h5" fontWeight="medium">
                  {t("CUSTOMERS.CUSTOMER_TABLE")}
                </MDTypography>
              </MDBox>
              <CustomerDetails />
            </>
          ) : (
            <MDBox
              p={3}
              pb={1}
              lineHeight={1}
              display="flex"
              justifyContent="space-between"
            >
              <MDTypography variant="h5" fontWeight="medium">
                {t("USERS.CUSTOMERS")}
              </MDTypography>
              <MDBox display="flex">
                <MDButton
                  variant={menu ? "contained" : "outlined"}
                  color="dark"
                  onClick={openMenu}
                >
                  {t("TABLE.FILTERS")}&nbsp;
                  <Icon>keyboard_arrow_down</Icon>
                </MDButton>
                {renderMenu}
              </MDBox>
            </MDBox>
          )}
          {!customers ||
          Object.keys(customers).length === 0 ||
          customerDetailState ? null : (
            <DataTable
              table={customers}
              totalCount={totalCount}
              filterOption={filterOption}
              canSearch
              setPageIndex={setPageIndex}
              parentPageIndex={pageIndex}
              parentPageSize={pageSize}
              loading={loading}
              setSearchTerms={setSearchTerms}
              searchTerms={searchTerms}
            />
          )}
          {failModalState ? (
            <FailModal title={t("ERRORS.ERROR")} message={t(errorMessage)} />
          ) : null}
          {successModalState ? (
            <SuccessModal
              title={t("AUTH.SUCCESS")}
              message={t(successMessage)}
            />
          ) : null}
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default Customers;
