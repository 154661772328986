/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { getLoanDetails } from "store/loanSlice";


function ViewMoreCell({ id }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleViewMore = (e) => {
    dispatch(getLoanDetails(e.target.value));
  };

  return (
    <MDBox display="flex" alignItems="center">
      <MDButton
        color="primary"
        circular={true}
        value={id}
        onClick={handleViewMore}
      >
        {t("TABLE.VIEW_MORE")}
      </MDButton>
    </MDBox>
  );
}

// Typechecking props for the IdCell
ViewMoreCell.propTypes = {
  id: PropTypes.string.isRequired,
};

export default ViewMoreCell;
