import axios from "./api";

export function getSettings() {
  return axios({
    url: `/Setting`,
    method: "get",
  });
}

export function loanMinMax(data) {
  return axios({
    url: `/Setting/loanMinMax`,
    method: "put",
    data,
  });
}

export function newLoanThreshold(threshold) {
  return axios({
    url: `/Setting/newLoanThreshold/${threshold}`,
    method: "put",
  });
}

export function updateLoanFee(term, newFee) {
  return axios({
    url: `/Setting/loanFee/${term}/${newFee}`,
    method: "put",
  });
}

export function updateAgeSimilarity(threshold) {
  return axios({
    url: `/Setting/ageSimilarityThreshold/${threshold}`,
    method: "put",
  });
}

export function updateRiskScores(data) {
  return axios({
    url: `/Setting/riskScoreMinMax`,
    method: "put",
    data,
  });
}

export function updateMinFloat(data) {
  return axios({
    url: `/Setting/minFloat`,
    method: "put",
    data,
  });
}

export function updateFailedPaymentFee(fee) {
  return axios({
    url: `/Setting/failedPaymentFee/${fee}`,
    method: "put",
  });
}

export function updateFailedPaymentDays(data) {
  return axios({
    url: `/Setting/failedPayment/DaysUntilTrys`,
    method: "put",
    data,
  });
}

export function getContentTable(data) {
  return axios({
    url: `/Setting/content/table`,
    method: "post",
    data,
  });
}

export function getContent(type, language) {
  return axios({
    url: `/Setting/content/${type}/${language}`,
    method: "get",
  });
}

export function updateContent(data) {
  return axios({
    url: `/Setting/content`,
    method: "post",
    data,
  });
}

export function updateContract(data) {
  return axios({
    url: `/Upload/basecontract`,
    method: "post",
    data,
  });
}

export function getPreviewContract() {
  return axios({
    url: `/Setting/content/contract`,
    method: "get",
  });
}