import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import MDBox from "components/MDBox";
import InApplyingProcessDetails from "layouts/pages/loan-management/loanDetails/InApplyingProcessDetails";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import FailModal from "components/FailModal/FailModal";
import SuccessModal from "components/SuccessModal/SuccessModal";
import MDTypography from "components/MDTypography";

import xIcon from "../../../../assets/images/ink_me/icons/icon_x_white.svg";
import { getLoanDetails, setLoanApprovalDetailsState } from "store/loanSlice";
import { approveOrDenyLoan, getLoanValues } from "api/loan";

const LoanApprovalDetails = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loanDetails = useSelector((state) => state.loan.loanDetails);
  const [successModalState, setSuccessModalState] = useState(false);
  const [failModalState, setFailModalState] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [error, setError] = useState();
  const [approveLoanState, setApproveLoanState] = useState(false);
  const [declineLoanState, setDeclineLoanState] = useState(false);
  const [reason, setReason] = useState("");
  const [approvedAmount, setApprovedAmount] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loanValues, setLoanValues] = useState({});
  const [errors, setErrors] = useState({});
  const approvalLoanDetailState = useSelector(
    (state) => state.loan.approvalLoanDetailState
  );
  const id = useSelector((state) => state.loan.targetLoanId);

  useEffect(() => {
    dispatch(getLoanDetails(id));
    
    return () => {
      dispatch(setLoanApprovalDetailsState(false));
    };
  }, [approvalLoanDetailState]);

  useEffect(() => {
    let timer1;
    if (successModalState) {
      timer1 = setTimeout(() => {
        setSuccessModalState(false);
        dispatch(getLoanDetails(loanDetails.id));
      }, 1500);
    }

    return () => {
      clearTimeout(timer1);
    };
  }, [successModalState, loanDetails.id]);

  useEffect(() => {
    let timer1;
    if (failModalState) {
      timer1 = setTimeout(() => {
        setFailModalState(false);
        setError();
      }, 1500);
    }

    return () => {
      clearTimeout(timer1);
    };
  }, [failModalState]);

  const renderDetails = () => {
    if (!loanDetails || !loanDetails.details) {
      return;
    } else {
      return <InApplyingProcessDetails />;
    }
  };

  const renderStatus = () => {
    if (
      !loanDetails ||
      !loanDetails.details ||
      !loanDetails.details.loanStatus
    ) {
      return;
    } else if (loanDetails.details.loanStatus === "In Applying Process") {
      return t("LOAN.IN_APPLYING_PROCESS");
    } else if (loanDetails.details.loanStatus === "Pending Approval") {
      return t("LOAN.PENDING_APPROVAL");
    } else if (loanDetails.details.loanStatus === "Active") {
      return t("LOAN.ACTIVE");
    } else if (loanDetails.details.loanStatus === "Tattoo Complete") {
      return t("LOAN.TATTOO_COMPLETE");
    } else if (loanDetails.details.loanStatus === "Complete And Paid") {
      return t("LOAN.COMPLETE_PAID");
    } else if (loanDetails.details.loanStatus === "Cancelled") {
      return t("LOAN.CANCELLED");
    } else if (loanDetails.details.loanStatus === "Denied") {
      return t("LOAN.DENIED");
    } else {
      return t("LOAN.IN_COLLECTIONS");
    }
  };

  useEffect(() => {
    const approveLoan = async (data) => {
      try {
        const res = await approveOrDenyLoan(data);
        setSuccessModalState(true);
        setSuccessMessage("LOAN.SUCCESS_APPROVE");
      } catch (error) {
        console.log(error.response);
        if (error.response.data.message === "LOAN_APPROVAL_LOCKED") {
          setError("ERRORS.LOAN_APPROVAL_LOCKED");
        } else if (
          error.response.data.message === "APPROVED_GREATER_THAN_REQUESTED"
        ) {
          setError("ERRORS.APPROVED_GREATER_THAN_REQUESTED");
        } else {
          setError("ERRORS.GENERAL_ERROR");
        }
        setFailModalState(true);
      }
    };

    if (Object.keys(errors).length === 0 && isSubmitting) {
      const data = {
        id: loanDetails.id,
        fullLoanStatus: "Risk_Score_Completed",
        approvedAmount: approvedAmount,
        reason: "",
      };
      approveLoan(data);
      setApprovedAmount(0);
      setIsSubmitting(false);
      setApproveLoanState(false);
      setErrors({});
    }
  }, [isSubmitting, approvedAmount, errors, loanDetails.id]);

  const handleApproveLoan = async () => {
    setApproveLoanState(true);
    setApprovedAmount(loanDetails.requestedAmount);
    try {
      const res = await getLoanValues();
      setLoanValues(res);
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleSubmitApproveLoan = () => {
    let errorCode = {};
    if (approvedAmount > loanValues.loanMaxSize) {
      errorCode.approvedAmount = "ERRORS.LOAN_MAX_LIMIT";
    }
    if (approvedAmount < loanValues.loanMinSize) {
      errorCode.approvedAmount = "ERRORS.LOAN_MIN_LIMIT";
    }
    if (approvedAmount > loanDetails.requestedAmount) {
      errorCode.approvedAmount = "ERRORS.APPROVED_GREATER_THAN_REQUESTED";
    }
    setErrors(errorCode);
    setIsSubmitting(true);
  };

  const handleDeclineLoan = () => {
    setDeclineLoanState(true);
  };

  const handleSubmitDeclineLoan = async (e) => {
    e.preventDefault();
    const data = {
      id: loanDetails.id,
      fullLoanStatus: "Risk_Score_Denied",
      approvedAmount: 0,
      declineReason: reason === "" ? null : reason,
    };
    try {
      const res = await approveOrDenyLoan(data);
      setSuccessModalState(true);
      setSuccessMessage("LOAN.SUCCESS_DECLINE");
    } catch (error) {
      console.log(error.response);
      setFailModalState(true);
      setError("ERRORS.GENERAL_ERROR");
    }
  };

  const handleChange = (e) => {
    setIsSubmitting(false);
    if (e.target.name === "reason") {
      setReason(e.target.value);
    }
    if (e.target.name === "approvedAmount") {
      setApprovedAmount(e.target.value);
    }
  };

  const renderLoanError = () => {
    if (!errors || !errors.approvedAmount) {
      return;
    } else if (errors.approvedAmount === "ERRORS.LOAN_MAX_LIMIT") {
      return loanValues.loanMaxSize;
    } else if (error.approvedAmount === "ERRORS.LOAN_MIN_LIMIT") {
      return loanValues.loanMinSize;
    }
  };

  return (
    <>
      <MDBox display="flex" justifyContent="flex-end" px={3}>
        <MDTypography variant="h6" color="primary" pb={2}>
          {t("TABLE.STATUS")}
          {": "}
          {renderStatus()}
        </MDTypography>
      </MDBox>
      {loanDetails.status === "Loan_Approved" ? null : (
        <MDBox display="flex" justifyContent="flex-end" px={3}>
          <MDButton color="primary" onClick={handleApproveLoan}>
            {t("LOAN.APPROVE_LOAN")}
          </MDButton>
          <MDBox pl={2}>
            <MDButton color="error" onClick={handleDeclineLoan}>
              {t("LOAN.DENY_LOAN")}
            </MDButton>
          </MDBox>
        </MDBox>
      )}
      <MDBox pt={2} pb={3} px={5} display="flex" flexDirection="column">
        <MDBox>
          <MDTypography variant="h4">
            {t("TABLE.NAME")}
            {`: ${!loanDetails.details ? null : loanDetails.details.userName}`}
          </MDTypography>
        </MDBox>
        <MDBox display="flex" alignItems="center">
          <MDTypography variant="h6">
            {" "}
            {t("TABLE.ARTIST_NAME")}
            {": "}
          </MDTypography>
          <MDTypography variant="body2" pl={2}>
            {!loanDetails.details ? null : loanDetails.details.artistName}
          </MDTypography>
        </MDBox>
        {renderDetails()}
      </MDBox>
      {failModalState ? (
        <FailModal title={t("ERRORS.ERROR")} message={t(error)} />
      ) : null}
      {successModalState ? (
        <SuccessModal title={t("AUTH.SUCCESS")} message={t(successMessage)} />
      ) : null}
      {declineLoanState ? (
        <div className="error_modal_background_container">
          <div className="error_modal_container">
            <div className="modal_title">
              <h3>{t("LOAN.DENY_LOAN")}</h3>
              <button
                type="button"
                onClick={() => setDeclineLoanState(false)}
                onKeyDown={() => setDeclineLoanState(false)}
              >
                <img src={xIcon} alt="x icon" />
              </button>
            </div>
            <div className="modal_content">
              <MDBox p={2} width="350px">
                <MDInput
                  type="text"
                  name="reason"
                  label={t("LOAN.REASON_FOR_DECLINE")}
                  value={reason}
                  onChange={(e) => handleChange(e)}
                  error={!errors && !errors.reason ? false : true}
                  multiline
                  rows={5}
                  fullWidth
                />
                {!errors ? null : (
                  <p className="error_message">{t(errors.reason)}</p>
                )}
              </MDBox>
              <MDBox>
                <MDButton
                  color="error"
                  onClick={(e) => handleSubmitDeclineLoan(e)}
                >
                  {t("LOAN.DENY_LOAN")}
                </MDButton>
              </MDBox>
            </div>
          </div>
        </div>
      ) : null}
      {approveLoanState ? (
        <div className="error_modal_background_container">
          <div className="error_modal_container">
            <div className="modal_title">
              <h3>{t("LOAN.APPROVE_LOAN")}</h3>
              <button
                type="button"
                onClick={() => setApproveLoanState(false)}
                onKeyDown={() => setApproveLoanState(false)}
              >
                <img src={xIcon} alt="x icon" />
              </button>
            </div>
            <div className="modal_content">
              <MDBox p={2} width="350px">
                <MDInput
                  type="number"
                  name="approvedAmount"
                  label={t("LOAN.APPROVED_AMOUNT")}
                  value={approvedAmount}
                  onChange={(e) => handleChange(e)}
                  error={!errors && !errors.approvedAmount ? false : true}
                  fullWidth
                />
                {!errors ? null : (
                  <p className="error_message">
                    {t(errors.approvedAmount)}
                    {renderLoanError()}
                  </p>
                )}
              </MDBox>
              <MDBox>
                <MDButton
                  color="error"
                  onClick={() => handleSubmitApproveLoan()}
                >
                  {t("LOAN.APPROVE_LOAN")}
                </MDButton>
              </MDBox>
            </div>
          </div>
        </div>
      ) : null}
      {failModalState ? (
        <FailModal title={t("ERRORS.ERROR")} message={t(error)} />
      ) : null}
      {successModalState ? (
        <SuccessModal title={t("AUTH.SUCCESS")} message={t(successMessage)} />
      ) : null}
    </>
  );
};

export default LoanApprovalDetails;
