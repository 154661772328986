/* eslint-disable no-nested-ternary */
/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect } from "react";
// react-router components
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";

import { useSelector } from "react-redux";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
// import Icon from "@mui/material/Icon";

// import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React themes
import theme from "assets/theme";

// Material Dashboard 2 PRO React Dark Mode themes
import themeDark from "assets/theme-dark";

// Material Dashboard 2 PRO React routes

// Material Dashboard 2 PRO React contexts
import {
  useMaterialUIController,
  setLayout,
  // setOpenConfigurator,
} from "context";

import Loader from "components/Loader/Loader";
import SignInBasic from "./layouts/authentication/sign-in/basic/index";
import brandDark from "assets/images/ink_me/logo/logo_main.png";
import brandWhite from "assets/images/ink_me/logo/logo_white.svg";

// Material Dashboard 2 PRO React components
import Sidenav from "examples/Sidenav";
import routes from "./routes";

export default function App() {
  const accessToken = useSelector((state) => state.auth.accessToken);
  const [controller, dispatch] = useMaterialUIController();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const location = useLocation();
  const { layout, sidenavColor, transparentSidenav, whiteSidenav, darkMode } =
    controller;
  // const [onMouseEnter, setOnMouseEnter] = useState(false);
  // Change the openConfigurator state
  // const handleConfiguratorOpen = () =>
  //   setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  // useEffect(() => {
  //   document.body.setAttribute("dir", direction);
  // }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);
  // Open sidenav when mouse enter on mini sidenav
  // const handleOnMouseEnter = () => {
  // 	if (miniSidenav && !onMouseEnter) {
  // 		setMiniSidenav(dispatch, false);
  // 		setOnMouseEnter(true);
  // 	}
  // };
  // Close sidenav when mouse leave mini sidenav
  // const handleOnMouseLeave = () => {
  //   if (onMouseEnter) {
  //     setMiniSidenav(dispatch, true);
  //     setOnMouseEnter(false);
  //   }
  // };

  useEffect(() => {
    if (accessToken && location.pathname === "/") {
      navigate("/dashboard");
      setLayout(dispatch, "dashboard");
    }
    if (
      location.pathname === "/usermanagement" ||
      location.pathname === "/application-management" ||
      location.pathname === "/settings"
    ) {
      navigate("/dashboard");
    }
  }, [accessToken, location.pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return (
          <Route
            exact
            path={route.route}
            element={route.component}
            key={route.key}
          />
        );
      }

      return null;
    });

  // const configsButton = (
  //   <MDBox
  //     display="flex"
  //     justifyContent="center"
  //     alignItems="center"
  //     width="3.25rem"
  //     height="3.25rem"
  //     bgColor="white"
  //     shadow="sm"
  //     borderRadius="50%"
  //     position="fixed"
  //     right="2rem"
  //     bottom="2rem"
  //     zIndex={99}
  //     color="dark"
  //     sx={{ cursor: "pointer" }}
  //     onClick={handleConfiguratorOpen}
  //   >
  //     <Icon fontSize="small" color="inherit">
  //       settings
  //     </Icon>
  //   </MDBox>
  // );

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      <Loader />
      {accessToken ? (
        layout === "dashboard" && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={
                (transparentSidenav && !darkMode) || whiteSidenav
                  ? brandDark
                  : brandWhite
              }
              brandName="Ink Me Financial"
              routes={routes}
            />
            <Routes>{getRoutes(routes)}</Routes>
          </>
        )
      ) : (
        <Routes>
          <Route path="*" element={<Navigate to="/" replace />} />
          <Route exact path="/" element={<SignInBasic />} />
        </Routes>
      )}
    </ThemeProvider>
  );
}
