import axios from "./api";

export function getCustomerTable(data) {
  return axios({
    url: `/UserAdmin/customer/getpage`,
    method: "post",
    data,
  });
}

export function getCustomerDetails(id) {
  return axios({
    url: `/UserAdmin/customer/details/${id}`,
    method: "get",
  });
}

export function blockAndUnblockCustomer(data) {
  return axios({
    url: `/UserAdmin/customer/blocking`,
    method: "put",
    data,
  });
}

export function finishCollections(id) {
  return axios({
    url: `/UserAdmin/customer/finishcollection/${id}`,
    method: "put",
  });
}
