import React from "react";
import { useTranslation } from "react-i18next";

import ModalContainer from "../../../../../components/ModalContainer/ModalContainer";

import "./PaymentDetailsModal.css";

const PaymentDetailsModal = ({ paymentDetails, togglePaymentDetails }) => {
  const { t } = useTranslation();

  const getPaymentDateMonth = (isoDate) => {
    const monthNames = [
      t("LOAN.JAN"),
      t("LOAN.FEB"),
      t("LOAN.MAR"),
      t("LOAN.APR"),
      t("LOAN.MAY"),
      t("LOAN.JUN"),
      t("LOAN.JUL"),
      t("LOAN.AUG"),
      t("LOAN.SEP"),
      t("LOAN.OCT"),
      t("LOAN.NOV"),
      t("LOAN.DEC"),
    ];
    const date = new Date(isoDate);
    let dt = date.getDate();
    let month = monthNames[date.getMonth()];

    return `${month} ${dt}`;
  };

  const renderSubLoans = () => {
    if (!paymentDetails) {
      return;
    } else {
      return paymentDetails.subloans.map((subloan) => {
        return (
          <p key={subloan.created}>
            {getPaymentDateMonth(subloan.created)}-{" "}
            {t("LOAN.PAYMENT_APPROVED_AMOUNT")} ${subloan.amount}
            <span>${subloan.monthlyPayment.toFixed(2)}</span>
          </p>
        );
      });
    }
  };

  return (
    <div>
      <ModalContainer
        title={t("LOAN.PAYMENT_DETAILS")}
        onButtonClose={() => togglePaymentDetails(false)}
      >
        <div className="payment_details_container">
          <div className="payment_details_modal_details">
            {renderSubLoans()}
            <p>
              {t("LOAN.FEES")}
              <span>${paymentDetails.totalFee.toFixed(2)}</span>
            </p>
            {paymentDetails.lateFee === 0 ? null : (
              <p>
                {t("LOAN.LATE_FEES")}
                <span>${paymentDetails.lateFee.toFixed(2)}</span>
              </p>
            )}
            <hr />
            <p>
              {t("LOAN.TOTAL")}
              <span>${paymentDetails.totalAmount.toFixed(2)}</span>
            </p>
          </div>
        </div>
      </ModalContainer>
    </div>
  );
};

export default PaymentDetailsModal;
