import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getContentTable } from "api/settings";
import { getSettings } from "api/settings";

const initialState = {
  loading: false,
  settings: {},
  errorMessage: "",
  content: {},
  contentDetail: {},
  contentDetailState: false,
};

export const getSettingsData = createAsyncThunk(
  "settings/getSettingsData",
  // eslint-disable-next-line consistent-return
  async (data, thunkAPI) => {
    try {
      const res = await getSettings();
      return res;
    } catch (error) {
      if (error.response.status === 400) {
        return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
      }
      if (error.response.status === 500) {
        return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
      } else {
        return thunkAPI.rejectWithValue(error.response);
      }
    }
  }
);

export const getContentData = createAsyncThunk(
  "settings/getContentData",
  // eslint-disable-next-line consistent-return
  async (data, thunkAPI) => {
    try {
      const res = await getContentTable(data);
      return res;
    } catch (error) {
      if (error.response.status === 400) {
        return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
      }
      if (error.response.status === 500) {
        return thunkAPI.rejectWithValue("ERRORS.GENERAL_ERROR");
      } else {
        return thunkAPI.rejectWithValue(error.response);
      }
    }
  }
);

export const settingSlice = createSlice({
  name: "setting",
  initialState,
  reducers: {
    setContentDetails: (state, { payload }) => {
      state.contentDetail = state.content.contents.filter(
        (content) =>
          content.name === payload.name && content.version === payload.version
      )[0];
    },
    toggleContentDetailState: (state) => {
      state.contentDetailState = !state.contentDetailState;
    },
  },
  extraReducers: {
    [getSettingsData.pending]: (state) => {
      state.errorMessage = "";
      state.loading = true;
    },
    [getSettingsData.rejected]: (state, action) => {
      state.loading = false;
      state.errorMessage = action.payload;
    },
    [getSettingsData.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.settings = payload;
    },
    [getContentData.pending]: (state) => {
      state.errorMessage = "";
      state.loading = true;
    },
    [getContentData.rejected]: (state, action) => {
      state.loading = false;
      state.errorMessage = action.payload;
    },
    [getContentData.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.content = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setContentDetails, toggleContentDetailState } = settingSlice.actions;

export default settingSlice.reducer;
