import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import MDBox from "components/MDBox";
import { Icon } from "@mui/material";
import MDButton from "components/MDButton";
import BlockUserModal from "components/blockUserModal";
import MDTypography from "components/MDTypography";

import {
  toggleCreateNewAdminState,
  toggleUpdateAdminState,
  toggleAdminDetailsState,
} from "store/staffSlice";
import UpdateAdmin from "../UpdateAdmin";
import { changeAdminBlockUnblock } from "store/staffSlice";
import { adminResetTempPassword } from "store/staffSlice";
import { getDate } from "../../../../../helpers/helpers";

const AdminDetails = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const updateAdminState = useSelector((state) => state.staff.updateAdminState);
  const staffDetails = useSelector((state) => state.staff.staffDetails);
  const adminDetailsState = useSelector(
    (state) => state.staff.adminDetailsState
  );
  const createNewAdminState = useSelector(
    (state) => state.staff.createNewAdminState
  );
  const role = useSelector((state) => state.auth.role);
  const [blockAdminState, setBlockAdminState] = useState(false);

  const handleUnBlockStaff = () => {
    const data = {
      id: staffDetails.id,
      status: "Unblock",
      declineReason: "",
    };
    dispatch(changeAdminBlockUnblock(data));
  };
  const handleBlockStaff = () => {
    setBlockAdminState(true);
  };

  const handleSubmitBlockAdmin = (reason, sendEmail) => {
    const data = {
      id: staffDetails.id,
      status: "Block",
      declineReason: reason,
      sendUserEmail: sendEmail,
    };
    dispatch(changeAdminBlockUnblock(data));
    setBlockAdminState(false);
  };

  const handleResetTempPassword = () => {
    const data = {
      email: staffDetails.email,
    };
    dispatch(adminResetTempPassword(data));
  };

  const renderBlockReasons = () => {
    if (
      !staffDetails ||
      !staffDetails.blockForms ||
      staffDetails.blockForms.length === 0
    ) {
      return;
    } else {
      return staffDetails.blockForms.map((reason) => {
        return (
          <MDBox key={reason.created}>
            <MDTypography variant="h6" pl={1}>
              {getDate(reason.created)}
            </MDTypography>
            <MDTypography variant="body2" pl={1}>
              {reason.blockReason}
            </MDTypography>
          </MDBox>
        );
      });
    }
  };

  return (
    <>
      <MDBox p={3} pb={1} lineHeight={1} display="flex" alignItems="center">
        <MDBox
          mr={2}
          onClick={() => {
            if (adminDetailsState) {
              dispatch(toggleAdminDetailsState());
            }
            if (createNewAdminState) {
              dispatch(toggleCreateNewAdminState());
            }
            if (updateAdminState) {
              dispatch(toggleUpdateAdminState());
            }
          }}
        >
          <Icon>arrow_back</Icon>
        </MDBox>
        <MDTypography variant="h5" fontWeight="medium">
          {t("STAFF.STAFF_TABLE")}
        </MDTypography>
      </MDBox>
      {role === "SuperAdmin" && !staffDetails.isSuperAdmin ? (
        <MDBox display="flex" flexDirection="column" p={2}>
          <MDBox display="flex" justifyContent="flex-end">
            <MDBox px={2}>
              <MDButton color="error" onClick={handleBlockStaff}>
                {t("STAFF.BLOCK_ADMIN")}
              </MDButton>
            </MDBox>
            <MDButton color="primary" onClick={handleUnBlockStaff}>
              {t("STAFF.UNBLOCK_ADMIN")}
            </MDButton>
          </MDBox>
          <MDBox display="flex" justifyContent="flex-end" pt={2}>
            <MDBox px={2}>
              <MDButton
                variant="outlined"
                color="primary"
                onClick={handleResetTempPassword}
              >
                {t("STAFF.RESET_TEMP_PASSWORD")}
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      ) : null}
      {staffDetails.isBlocked ? (
        <MDBox
          display="flex"
          flexDirection="column"
          alignItems="flex-end"
          px={3}
          pt={3}
        >
          <MDBox display="flex" flexDirection="column">
            <MDTypography variant="h6" color="primary">
              {t("TABLE.STATUS")}
              {": "}
              {t("STAFF.BLOCKED")}
            </MDTypography>
            <MDBox>
              <MDTypography variant="h6">
                {t("ARTISTS.REASON_FOR_BLOCK")}
                {": "}
              </MDTypography>
              <MDBox>
                <MDTypography variant="body2" pl={1}>
                  {renderBlockReasons()}
                </MDTypography>
              </MDBox>
            </MDBox>
          </MDBox>
        </MDBox>
      ) : null}
      {updateAdminState ? (
        <UpdateAdmin />
      ) : (
        <MDBox
          pt={2}
          pb={3}
          px={2}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <MDBox display="flex" justifyContent="center">
            <MDButton
              color="primary"
              variant="outlined"
              onClick={() => dispatch(toggleUpdateAdminState())}
            >
              {t("STAFF.UPDATE_INFO")}
            </MDButton>
          </MDBox>
          <MDBox p={3}>
            <MDTypography variant="h3" textTransform="uppercase">
              {`${staffDetails.firstName} ${staffDetails.lastName}`}
            </MDTypography>
            <MDBox display="flex" alignItmes="center">
              <MDTypography variant="h6">
                {t("TABLE.FIRST_NAME")}
                {": "}
              </MDTypography>
              <MDTypography variant="body2" pl={1}>
                {staffDetails.firstName}
              </MDTypography>
            </MDBox>
            <MDBox display="flex" alignItmes="center">
              <MDTypography variant="h6">
                {t("TABLE.LAST_NAME")}
                {": "}
              </MDTypography>
              <MDTypography variant="body2" pl={1}>
                {staffDetails.lastName}
              </MDTypography>
            </MDBox>
            <MDBox display="flex" alignItmes="center">
              <MDTypography variant="h6">
                {t("TABLE.EMAIL")}
                {": "}
              </MDTypography>
              <MDTypography variant="body2" pl={1}>
                {staffDetails.email}
              </MDTypography>
            </MDBox>
            <MDBox display="flex" alignItmes="center">
              <MDTypography variant="h6">
                {t("TABLE.PHONE")}
                {": "}
              </MDTypography>
              <MDTypography variant="body2" pl={1}>
                {staffDetails.phone}
              </MDTypography>
            </MDBox>
          </MDBox>
          {blockAdminState ? (
            <BlockUserModal
              buttonText={"STAFF.BLOCK_ADMIN"}
              title={"STAFF.BLOCK_ADMIN"}
              onModalClose={() => setBlockAdminState(false)}
              onSubmit={handleSubmitBlockAdmin}
            />
          ) : null}
        </MDBox>
      )}
    </>
  );
};

export default AdminDetails;
