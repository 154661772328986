import axios from "./api";

export function getPayoutReport(data) {
	return axios({
		url: `/Payout/report/page`,
		method: "post",
		data,
	});
}

export function getPayoutReportExcel(reportId) {
	return axios({
		url: `/Payout/download/${reportId}`,
		method: "post",
		responseType: "blob",
	});
}

export function getPayoutReportDetail(data, reportId) {
	return axios({
		url: `/Payout/report/details/${reportId}`,
		method: "post",
		data,
	});
}

export function getPayoutReportArtistDetail(data, reportId, artistId) {
	return axios({
		url: `/Payout/report/moredetails/${reportId}/${artistId}`,
		method: "post",
		data,
	});
}

export function markReportAsPaid(reportId){
	return axios({
		url: `/Payout/payreport/${reportId}`,
		method: "put",
	});
}

export function markArtistAsPaid({reportId, artistId}){
	return axios({
		url: `/Payout/payartist/${reportId}/${artistId}`,
		method: "put",
	});
}