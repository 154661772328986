import React, { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import { Card, Box } from "@mui/material";
import MDTypography from "components/MDTypography";
import DataTable from "components/DataTable";
import { getReportDetails } from "store/payoutSlice";
import { useDispatch, useSelector } from "react-redux";
import MDButton from "components/MDButton";
import Modal from "@mui/material/Modal";
import ReportArtistDetail from "../ReportArtistDetail";
import Icon from "@mui/material/Icon";
import { markArtistAsPaid } from "api/payout";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function ReportDetail({ selectedReport, goBack }) {
  const { t } = useTranslation();
  const [pageIndex, setPageIndex] = useState(1);
  const [details, setDetails] = useState({});
  const [totalCount, setTotalCount] = useState(0);
  const [selectedAritist, setSelectedAritist] = useState(null);
  const [noData, setNoData] = useState(false);
  const [pageSize, setPageSize] = useState(10);

  const dispatch = useDispatch();
  const [open, setOpen] = useState(false); // open artist detail for each payout
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const reportDetails = useSelector((state) => state.payout.reportDetails);
  const loading = useSelector((state) => state.payout.getReportDetailsLoading);

  const getDetails = () => {
    let data = {
      searchTerms: "",
      forceSearchTerms: "",
      pageIndex,
      pageSize,
      selectedReport: selectedReport,
    };
    dispatch(getReportDetails(data));
  };
  // useEffect(() => {
  // 	getDetails()
  // }, []);

  useEffect(() => {
    if (reportDetails?.totalCount > 0) {
      setDetails({
        columns: [
          { Header: t("PAYOUT.NAME"), accessor: "name" },
          { Header: t("PAYOUT.PAY_EMAIL"), accessor: "payEmail" },
          { Header: t("PAYOUT.AMOUNT"), accessor: "amount" },
          {
            Header: t("PAYOUT.VIEW_DETAILS"),
            accessor: "actions",
            width: 300,
            align: "right",
          },
        ],
        rows: reportDetails.payouts.map((payout) => {
          return {
            // "totalPayout": 0,
            // "created": "2022-07-20T21:54:56.156Z",
            // "isPaid": true
            amount: payout.amount.toString(),
            name: payout.name,
            payEmail: payout.payEmail,
            actions: (
              <div key={payout.artistId}>
                {!payout.isPaid && (
                  <>
                    <MDButton
                      onClick={() => {
                        markArtistAsPaid({
                          reportId: selectedReport,
                          artistId: payout.artistId,
                        })
                          .then((res) => {
                            toast.success("Marked as paid");
                            getDetails();
                          })
                          .catch((e) => {
                            toast.success("Failed mark as paid");
                          });
                      }}
                      // key={report.id}
                      title={t("PAYOUT.MARK_AS_PAID")}
                      color="light"
                      style={{ color: "#7b809a" }}
                      variant="contained"
                    >
                      {t("PAYOUT.MARK_AS_PAID")}
                    </MDButton>{" "}
                  </>
                )}

                <MDButton
                  onClick={() => {
                    handleOpen();
                    setSelectedAritist(payout.artistId);
                  }}
                  // key={report.id}
                  title={t("PAYOUT.VIEW_DETAIL")}
                  color="light"
                  style={{ color: "#7b809a" }}
                  variant="contained"
                >
                  {t("PAYOUT.VIEW_DETAIL")}
                </MDButton>
              </div>
            ),
          };
        }),
      });
      setTotalCount(reportDetails.totalCount);
      setNoData(false);
    } else {
      resetState();
      setNoData(true);
    }
  }, [reportDetails]);

  const resetState = () => {
    setDetails({});
    setTotalCount(0);
  };

  useEffect(() => {
    getDetails();
  }, [pageIndex]);

  return (
    <>
      <Card>
        <MDBox p={3} lineHeight={1} display="flex" alignItems="center">
          <Icon
            onClick={(e) => {
              e.preventDefault();
              resetState();
              goBack();
            }}
            style={{ cursor: "pointer" }}
          >
            arrow_back
          </Icon>{" "}
          <MDTypography variant="h5" fontWeight="medium">
            {t("PAYOUT.REPORT_DETAILS")}
          </MDTypography>
        </MDBox>
        {details?.rows?.length > 0 ? (
          <DataTable
            // entriesPerPage={{ entries: "2" }}
            table={details}
            totalCount={totalCount}
            setPageIndex={setPageIndex}
            parentPageIndex={pageIndex}
            parentPageSize={pageSize}
            loading={loading}
          />
        ) : (
          <MDBox p={3} lineHeight={1} display="flex" alignItems="center">
            <MDTypography variant="h6" fontWeight="medium">
              {t("PAYOUT.NO_DATA")}
            </MDTypography>
          </MDBox>
        )}
      </Card>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ReportArtistDetail
            selectedAritist={selectedAritist}
            selectedReport={selectedReport}
          />
        </Box>
      </Modal>
    </>
  );
}
