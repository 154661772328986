import axios from "./api";

export function getLoanTable(data) {
  return axios({
    url: `/LoanAdmin/all/getpage`,
    method: "post",
    data,
  });
}

export function getLoanApprovalTable(sortByDate, ascendingOrder, data) {
  return axios({
    url: `/LoanAdmin/approval/getpage/${sortByDate}/${ascendingOrder}`,
    method: "post",
    data,
  });
}

export function getLoanHistory(id) {
  return axios({
    url: `/LoanAdmin/details/history/${id}`,
    method: "get",
  });
}

export function getContract(data) {
  return axios({
    url: `/Loan/signedContract`,
    method: "put",
    data,
  });
}

export function getUcpomingDetails(id) {
  return axios({
    url: `/LoanAdmin/details/upcoming/${id}`,
    method: "get",
  });
}

export function getPaymentDetails(data) {
  return axios({
    url: `/Loan/paymentdetails`,
    method: "post",
    data,
  });
}

export function reactivateLoan(id) {
  return axios({
    url: `/LoanAdmin/reactivate/${id}`,
    method: "put",
  });
}

export function removeLateFee(id) {
  return axios({
    url: `/LoanAdmin/removelatefee/${id}`,
    method: "put",
  });
}

export function approveOrDenyLoan(data) {
  return axios({
    url: `/Loan/bankRequest/Approval`,
    method: "post",
    data,
  });
}

export function getLoanValues() {
  return axios({
    url: `/Loan/calculatorValues`,
    method: "get",
  });
}
