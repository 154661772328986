/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import ArtistDetails from "./ArtistDetails";
import ErrorModal from "components/ErrorModal/ErrorModal";
import SuccessModal from "components/SuccessModal/SuccessModal";
import FailModal from "components/FailModal/FailModal";
import ViewMoreCell from "./ViewMoreCell";
// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "components/DataTable";
import {
  getArtists,
  setArtistDetailState,
  toggleFailModalState,
  toggleSuccessModalState,
  resetErrorMessage,
  resetSuccessMessage,
} from "store/artistSlice";
import { toggleUpdateProfileState } from "store/artistSlice";
import { getDateWithTime } from "helpers/helpers";
// Data

function Artists() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const artistData = useSelector((state) => state.artist.artists);
  const totalCount = useSelector((state) => state.artist.totalCount);
  const artistDetailState = useSelector(
    (state) => state.artist.artistDetailState
  );
  const [menu, setMenu] = useState(null);
  const [filterOption, setFilterOptions] = useState("");
  const successModalState = useSelector(
    (state) => state.artist.successModalState
  );
  const failModalState = useSelector((state) => state.artist.failModalState);
  const errorMessage = useSelector((state) => state.artist.errorMessage);
  const successMessage = useSelector((state) => state.artist.successMessage);
  const updateProfileState = useSelector(
    (state) => state.artist.updateProfileState
  );
  const [pageIndex, setPageIndex] = useState(1);
  const pageSize = 10;
  const loading = useSelector((state) => state.customer.loading);
  const [searchTerms, setSearchTerms] = useState("");
  const [artists, setArtists] = useState({});

  const getArtistStatus = (status) => {
    let text = "";
    switch (status) {
      case "Email_Not_Verified":
        text = t("ARTISTS.EMAIL_NOT_VERIFIED");
        break;
      case "Active":
        text = t("ARTISTS.ACTIVE");
        break;
      case "Waiting_Verification":
        text = t("ARTISTS.WAITING_VERIFICATION");
        break;
      case "Declined":
        text = t("ARTISTS.DECLINED");
        break;
      default:
        text = t("ARTISTS.BLOCKED");
    }
    return text;
  };

  useEffect(() => {
    if (artistData?.totalCount > 0) {
      setArtists({
        columns: [
          { Header: t("TABLE.NAME"), accessor: "fullName" },
          { Header: t("TABLE.EMAIL"), accessor: "email" },
          { Header: t("TABLE.PHONE"), accessor: "phone" },
          {
            Header: t("TABLE.REGISTRATION_DATE"),
            accessor: "registerDate",
          },
          { Header: t("TABLE.STUDIO_NAME"), accessor: "studioName" },

          {
            Header: t("TABLE.STATUS"),
            accessor: "status",
          },
          {
            Header: t("TABLE.VIEW_MORE"),
            accessor: "viewMore",
          },
        ],
        rows: artistData.artists.map((artist) => {
          return {
            fullName: artist.fullName,
            email: artist.email,
            phone: artist.phone,
            registerDate: getDateWithTime(artist.registerDate),
            studioName: artist.studioName,
            status: getArtistStatus(artist.status),
            viewMore: <ViewMoreCell id={artist.id} />,
          };
        }),
      });
    } else {
      setArtists({
        columns: [
          { Header: t("TABLE.NAME"), accessor: "fullName" },
          { Header: t("TABLE.EMAIL"), accessor: "email" },
          { Header: t("TABLE.PHONE"), accessor: "phone" },
          {
            Header: t("TABLE.REGISTRATION_DATE"),
            accessor: "registerDate",
          },
          { Header: t("TABLE.STUDIO_NAME"), accessor: "studioName" },

          {
            Header: t("TABLE.STATUS"),
            accessor: "status",
          },
          {
            Header: t("TABLE.VIEW_MORE"),
            accessor: "viewMore",
          },
        ],
        rows: [],
      });
    }
  }, [artistData]);

  useEffect(() => {
    let timer1;
    if (successModalState) {
      timer1 = setTimeout(() => {
        dispatch(toggleSuccessModalState());
        dispatch(resetSuccessMessage());
      }, 1500);
    }

    return () => {
      clearTimeout(timer1);
    };
  }, [successModalState]);

  useEffect(() => {
    let timer1;
    if (failModalState) {
      timer1 = setTimeout(() => {
        dispatch(toggleFailModalState());
        dispatch(resetErrorMessage());
      }, 1500);
    }

    return () => {
      clearTimeout(timer1);
    };
  }, [failModalState]);

  useEffect(() => {
    if (searchTerms.length > 0) {
      const timeoutId = setTimeout(() => {
        const data = {
          pageIndex,
          pageSize,
          searchTerms,
          forceSearchTerms: filterOption,
        };
        dispatch(getArtists(data));
      }, 1000);
      return () => {
        clearTimeout(timeoutId);
      };
    } else {
      const data = {
        pageIndex,
        pageSize,
        searchTerms,
        forceSearchTerms: filterOption,
      };
      dispatch(getArtists(data));
    }

    return () => {
      if (artistDetailState) {
        dispatch(setArtistDetailState(false));
      }
    };
  }, [filterOption, pageIndex, searchTerms]);

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = (state) => {
    if (
      state === "Active" ||
      state === "Waiting_Verification" ||
      state === "Declined" ||
      state === "Blocked"
    ) {
      setFilterOptions(state);
      setPageIndex(1);
    } else if (state === "None") {
      setFilterOptions("");
    }
    setMenu(null);
  };

  const renderMenu = (
    <Menu
      anchorEl={menu}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu)}
      onClose={closeMenu}
      keepMounted
    >
      <MenuItem onClick={() => closeMenu("Active")}>
        {t("ARTISTS.FILTER_ACTIVE")}
      </MenuItem>
      <MenuItem onClick={() => closeMenu("Waiting_Verification")}>
        {t("ARTISTS.FILTER_WAITING_VERIFICATION")}
      </MenuItem>
      <MenuItem onClick={() => closeMenu("Declined")}>
        {t("ARTISTS.FILTER_DECLINED")}
      </MenuItem>
      <MenuItem onClick={() => closeMenu("Blocked")}>
        {t("ARTISTS.FILTER_BLOCKED")}
      </MenuItem>
      <MenuItem onClick={() => closeMenu("None")}>
        {t("ARTISTS.FILTER_NONE")}
      </MenuItem>
    </Menu>
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ErrorModal />
      <MDBox pt={6} pb={3}>
        <Card>
          {artistDetailState ? (
            <>
              <MDBox
                p={3}
                pb={1}
                lineHeight={1}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <MDBox display="flex" alignItems="center">
                  <MDBox
                    mr={2}
                    onClick={() => {
                      if (updateProfileState) {
                        dispatch(toggleUpdateProfileState());
                      } else {
                        dispatch(setArtistDetailState(false));
                      }
                    }}
                  >
                    <Icon>arrow_back</Icon>
                  </MDBox>
                  <MDTypography variant="h5" fontWeight="medium">
                    {t("ARTISTS.ARTIST_TABLE")}
                  </MDTypography>
                </MDBox>
              </MDBox>
              <ArtistDetails />
            </>
          ) : (
            <MDBox
              p={3}
              pb={1}
              lineHeight={1}
              display="flex"
              justifyContent="space-between"
            >
              <MDTypography variant="h5" fontWeight="medium">
                {t("USERS.ARTISTS")}
              </MDTypography>
              <MDBox display="flex">
                <MDButton
                  variant={menu ? "contained" : "outlined"}
                  color="dark"
                  onClick={openMenu}
                >
                  {t("TABLE.FILTERS")}&nbsp;
                  <Icon>keyboard_arrow_down</Icon>
                </MDButton>
                {renderMenu}
              </MDBox>
            </MDBox>
          )}
          {!artists ||
          Object.keys(artists).length === 0 ||
          artistDetailState ? null : (
            <DataTable
              table={artists}
              totalCount={totalCount}
              filterOption={filterOption}
              canSearch
              setPageIndex={setPageIndex}
              parentPageIndex={pageIndex}
              parentPageSize={pageSize}
              loading={loading}
              setSearchTerms={setSearchTerms}
              searchTerms={searchTerms}
            />
          )}
          {failModalState ? (
            <FailModal title={t("ERRORS.ERROR")} message={t(errorMessage)} />
          ) : null}
          {successModalState ? (
            <SuccessModal
              title={t("AUTH.SUCCESS")}
              message={t(successMessage)}
            />
          ) : null}
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default Artists;
